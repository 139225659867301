// TODO: Re-write Date Picker

import { useEffect, useState, useCallback, useMemo } from 'react'
import { ClickAwayListener } from '@mui/base'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { OctupDateRangePicker } from '../ReactDateRangeWrappers/OctupDateRangePicker/OctupDateRangePicker'
import { ChevronDownSvg } from '../../assets/icons/ChevronDownSvg'
import { ChevronUpSvg } from '../../assets/icons/ChevronUpSvg'
import { getShortTableDate } from '../../utils/dates-util'

export const OctupDatePicker = ({
    direction,
    month = 2,
    hideStaticDates = false,
    showCalendar = true,
    customStaticRanges,
    ...props
}) => {
    const [showDatePicker, setShowDatePicker] = useState(false)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const [dateLabel, setDateLabel] = useState(datePickerValues.label)
    const [labelDates, setLabelDates] = useState(null)

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setShowDatePicker(false)
        }
        event.stopPropagation()
    }, [])

    useEffect(() => {
        if (!showDatePicker) return
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction, showDatePicker])

    const handleClickAway = useCallback(() => {
        if (showDatePicker) {
            setShowDatePicker(false)
        }
    }, [showDatePicker])

    const startFormattedDate = useMemo(
        () => labelDates && getShortTableDate(labelDates.startDate).primaryFormat,
        [labelDates]
    )

    const endFormattedDate = useMemo(
        () => labelDates && getShortTableDate(labelDates.endDate).primaryFormat,
        [labelDates]
    )

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <MainContainer id="date-picker-container">
                <DatePickerTriggerContainer
                    id="date-picker-trigger"
                    onClick={() => {
                        if (!showDatePicker) {
                            setShowDatePicker(true)
                        }
                        if (showDatePicker) {
                            setShowDatePicker(false)
                        }
                    }}
                >
                    <DatePickerLabel>{dateLabel && dateLabel}</DatePickerLabel>
                    <DatePickerDateRangeStyled>
                        {labelDates && startFormattedDate === endFormattedDate
                            ? startFormattedDate
                            : `(${startFormattedDate}-${endFormattedDate})`}
                    </DatePickerDateRangeStyled>
                    {!showDatePicker ? (
                        <ChevronDownSvg customColor={'#504B5A'} />
                    ) : (
                        <ChevronUpSvg customColor={'#504B5A'} />
                    )}
                </DatePickerTriggerContainer>
                <OctupDateRangePickerEl showCalendar={showCalendar}>
                    <OctupDateRangePicker
                        show={showDatePicker}
                        dateLabel={dateLabel}
                        setLabel={setDateLabel}
                        setLabelDates={setLabelDates}
                        setShowDatePicker={setShowDatePicker}
                        direction={direction}
                        month={month}
                        hideStaticDates={hideStaticDates}
                        showCalendar={showCalendar}
                        customStaticRanges={customStaticRanges}
                        {...props}
                    />
                </OctupDateRangePickerEl>
            </MainContainer>
        </ClickAwayListener>
    )
}

const MainContainer = styled.div`
    position: relative;
    z-index: 90;
`
const OctupDateRangePickerEl = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;

    margin-top: ${(props) => (!props.showCalendar ? '8rem' : '10rem')};
    margin-right: ${(props) => (!props.showCalendar ? '1.6rem' : '8.7rem')};
    scale: 1.2;

    border-radius: 8px;

    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);

    .rdrMonths {
        margin-left: 3rem;
    }

    .sc-dZeWys {
        margin-left: 3rem;
    }
`

const DatePickerTriggerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3.4rem;
    width: fit-content;
    gap: 0.5rem;
    background: #f8f7fb;
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    cursor: pointer;
    padding: 0 1.2rem 0 1.2rem;
    border-radius: 0.8rem;
    z-index: 3;
`

const DatePickerLabel = styled.div`
    white-space: nowrap;
    font-weight: 600;
    font-size: 1.3rem;
`

const DatePickerDateRangeStyled = styled.div`
    color: rgba(127, 123, 135, 1);
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.3rem;
`
