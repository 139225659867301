import axios from 'axios'
import { GLOBAL_CONFIG } from '@/config'
import type { PAGE_MENU_TYPES, PageType } from '@/models/pages'
import { generateAPIConfig } from '@/services/utils'

const PAGE_API_URL = GLOBAL_CONFIG.API_URL + '/page'

export const getPagesByMenuType = async (menuType: PAGE_MENU_TYPES) => {
    const config = generateAPIConfig()
    const response = await axios.get(`${PAGE_API_URL}/${menuType}`, config)
    return response.data
}

export const getPageSettingsById = async (id: PageType['id']) => {
    const config = generateAPIConfig()
    const response = await axios.get(`${PAGE_API_URL}/${id}/settings`, config)
    return response.data
}
