import axios from 'axios'
import { GLOBAL_CONFIG } from '@/config'
import { generateAPIConfig } from '@/services/utils'
import { getLogisticsParams } from './utils'

export const LOGISTICS_API_URL = GLOBAL_CONFIG.BASE_URL + 'logistics'

/**
 * GET logistics data
 * @start_date mandatory
 * @end_date mandatory
 * @warehouses_names optional
 * @countries optional ('IL,US:NY,CA:BC,VG')
 * @service_types optional
 */

export const getLogistics = async (data) => {
    const config = generateAPIConfig({ params: getLogisticsParams(data) })
    const response = await axios.get(LOGISTICS_API_URL, config)
    return response.data
}

/**
 * GET warehouses
 */

export const getWarehouses = async () => {
    const config = generateAPIConfig()
    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses`, config)
    return response.data
}

/**
 * GET countries
 * @start_date mandatory
 * @end_date mandatory
 * @warehouses_names optional
 * @service_types optional
 */

export const getWarehouseCountries = async (data) => {
    const { start_date, end_date, warehouses_names, service_types } = getLogisticsParams(data)
    const config = generateAPIConfig({
        params: { start_date, end_date, warehouses_names, service_types },
    })
    const response = await axios.get(`${LOGISTICS_API_URL}/countries`, config)
    return response.data
}

/**
 * GET service types
 * @start_date mandatory
 * @end_date mandatory
 * @countries optional
 * @warehouses_names optional
 */

export const getServiceTypes = async (data) => {
    const { start_date, end_date, countries, warehouses_names } = getLogisticsParams(data)
    const config = generateAPIConfig({
        params: { start_date, end_date, countries, warehouses_names },
    })
    const response = await axios.get(`${LOGISTICS_API_URL}/service_types`, config)
    return response.data
}

/**
 * GET warehouse cost data
 * @cost_name mandatory
 * @start_date mandatory
 * @end_date mandatory
 * @warehouse_name mandatory
 * @countries optional ('IL,US:NY,CA:BC,VG')
 * @service_types optional
 */

export const getWarehouseCost = async (data) => {
    const config = generateAPIConfig({
        params: { cost_name: data.costName, ...getLogisticsParams(data) },
    })

    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses/costs`, config)
    return response.data
}

/**
 * GET order cost report file
 * @start_date mandatory
 * @end_date mandatory
 * @warehouse_name mandatory
 * @countries optional ('IL,US:NY,CA:BC,VG')
 * @service_types optional
 */

export const getOrderCostsReport = async (data) => {
    const config = generateAPIConfig({
        responseType: 'blob',
        contentType: 'multipart/form-data',
        params: getLogisticsParams(data),
    })

    const response = await axios.get(`${LOGISTICS_API_URL}/warehouses/costs/report`, config)
    return response
}
