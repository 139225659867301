import { forwardRef } from 'react'
import type { LinkPropsType as UiLinkPropsType } from '@octup/core/ui-kit'
import { Link as UiLink } from '@octup/core/ui-kit'
import type { LinkProps as RouterLinkPropsType, To as LinkToType } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import type { APP_ROUTES } from '@/models/appConfig'

export type LinkPropsType = Omit<RouterLinkPropsType, 'to'> &
    UiLinkPropsType & {
        to?: LinkToType
        route?: APP_ROUTES
        isExternal?: boolean
    }

export const Link = forwardRef<LinkPropsType['ref'], LinkPropsType>(
    ({ to, route, isExternal, ...props }, ref) => {
        if (isExternal) {
            return <UiLink ref={ref} component="a" href={to} rel="noopener noreferrer" {...props} />
        }

        return <UiLink ref={ref} component={RouterLink} to={route ? `/${route}` : to} {...props} />
    }
)
