import { useMemo } from 'react'
import type { FORMATTER_DISPLAY_TYPES, PatternFormatterPropsType } from '@octup/core/models'
import { ColorPatternFormatter } from '@/new_components/__common__'
import { getConditionValidity } from './utils'

export const ColorPatternFormatterContainer = ({
    data,
    value,
    config,
}: PatternFormatterPropsType<FORMATTER_DISPLAY_TYPES.COLOR>) => {
    const { pattern, display_type_pattern, description } = config || {}

    const isConditionValid = useMemo(
        () => getConditionValidity(data, pattern, display_type_pattern),
        [pattern, display_type_pattern, data]
    )

    return (
        <ColorPatternFormatter value={value} description={description} isValid={isConditionValid} />
    )
}
