import { Stack, TextField } from '@octup/core/ui-kit'
import {
    FormFieldContainer,
    FormSubmitButtonContainer,
} from '@/new_containers/components/__common__'

export const ResetPasswordForm = () => (
    <Stack spacing={5}>
        <FormFieldContainer as={TextField} fullWidth name="email" label="Email" />
        <FormSubmitButtonContainer size="large" fullWidth>
            Send link
        </FormSubmitButtonContainer>
    </Stack>
)
