import { useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton, Tooltip } from '@octup/core/ui-kit'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { APP_ROUTES } from '@/models/appConfig'
import { resolveRoutePath } from '@/utils'

export const AddDashboardButtonContainer = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const disabled = /\/Dashboards\/[^/]+\/(edit|new)/.test(location.pathname)

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            const id = uuidv4()
            navigate(resolveRoutePath(APP_ROUTES.DASHBOARD_CREATE, { ':dashboardId': id }))
        },
        [navigate]
    )

    return (
        <Tooltip title="Add new board" placement="top">
            <Box>
                <IconButton onClick={handleClick} disabled={disabled}>
                    <AddIcon />
                </IconButton>
            </Box>
        </Tooltip>
    )
}
