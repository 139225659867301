import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { COMPARISON_MODES } from '@/constants/metrics'
import {
    getBreakdownMetrics,
    getDeepdiveMetrics,
    setIsBreakdown,
    setSelectedMetric,
} from '@/features/sections/deepdivesSectionsSlice'
import { ErrorMessageFallback } from '@/new_components'
import { MetricDeepDive } from '@/new_components/Metrics'
import { getComparisonDates } from './utils'

export const MetricDeepDiveContainer = ({
    metricId,
    deepDiveId,
    blendedDeepDiveId,
    datePicker,
    clientId = null,
    metricsGroup = [],
}) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const {
        deepDiveRawData,
        breakdownRawData,
        isLoading,
        isLoadingGetBreakdownMetrics,
        isRejected,
        isRejectedGetBreakdownMetrics,
        comparisonMode,
        comparisonMetric,
        isBreakdown,
    } = useSelector((state) => state.deepdivesSection)
    const currentClientId = clientId || userInfo.client.id

    const comparisonDates = useMemo(
        () => getComparisonDates({ comparisonMode, datePicker }),
        [comparisonMode, datePicker]
    )

    const dataParams = useMemo(
        () => ({ datePickerValues: datePicker, comparisonDates, clientId: currentClientId }),
        [datePicker, comparisonDates, currentClientId]
    )

    const isDisabled =
        !datePicker.label ||
        (comparisonMode === COMPARISON_MODES.METRIC && !comparisonMetric) ||
        +metricId === +comparisonMetric?.metric_id

    useEffect(() => {
        if (isDisabled) return

        if (deepDiveId) {
            dispatch(
                getDeepdiveMetrics({ segments: [deepDiveId], comparisonMetric, ...dataParams })
            )
        }
    }, [comparisonMetric, dataParams, deepDiveId, dispatch, isDisabled])

    useEffect(() => {
        if (isDisabled) return

        if (blendedDeepDiveId && !comparisonMetric) {
            dispatch(getBreakdownMetrics({ segments: [blendedDeepDiveId], ...dataParams }))
        } else {
            dispatch(setIsBreakdown(false))
        }
    }, [blendedDeepDiveId, comparisonMetric, dataParams, dispatch, isDisabled])

    const data = isBreakdown ? breakdownRawData : deepDiveRawData

    useEffect(() => {
        const metricsData = Object.values(data || {})[0] || []
        const selectedMetricData = metricsData.find(({ metric }) => metric?.metric_id === +metricId)
        dispatch(setSelectedMetric(selectedMetricData?.metric))
    }, [data, dispatch, metricId])

    if ((isRejected && !isBreakdown) || (isRejectedGetBreakdownMetrics && isBreakdown))
        return <ErrorMessageFallback />

    return (
        <MetricDeepDive
            data={data}
            metricsGroup={metricsGroup}
            isLoading={isLoading || isLoadingGetBreakdownMetrics}
        />
    )
}
