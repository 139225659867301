import { useMemo } from 'react'
import { COMMON_PALETTE } from '@octup/core/theme'
import { useSelector } from 'react-redux'
import { ChartsArea } from '@/shared/ui/Charts/ChartsArea'
import { getChartConfig } from './utils'

const STYLE = { height: '100%' }

export const ChartContainer = ({ data }) => {
    const deepdiveSection = useSelector((state) => state.deepdivesSection)
    const { comparisonMetric, comparisonMode, isBreakdown, selectedMetric } = deepdiveSection

    const chartConfig = useMemo(
        () =>
            getChartConfig({ data, isBreakdown, comparisonMode, selectedMetric, comparisonMetric }),
        [comparisonMetric, comparisonMode, data, isBreakdown, selectedMetric]
    )

    return (
        <ChartsArea
            key={isBreakdown}
            id="deep-dive-metric"
            showYAxis
            showXAxis
            isFilled
            style={STYLE}
            backgroundColor={COMMON_PALETTE.grey[50]}
            {...chartConfig}
        />
    )
}
