import type { ClientType, UserType } from '@octup/core/models'

export enum DATA_LAYER_EVENTS {
    IDENTIFY_USER = 'identifyUser',
}

export type DataLayerType = {
    event: DATA_LAYER_EVENTS
    userId: UserType['id']
    userEmail: UserType['email']
    userFullName: UserType['fullName']
    clientName: ClientType['name']
    clientId: ClientType['id']
    clientPlan: ClientType['plan']
}
