import type { DashboardTemplateGroupType } from '@octup/core/models'
import { Grid } from '@octup/core/ui-kit'
import { generateNumberArray } from '@octup/core/utils'
import { GroupTitle } from './GroupTitle'
import { containerStyle, sectionContainerStyle } from './styles'

export type DashboardSectionPropsType = { index: number } & Pick<
    DashboardTemplateGroupType,
    'group'
>

export type DashboardPropsType = {
    groups?: DashboardTemplateGroupType[]
    totalHeightUnits?: number
    renderSection: (props: DashboardSectionPropsType) => JSX.Element | null
    showGroupName?: boolean
}

export const Dashboard = ({
    groups,
    totalHeightUnits = 12,
    renderSection,
    showGroupName = false,
    ...props
}: DashboardPropsType) => {
    let sectionIndex = -1

    return (
        <Grid container direction="column" flexWrap="nowrap" gap={3} sx={containerStyle} {...props}>
            {groups?.map(
                ({
                    id,
                    group,
                    icon,
                    itemsPerRow,
                    heightUnits,
                    itemsCount,
                    description,
                    previewImg,
                }) => (
                    <Grid
                        key={id}
                        item
                        container
                        direction="column"
                        flexWrap="nowrap"
                        gap={1.5}
                        xs={heightUnits}
                        columns={totalHeightUnits}
                    >
                        {group && showGroupName && (
                            <Grid item container xs={1}>
                                <GroupTitle
                                    group={group}
                                    icon={icon}
                                    description={description}
                                    previewImg={previewImg}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            xs
                            spacing={3}
                            columns={totalHeightUnits}
                            height="100%"
                        >
                            {generateNumberArray(itemsCount).map((value) => {
                                sectionIndex += 1

                                return (
                                    <Grid
                                        key={value}
                                        item
                                        xs={totalHeightUnits}
                                        md={totalHeightUnits / Math.round(itemsPerRow / 2)}
                                        lg={totalHeightUnits / itemsPerRow}
                                        sx={sectionContainerStyle(itemsCount, itemsPerRow)}
                                    >
                                        {renderSection({ index: sectionIndex, group })}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                )
            )}
        </Grid>
    )
}
