import PageLayout from '@/components/PageLayout/PageLayout'
import type { MetricsDashboardProviderPropsType } from '@/new_containers/components/__common__'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'

type DashboardPagePropsType = MetricsDashboardProviderPropsType & {
    sidePanelTools?: React.ReactNode
    hasCustomTitle?: boolean
    customTitleStyled?: string
}

export const DashboardPage = ({
    dashboardId,
    noDataText,
    dateRange,
    ...props
}: DashboardPagePropsType) => (
    //  @ts-expect-error: PageLayout is a JavaScript file
    <PageLayout {...props}>
        <MetricsDashboardProvider
            dashboardId={dashboardId}
            noDataText={noDataText}
            dateRange={dateRange}
        />
    </PageLayout>
)
