import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersTableContainer } from '@/new_containers/components/Orders'
import PageLayout from '../../components/PageLayout/PageLayout'
import { SLAConfig } from '../../components/SLAConfig/SLAConfig'
import { getOrdersSLAStatus } from '../../features/metrics/metricsSlice'
import { getFulfilledOrdersSLARange } from '../../features/sections/slaSectionSlice'
import { CardBase } from '../../shared/ui/Card/CardBase'
import { OctupTabs } from '../../shared/ui/Tabs/OctupTabs'

const OperationsOverviewPage = () => {
    const dispatch = useDispatch()
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const { slaConfig, isSlaConfigLoading } = useSelector((state) => state.metrics)
    const { slaRange, isLoading: isSlaRangeLoading } = useSelector((state) => state.sla)
    const { fulfillment_min_sla, fulfillment_max_sla } = slaRange || {}
    const [filter, setFilter] = useState(1)
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        dispatch(getFulfilledOrdersSLARange)
    }, [dispatch])

    const fetchOrders = useCallback(
        (params) => dispatch(getOrdersSLAStatus({ filter, datePickerValues, ...params })),
        [dispatch, filter, datePickerValues]
    )

    const rangeTabs = useMemo(
        () => [
            {
                title: `Under ${fulfillment_min_sla} days`,
                key: `Under ${fulfillment_min_sla} days`,
                isActive: true,
                filterKey: 1,
            },
            {
                title: `${fulfillment_min_sla}-${fulfillment_max_sla} days`,
                key: `${fulfillment_min_sla}-${fulfillment_max_sla} days`,
                filterKey: 2,
            },
            {
                title: `Over ${fulfillment_max_sla} days`,
                key: `Over ${fulfillment_max_sla} days`,
                filterKey: 3,
            },
        ],
        [fulfillment_min_sla, fulfillment_max_sla]
    )

    return (
        <PageLayout isLoading={!slaRange && isSlaRangeLoading} hasFixedHeight>
            <div
                style={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2rem',
                    paddingBottom: '2rem',
                }}
            >
                <OctupTabs
                    tabsData={rangeTabs}
                    clickHandlerCallback={(el) => {
                        setFilter(el.filterKey)
                        setActiveTab(el.filterKey - 1)
                    }}
                    defaultActiveTab={rangeTabs[activeTab]?.title}
                />
                <SLAConfig />
            </div>
            {slaRange && (
                <CardBase>
                    <OrdersTableContainer
                        key={`${filter}-${fulfillment_min_sla}-${fulfillment_max_sla}`}
                        config={slaConfig}
                        isLoading={isSlaConfigLoading}
                        fetchData={fetchOrders}
                    />
                </CardBase>
            )}
        </PageLayout>
    )
}

export default OperationsOverviewPage
