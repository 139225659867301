import { useState, useCallback, useEffect } from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'
import type { DATE_PICKER_VIEW_TYPES } from '@octup/core/models'
import { DATE_PICKER_CONFIG_BY_VIEW_TYPE, DEFAULT_SLOTS, MIN_DATE, MAX_DATE } from './utils'

export type DatePickerType = MuiDatePickerProps<Date>

export type DatePickerPropsType = Omit<DatePickerType, 'loading' | 'onAccept'> & {
    isLoading?: boolean
    acceptFormat?: string
    onAccept?: (date: DatePickerType['value']) => void
    viewType: DATE_PICKER_VIEW_TYPES
}

export const DatePicker = ({
    viewType,
    onAccept,
    isLoading,
    value: newValue,
    minDate = MIN_DATE,
    maxDate = MAX_DATE,
    slots,
    ...props
}: DatePickerPropsType) => {
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState<DatePickerType['value']>(newValue)
    const handleOpen = useCallback(() => setIsOpen(true), [])
    const handleClose = useCallback(() => setIsOpen(false), [])
    const configByViewType = DATE_PICKER_CONFIG_BY_VIEW_TYPE[viewType]

    useEffect(() => {
        setValue(newValue)
    }, [newValue])

    return (
        <MuiDatePicker
            open={isOpen}
            value={value}
            disableFuture
            monthsPerRow={4}
            minDate={minDate}
            maxDate={maxDate}
            loading={isLoading}
            onOpen={handleOpen}
            onClose={handleClose}
            onChange={setValue}
            onAccept={onAccept}
            slots={{ ...DEFAULT_SLOTS, ...slots }}
            {...configByViewType}
            {...props}
        />
    )
}
