import { Typography } from '@octup/core/ui-kit'
import { APP_ROUTES } from '@/models/appConfig'
import { Link } from '@/new_components/__common__'
import { ResetPasswordFormContainer } from '@/new_containers/components/Auth'
import { AuthLayoutContainer } from '@/new_containers/layouts'

export const ResetPasswordPage = () => (
    <AuthLayoutContainer title="Reset your password">
        <Typography>
            Enter the email address used to sign up for your octup account, we'll send you a link to
            reset your password.
        </Typography>
        <ResetPasswordFormContainer />
        <Link route={APP_ROUTES.LOGIN} variant="body2" width="fit-content">
            Back to Login
        </Link>
    </AuthLayoutContainer>
)
