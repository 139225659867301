const LIST_ITEM_ICON_WIDTH = '3.5rem'

export const dashboardStyle = {
    '& .MuiAppBar-root': {
        '&,  & ~ .MuiDrawer-root ': {
            '& ~ .MuiBox-root > .MuiToolbar-root, & .MuiToolbar-root': {
                minHeight: '8rem',
            },

            '& .MuiToolbar-root img': {
                maxHeight: '4.5rem',
            },
        },

        '& .MuiToolbar-root .MuiStack-root': {
            alignItems: 'center',
        },

        '& ~ .MuiDrawer-root': {
            '& .MuiListItemButton-root': {
                height: '4.5rem',
                '& .MuiSvgIcon-root': {
                    color: 'inherit',
                },
            },
            '& .MuiCollapse-root .MuiList-root': {
                paddingLeft: LIST_ITEM_ICON_WIDTH,
            },
            '& .MuiListItemIcon-root': {
                marginRight: 0,
                minWidth: LIST_ITEM_ICON_WIDTH,
            },
        },
    },
}
