import { useMemo } from 'react'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { TableMetricsSection } from '@/new_components/__common__/MetricsSections'
import { getTableConfig } from './utils'

export const TableMetricsSectionContainer = ({ data, ...props }: MetricsSectionPropsType) => {
    const tableConfig = useMemo(() => getTableConfig(data), [data])

    return <TableMetricsSection tableProps={tableConfig} {...props} />
}
