import type { MetricType } from '@octup/core/models'

export const getChartDataConfig = (metrics: MetricType[] = []) => {
    const { time_slice, currency, value_units, data_by_dates } = metrics[0] || {}
    const labels = Object.keys(data_by_dates || {})

    const chartData = metrics.map((metric) => ({
        label: metric.name,
        data: Object.values(metric.data_by_dates || {}),
        currency: metric.currency,
        valueUnits: metric.value_units,
    }))

    const hasMultipleMetrics = chartData.length > 1
    const secondMetricConfig = chartData.find((data) => data.valueUnits !== value_units)

    return {
        labels,
        chartsDataSets: chartData,
        timeSlice: time_slice,
        currency,
        valueUnits: value_units,
        isFilled: true,
        showLegend: hasMultipleMetrics,
        showSeriesNameInTooltip: hasMultipleMetrics,
        showSecondYAxis: !!secondMetricConfig,
        secondYAxisConfig: secondMetricConfig,
        numberOfShownDataSests: chartData.length,
        style: { height: '100%' },
    }
}
