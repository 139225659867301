import { useCallback } from 'react'
import { useGridApiContext } from '@mui/x-data-grid'
import { SearchTextFieldContainer } from '@octup/core/components'
import type { GridApiCommonType } from '../..'

export type DataGridToolbarSearchPropsType = {
    onSearchChange: (value: string, api: GridApiCommonType['state']) => void
}

export const DataGridToolbarSearch = ({ onSearchChange }: DataGridToolbarSearchPropsType) => {
    const apiRef = useGridApiContext()

    const handleSearchChange = useCallback(
        (value: string) => {
            const { forceUpdate, state } = apiRef.current
            ;(state as typeof state & { search: string }).search = value
            forceUpdate()
            onSearchChange(value, state)
        },
        [apiRef, onSearchChange]
    )

    return <SearchTextFieldContainer fullWidth onChange={handleSearchChange} />
}
