import { CHART_TYPES } from 'constants'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DEFAULT_STYLES } from 'shared/ui/Charts/octupChartsUtils'
import { VALUE_TYPE_INDEX, getFormattedVal } from 'shared/utils/metrics-utils'
import { getDoughnutChartData, getLineChartData } from '../utils'

export const useDashboardChartCards = ({ isLoading }) => {
    const { sections } = useSelector((state) => state.logistics)
    const { total_costs, cost_comparison } = sections.data || {}

    const charts = useMemo(
        () => [
            {
                id: 'totalCostsBreakdown',
                title: 'Total Costs Breakdown',
                chartConfig: {
                    type: CHART_TYPES.DOUGHNUT,
                    title: getFormattedVal({
                        val: total_costs?.total,
                        customNumOfDigits: 0,
                        valueType: VALUE_TYPE_INDEX.NUMBER,
                        showSymbol: true,
                        isShopCurrency: true,
                    }),
                    maxSize: '32rem',
                    titleFontSize: '4.5rem',
                    subtitleFontSize: '1.7rem',
                    subtitle: 'Net Cost (-Discounts)',
                    isShopCurrency: true,
                    isLoading,
                    ...getDoughnutChartData(total_costs?.data),
                },
            },
            {
                id: 'costComparison',
                title: 'Cost vs. Sales',
                chartConfig: {
                    type: CHART_TYPES.LINE,
                    id: 'cost-comparison',
                    showYAxis: true,
                    showXAxis: true,
                    isFilled: true,
                    showLegend: true,
                    style: { height: '32rem' },
                    timeSlice: cost_comparison?.time_slice,
                    breathingHeight: '20rem',
                    isShopCurrency: true,
                    valueUnits: VALUE_TYPE_INDEX.NUMBER,
                    sx: { ...DEFAULT_STYLES, legends: { paddingBottom: 3, fontSize: 12 } },
                    isLoading,
                    ...getLineChartData(cost_comparison?.data),
                },
            },
        ],
        [cost_comparison, isLoading, total_costs]
    )

    return charts
}
