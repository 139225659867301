import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import type { DateView as DateViewType } from '@mui/x-date-pickers'
import { DATE_PICKER_VIEW_TYPES } from '@octup/core/models'
import { ButtonField } from './ButtonField'

export const MIN_DATE = new Date(2020, 0)
export const MAX_DATE = new Date()

export const DEFAULT_SLOTS = {
    field: ButtonField,
    switchViewIcon: KeyboardArrowDownOutlined,
}

export type DatePickerConfigType = {
    openTo: DateViewType
    views: DateViewType[]
    format: string
}

export const DATE_PICKER_CONFIG_BY_VIEW_TYPE: Record<DATE_PICKER_VIEW_TYPES, DatePickerConfigType> =
    {
        [DATE_PICKER_VIEW_TYPES.DAILY]: {
            openTo: 'day',
            format: 'EEE. MMM dd, yyyy',
            views: ['year', 'month', 'day'],
        },
        [DATE_PICKER_VIEW_TYPES.MONTHLY]: {
            openTo: 'month',
            format: 'MMM. yyyy',
            views: ['year', 'month'],
        },
    }
