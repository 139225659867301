import type { PatternFormatterComponentType } from '@octup/core/models'
import { FORMATTER_DISPLAY_TYPES } from '@octup/core/models'
import { ColorPatternFormatterContainer } from './ColorPatternFormatterContainer'
import { LinkPatternFormatterContainer } from './LinkPatternFormatterContainer'

export const COMPONENT_BY_DISPLAY_TYPE: {
    [key in FORMATTER_DISPLAY_TYPES]: PatternFormatterComponentType<key>
} = {
    [FORMATTER_DISPLAY_TYPES.COLOR]: ColorPatternFormatterContainer,
    [FORMATTER_DISPLAY_TYPES.LINK]: LinkPatternFormatterContainer,
}
