import { useCallback, useEffect, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import { differenceInDays } from 'date-fns'
import { useQueryString, useToast } from 'hooks'
import { PaymentFlow } from '@/components/Payment/PaymentFlow'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { useAppSelector } from '@/state/hooks'

export const PaymentModalContainer = () => {
    const toast = useToast()
    const { queryParams } = useQueryString()
    const [paymentModal, setPaymentModal] = useState(queryParams.payment)
    const [paymentCurrentStep, setPaymentCurrentStep] = useState(0)
    const { isPaymentRequired } = useAppSelector((state) => state.session)
    const { userInfo } = useAppSelector((state) => state.auth)

    const checkLCExp = useCallback(() => {
        if (userInfo?.client?.plan?.toLowerCase() !== 'trial') return
        const showLCCountdown = JSON.parse(localStorage.getItem('showLCCountdown') || '{}')
        if (showLCCountdown === false) return
        const lastDay = userInfo?.client?.expiredPaymentDate
        if (lastDay) {
            const daysGap = differenceInDays(new Date(lastDay), new Date())
            if (daysGap > 0 && daysGap < 8) {
                const button = (
                    <Button
                        size="small"
                        sx={(theme) => ({ color: theme.palette.common.white })}
                        onClick={() => {
                            setPaymentCurrentStep(1)
                            setPaymentModal(true)
                        }}
                    >
                        Explore Plans
                    </Button>
                )
                toast.error(
                    `Your free-trial will end in ${daysGap} day${
                        daysGap > 1 ? 's' : ''
                    }! Go ahead, select a plan and set a payment method`,
                    null,
                    null,
                    button
                )

                // NOTE: setTimeout is a temporary solution
                setTimeout(() => {
                    localStorage.setItem('showLCCountdown', 'false')
                }, 5000)
            }
        }
    }, [toast, userInfo?.client?.expiredPaymentDate, userInfo?.client?.plan])

    useEffect(() => {
        checkLCExp()
    }, [checkLCExp])

    if (!isPaymentRequired && !paymentModal) return null

    return (
        //  @ts-expect-error: OctupBaseModal is a JavaScript file
        <OctupBaseModal
            showCloseBtn={paymentCurrentStep > 0}
            onDismissModal={() => {
                if (paymentCurrentStep === 2 || !isPaymentRequired) setPaymentModal(false)
                else setPaymentCurrentStep(0)
            }}
        >
            <div style={{ maxHeight: '78vh' }}>
                <PaymentFlow
                    isTrial={true}
                    // TODO: Replace with real plans
                    currentStep={paymentCurrentStep}
                    setCurrentStep={setPaymentCurrentStep}
                    closeModal={() => {
                        setPaymentModal(false)
                    }}
                />
            </div>
        </OctupBaseModal>
    )
}
