import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ERROR_MESSAGES } from '@/constants/validation'
import { validateEmail } from '@/features/auth/authSlice'
import { useToast } from '@/hooks/useToastContext'
import { HTTP_STATUS_CODES } from '@/models/network'
import { inviteUser } from '@/services/users'
import { ErrorMessage } from '@/shared/styledComponents'
import { isEmailValid } from '@/shared/ui/Inputs/inputUtils'
import { OctupInputField } from '@/shared/ui/Inputs/OctupInputField'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'

export const InviteUserModal = ({ clientId, onUpdateData, onClose }) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [hideLabel, setHideLabel] = useState(false)

    const handleCancel = useCallback(() => {
        setEmail('')
        setEmailError('')
        onClose()
    }, [onClose])

    const handleInvite = useCallback(async () => {
        if (isEmailValid(email)) {
            const validateEmailRes = await dispatch(validateEmail({ email }))
            if (validateEmailRes?.payload?.valid) {
                onClose()
                const response = await inviteUser({ email, clientId })

                if (response.status === HTTP_STATUS_CODES.OK) {
                    toast.success(`Invite has been sent to ${email}`)
                } else {
                    toast.error(ERROR_MESSAGES.SERVER_ERROR)
                }

                setEmail('')
                onUpdateData()
            } else {
                setEmailError(validateEmailRes?.payload?.message)
            }
        } else {
            setEmailError('Please enter a valid email address\n' + '\n')
        }
    }, [clientId, dispatch, email, onClose, onUpdateData, toast])

    return (
        <OctupBaseModal
            title="Add a member to your team"
            onDismissModal={handleCancel}
            buttons={[
                { title: 'cancel', variant: 'secondary', onClick: handleCancel },
                { title: 'Invite', variant: 'contained', onClick: handleInvite },
            ]}
        >
            <MainContainer>
                <OctupInputField
                    placeholder={'Enter an email address'}
                    width={'40rem'}
                    transformX={'-2.8rem'}
                    transformY={'-0.4rem'}
                    labelBIId={'Invite User Input'}
                    hideLabel={hideLabel}
                    value={email}
                    padding={'3rem 0 3rem 2rem'}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setEmailError('')
                        if (email?.length > 0) {
                            setHideLabel(true)
                        }
                    }}
                    onFocusCallback={() => setHideLabel(true)}
                    onBlur={() => {
                        if (email?.length === 0) {
                            setHideLabel(false)
                        } else if (!isEmailValid(email)) {
                            setEmailError('Please enter a valid email address')
                        }
                    }}
                />
                <ErrorContainer>
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                </ErrorContainer>
            </MainContainer>
        </OctupBaseModal>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 67rem;
`

const ErrorContainer = styled.div`
    padding-top: 2rem;
    padding-bottom: 1rem;
`
