import { Accordion, AccordionSummary, OverflowTypography, Stack } from '@octup/core/ui-kit'
import type { ClientSettingsType } from '@/models/settings'
import { AccordionFormContent } from '@/new_components/__common__/Form'
import { ClientSettingsFormContainer } from '@/new_containers/components/Clients'

type ClientsSettingsPropsType = {
    data?: ClientSettingsType[]
}

export const ClientsSettings = ({ data }: ClientsSettingsPropsType) => (
    <Stack width="100%">
        {data?.map((client, index) => (
            <Accordion key={client.id} defaultExpanded={index === 0}>
                <AccordionSummary>
                    <OverflowTypography variant="h6">{client.name}</OverflowTypography>
                </AccordionSummary>
                <ClientSettingsFormContainer data={client} FormContent={AccordionFormContent} />
            </Accordion>
        ))}
    </Stack>
)
