import { OctupColors } from '../../theme/colors/OctupColors'

export const getIconColor = (active = false, customColor, customActiveColor, disabled = false) => {
    if (disabled) {
        return 'gray'
    }
    if (active) {
        if (customActiveColor) {
            return customActiveColor
        } else return OctupColors.primaryActionableLight
    } else if (customColor) {
        return customColor
    }
    return OctupColors.cardsBackground
}
