export enum FORMATTER_DISPLAY_TYPES {
    LINK = 'link',
    COLOR = 'color',
}

export enum LINK_DISPLAY_TYPE_PATTERNS {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export enum COLOR_DISPLAY_TYPE_PATTERNS {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}

export type DisplayTypePattern<T extends FORMATTER_DISPLAY_TYPES> =
    T extends FORMATTER_DISPLAY_TYPES.LINK
        ? LINK_DISPLAY_TYPE_PATTERNS
        : T extends FORMATTER_DISPLAY_TYPES.COLOR
          ? COLOR_DISPLAY_TYPE_PATTERNS
          : T

export type PatternFormatterConfigType<
    T extends FORMATTER_DISPLAY_TYPES = FORMATTER_DISPLAY_TYPES,
> = {
    pattern: string
    display_type: T
    display_type_pattern: DisplayTypePattern<T>
    description?: string
}
