import { useEffect, useMemo } from 'react'
import { AppProvider } from '@toolpad/core/react-router-dom'
import { Outlet } from 'react-router-dom'
import { useAbilities, useSidebarNavigation } from '@/hooks'
import { APP_FEATURES } from '@/models/appConfig'
import { LoadingView } from '@/new_components/__common__'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import { useAxiosInterceptors } from '@/services/hooks'
import { fetchAppSettings, fetchExcludedFeatures } from '@/state/features/appConfig'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { createTheme } from '@/theme'
import { changeFavicon, intercom } from '@/utils'

const BRANDING = { title: '', logo: <CompanyNameLogoContainer width="auto" /> }

export const App = () => {
    useAxiosInterceptors()
    const dispatch = useAppDispatch()
    const navigation = useSidebarNavigation()
    const { isFeatureAvailable } = useAbilities()
    const theme = useMemo(() => createTheme(), [])
    const { settings, excludedFeatures } = useAppSelector((state) => state.appConfig)
    const { sidebarPages } = useAppSelector((state) => state.pages)
    const isLoading = settings.isLoading || excludedFeatures.isLoading || sidebarPages.isLoading
    const { favicon } = settings.data?.icons || {}

    useEffect(() => {
        dispatch(fetchAppSettings())
        dispatch(fetchExcludedFeatures())
    }, [dispatch])

    useEffect(() => {
        if (favicon) {
            changeFavicon(favicon)
        }
    }, [favicon])

    useEffect(() => {
        isFeatureAvailable(APP_FEATURES.INTERCOM) ? intercom.show() : intercom.hide()
    }, [isFeatureAvailable])

    return (
        <AppProvider navigation={navigation} branding={BRANDING} theme={theme}>
            {isLoading ? <LoadingView /> : <Outlet />}
        </AppProvider>
    )
}
