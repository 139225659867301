import { Box } from '@octup/core/ui-kit'
import { OrderModalTitle } from './OrderModalTitle'
import { SingleOrderDeepDive } from './SingleOrderDeepDive'
import { OctupBaseModal } from '../../../shared/ui/Modal/OctupBaseModal'

export const SingleOrderDeepDiveModal = ({ selectedItem, onDismissModal }) => (
    <OctupBaseModal
        onDismissModal={onDismissModal}
        title={<OrderModalTitle selectedItem={selectedItem} />}
    >
        <Box width="80vw" maxWidth="135rem" height="80vh" maxHeight="80rem">
            <SingleOrderDeepDive orderId={selectedItem.order_id} />
        </Box>
    </OctupBaseModal>
)
