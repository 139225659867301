import type { MetricsSectionByClientIdType, MetricsSectionStateType } from '@octup/core/models'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type {
    GetMetricsSectionType,
    MetricsSectionByIdRequestType,
    MetricsSectionUpdateType,
} from '@/services/metricsSections'
import type { ThunkAPIConfigType } from '@/services/utils'
import { updateObjectByPath } from '@/utils'
import { FETCHER_BY_SECTION_TYPE, getUpdatedStateData } from './utils'

export type MetricsSectionsStateType = {
    data?: MetricsSectionByClientIdType
}

const INITIAL_STATE: MetricsSectionsStateType = {
    data: undefined,
}

export const fetchMetricsSectionById = createAsyncThunk<
    Awaited<ReturnType<GetMetricsSectionType>>,
    MetricsSectionByIdRequestType,
    ThunkAPIConfigType
>('fetchMetricsSectionById', (data, thunkAPI) => {
    const fetch = FETCHER_BY_SECTION_TYPE[data.type] ?? FETCHER_BY_SECTION_TYPE.default
    return fetch(data, thunkAPI)
})

export const fetchUpdateMetricsSection = createAsyncThunk<
    Awaited<ReturnType<MetricsSectionUpdateType['fetch']>>,
    MetricsSectionUpdateType,
    ThunkAPIConfigType
>('fetchUpdateMetricsSection', (data, thunkAPI) => {
    const { fetch, params } = data
    return fetch(params, thunkAPI)
})

export const metricsSectionsSlice = createSlice({
    name: 'metricsSections',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetricsSectionById.pending, (state, action) => {
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params: action.meta.arg,
                    getNewData: () => ({ isLoading: true, error: undefined }),
                })
            })
            .addCase(fetchMetricsSectionById.fulfilled, (state, action) => {
                const { data, hasContent } = action.payload
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params: action.meta.arg,
                    getNewData: () => ({ data, hasContent, isLoading: false }),
                })
            })
            .addCase(fetchMetricsSectionById.rejected, (state, action) => {
                const error = action.error.message
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params: action.meta.arg,
                    getNewData: () => ({ isLoading: false, error }),
                })
            })

            .addCase(fetchUpdateMetricsSection.pending, (state, action) => {
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params: action.meta.arg.params,
                    getNewData: () => ({ isDataUpdating: true, error: undefined }),
                })
            })
            .addCase(fetchUpdateMetricsSection.fulfilled, (state, action) => {
                const newData = action.payload
                const { params, path } = action.meta.arg
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params,
                    getNewData: (sectionState: MetricsSectionStateType) => ({
                        isDataUpdating: false,
                        data: updateObjectByPath(newData, sectionState?.data, path),
                    }),
                })
            })
            .addCase(fetchUpdateMetricsSection.rejected, (state, action) => {
                const error = action.error.message
                state.data = getUpdatedStateData({
                    stateData: state.data,
                    params: action.meta.arg.params,
                    getNewData: () => ({ isDataUpdating: false, error }),
                })
            })
    },
})

export const metricsSectionsReducer = metricsSectionsSlice.reducer
