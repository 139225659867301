import type { CustomTableMetricsSectionType } from '@octup/core/models'
import { VALUE_UNIT_TYPES } from '@octup/core/models'
import type { DataGridPropsType, GridColDefType, GridRowModelType } from '@octup/core/ui-kit'
import { formatNumber } from '@octup/core/utils'
import { PatternFormatterContainer } from '@/new_containers/components/__common__/PatternFormatterContainer'
import { getInputDate } from '@/shared/utils/dates-util'

const DEFAULT_ROW_ID_COLUMN_NAME = 'id'
const DEFAULT_PAGINATION_MODEL = { page: 0, pageSize: 100 }
const DEFAULT_PAGE_OPTIONS = [25, 50, 100, { label: 'All', value: -1 }]

export const getTableConfig = (tableData?: CustomTableMetricsSectionType): DataGridPropsType => {
    const { config, data } = tableData || {}
    const { data: rows = [], metadata } = data || {}
    const { columns_config = [], table_config } = config || {}
    const { density, row_id_column_name, default_values, display_name } = table_config || {}

    const getRowId = (row: GridRowModelType) =>
        (row_id_column_name && row[row_id_column_name]) || row[DEFAULT_ROW_ID_COLUMN_NAME]

    const columns: GridColDefType<(typeof rows)[0]>[] = columns_config.map((column) => ({
        field: column.name,
        headerName: column.display_name,
        sortable: column.sortable,
        disableColumnMenu: true,
        flex: 1,
        ...(column.cell_settings && {
            renderCell: (params) => (
                <PatternFormatterContainer
                    data={params.row}
                    value={params.formattedValue}
                    config={column.cell_settings!}
                />
            ),
        }),
        valueFormatter: (value: number | string | null) => {
            if (column.value_units === VALUE_UNIT_TYPES.STRING) return `${value}`
            if (column.value_units === VALUE_UNIT_TYPES.DATE) return getInputDate(value)

            return formatNumber({
                value: value as number,
                valueUnits: column.value_units,
                currency: column.currency,
            })
        },
    }))

    const defaultSortModel = default_values?.sort_by
        ? [{ field: default_values.sort_by, sort: default_values.sort_descending }]
        : []

    const initialState = {
        pagination: { paginationModel: DEFAULT_PAGINATION_MODEL },
        sorting: { sortModel: defaultSortModel },
    }

    return {
        rows,
        columns,
        density,
        getRowId,
        initialState,
        sortingMode: 'server',
        paginationMode: 'server',
        rowCount: metadata?.total_rows || 0,
        pageSizeOptions: DEFAULT_PAGE_OPTIONS,
        slotProps: { toolbar: { csvOptions: { fileName: display_name } } },
    }
}
