import { Navigate, Outlet } from 'react-router-dom'
import { DEFAULT_ROUTES } from '@/models/appConfig'
import { useAppSelector } from '@/state/hooks'

export const AuthRedirectLayoutContainer = () => {
    const { userInfo } = useAppSelector((state) => state.auth)

    if (userInfo?.isActive) {
        return <Navigate to={DEFAULT_ROUTES.MAIN} replace />
    }

    return <Outlet />
}
