import type { Theme } from '@mui/material'
import type { DataGridComponents } from '@mui/x-data-grid/themeAugmentation'

type DialogOverridesType = Pick<DataGridComponents<Theme>, 'MuiDataGrid'>

export const DATA_GRID_OVERRIDES: DialogOverridesType = {
    MuiDataGrid: {
        defaultProps: {
            disableRowSelectionOnClick: true,
        },
        styleOverrides: {
            root: ({ theme }) => ({
                '--DataGrid-rowBorderColor': theme.palette.divider,
            }),
            columnSeparator: ({ theme }) => ({
                color: theme.palette.divider,
            }),
        },
    },
}
