import { useEffect, useMemo } from 'react'
import { Box } from '@octup/core/ui-kit'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAbilities } from '@/hooks'
import { TabsPageLayout } from '@/layouts'
import type { APP_ROUTES } from '@/models/appConfig'
import type { TabPageType } from '@/models/pages'

export type TabsPageLayoutContainerPropsType = {
    tabs: TabPageType[]
}

export const TabsPageLayoutContainer = ({ tabs }: TabsPageLayoutContainerPropsType) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { section = '' } = useParams()
    const { isPageAvailable } = useAbilities()
    const currentRoute = location.pathname.substring(1) as APP_ROUTES

    const availableTabs = useMemo(
        () => tabs.filter((tab) => isPageAvailable(tab.route)),
        [isPageAvailable, tabs]
    )

    useEffect(() => {
        const defaultRoute = availableTabs[0]?.route
        if (!section && defaultRoute) {
            navigate(`/${defaultRoute}`)
        }
    }, [section, navigate, availableTabs])

    const currentTab = useMemo(
        () => availableTabs.find((tab) => tab.route === currentRoute),
        [availableTabs, currentRoute]
    )

    const { Component: Page = Box } = currentTab || {}

    if (!currentTab) return null
    if (availableTabs.length === 1) return <Page />

    return (
        <TabsPageLayout tabs={availableTabs} currentRoute={currentRoute}>
            <Page />
        </TabsPageLayout>
    )
}
