import { Greeting } from '@/components/Greeting/Greeting'
import PageLayout from '@/components/PageLayout/PageLayout'
import { HomeAlternativeView } from './homepage-components/HomeAlternativeView'
import { HomePageLayout } from './HomePage.styles'

const HomePage = () => (
    <>
        <PageLayout customTitleStyled={<Greeting />} hasFixedHeight>
            <HomePageLayout>
                <HomeAlternativeView />
            </HomePageLayout>
        </PageLayout>
    </>
)

export default HomePage
