// TODO: re-write MetricsDetailsModal to make it more reusable

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MetricsDetailsModal } from '@/components/Modals/MetricsModals/MetricsDetailsModal'
import { OTHER_COST } from '@/constants/warehouse'
import { useQueryString } from '@/hooks'
import { fetchWarehouseCost } from '@/state/features/logistics'
import { useLogisticsDatePicker } from '../hooks'
import { getMetricDetails, getData } from './DeepDiveCostModalContainer.utils'

export const DeepDiveCostModalContainer = ({ deepDiveModal, costs, onClose, onSelect }) => {
    const dispatch = useDispatch()
    const { queryParams } = useQueryString()
    const { dateRange } = useLogisticsDatePicker()
    const [data, setData] = useState([])
    const [selectedMetricData, setSelectedMetricData] = useState(null)
    const [metricDetails, setMetricDetails] = useState({ showGraph: true, tableConfig: {} })
    const { otherCost } = useSelector((state) => state.logistics.sections)
    const { warehouseCost } = useSelector((state) => state.logistics)
    const { data: costData, isLoading, error } = warehouseCost
    const isOtherCost = deepDiveModal?.selectedDDMetric === OTHER_COST

    const handleSelectedMetricChange = useCallback(() => {
        const { selectedDDMetric } = deepDiveModal
        const newData = getData({ costs, costData })
        const selectedData = newData.find(({ metric }) => metric?.metricTitle === selectedDDMetric)
        setData(newData)
        setSelectedMetricData(selectedData)
        setMetricDetails(
            getMetricDetails({ name: selectedData?.metric?.metricTitle, data: costData, otherCost })
        )
    }, [costData, costs, deepDiveModal, otherCost])

    useEffect(() => {
        if (deepDiveModal) {
            handleSelectedMetricChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costData])

    useEffect(() => {
        const { selectedDDMetric } = deepDiveModal

        if (selectedDDMetric === OTHER_COST) {
            return handleSelectedMetricChange()
        }

        dispatch(fetchWarehouseCost({ ...dateRange, ...queryParams, costName: selectedDDMetric }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deepDiveModal?.selectedDDMetric, queryParams, dateRange])

    const onMetricSelection = useCallback(
        (selectedItem) => {
            if (selectedItem.value) {
                onSelect(selectedItem.value.metric)
            }
        },
        [onSelect]
    )

    const showGraph = useMemo(
        () => metricDetails.showGraph || isLoading,
        [metricDetails.showGraph, isLoading]
    )

    const isLoadingDeepDives = useMemo(
        () => isLoading || !metricDetails.tableConfig[deepDiveModal.selectedDDMetric],
        [metricDetails.tableConfig, deepDiveModal.selectedDDMetric, isLoading]
    )

    if (!data.length) return null

    return (
        <MetricsDetailsModal
            data={data}
            onClose={onClose}
            isBreakdown={false}
            deepdiveModal={deepDiveModal}
            selectedMetricData={selectedMetricData}
            onMetricSelection={onMetricSelection}
            deepdives={metricDetails.tableConfig}
            showGraph={showGraph}
            originalReferenceType
            isLoadingDeepdives={isLoadingDeepDives}
            error={error && !isOtherCost}
        />
    )
}
