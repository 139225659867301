import { ContactSvgIcon } from '@/shared/assets/icons/ContactSvgIcon'

export const MENU_CONFIG = {
    options: [
        {
            title: 'Contact Support',
            leftIcon: <ContactSvgIcon />,
            clickHandler: () => {
                window.open(
                    'mailto:support@octup.com?subject=Octup%20New%20Support%20Ticket&body=Please%20assist%20with%20the%20following%20:'
                )
            },
        },
    ],
}
