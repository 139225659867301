import { Stack, Typography, Button, Box } from '@octup/core/ui-kit'
import { WarningIconSvg } from 'shared/assets/icons'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { containerStyle } from './NoDataFallback.styles'

export const NoDataFallback = ({
    title = 'No data',
    text,
    subText,
    buttonTitle,
    onClick,
    icon = <WarningIconSvg customColor={OctupColors.primaryTextLight} />,
    showIcon = true,
    children,
}) => (
    <Stack
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
        spacing={1}
        sx={containerStyle}
    >
        {showIcon && icon}
        {(title || text) && (
            <Box>
                {title && (
                    <Typography variant="h6" align="center">
                        {title}
                    </Typography>
                )}
                {text && (
                    <Typography align="center" whiteSpace="pre-wrap">
                        {text}
                    </Typography>
                )}
            </Box>
        )}
        {buttonTitle && (
            <Button variant="contained" onClick={onClick}>
                {buttonTitle}
            </Button>
        )}
        {/* TODO: create a component to wrap into Text if prop is string and reuse it for text */}
        {typeof subText === 'string' ? (
            <Typography align="center" variant="body2">
                {subText}
            </Typography>
        ) : (
            subText
        )}
        {children}
    </Stack>
)
