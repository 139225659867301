import { useCallback } from 'react'
import { ERROR_MESSAGES } from '@/constants'
import { useToast } from '@/hooks'
import type { ResetPasswordType } from '@/models/auth'
import { ResetPasswordForm } from '@/new_components/Auth'
import { FormContainer } from '@/new_containers/components/__common__'
import { fetchResetPassword } from '@/state/features/auth'
import { useAppDispatch } from '@/state/hooks'
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from './utils'

export const ResetPasswordFormContainer = () => {
    const toast = useToast()
    const dispatch = useAppDispatch()

    const handleSubmit = useCallback(
        (data: ResetPasswordType) => dispatch(fetchResetPassword(data)),
        [dispatch]
    )

    const handleSuccess = useCallback(
        () => toast.success('Please check your email for the password reset link.'),
        [toast]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        //  @ts-expect-error: FormContainer is a JavaScript file
        <FormContainer
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
            defaultValues={DEFAULT_VALUES}
            validationSchema={VALIDATION_SCHEMA}
        >
            <ResetPasswordForm />
        </FormContainer>
    )
}
