import { DATA_LAYER_EVENTS } from '@/models/dataLayer'

export const identifyUser = () => {
    const rawUserInfo = localStorage.getItem('userInfo')
    const userInfo = rawUserInfo && JSON.parse(rawUserInfo)

    if (!userInfo) return

    window.dataLayer.push({
        event: DATA_LAYER_EVENTS.IDENTIFY_USER,
        userId: userInfo.id,
        userEmail: userInfo.email,
        userFullName: userInfo.fullName,
        clientName: userInfo.client.name,
        clientId: userInfo.client.id,
        clientPlan: userInfo.client.plan,
    })
}
