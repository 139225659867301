import { useCallback, useMemo } from 'react'
import { DATE_FORMAT_BY_VIEW_TYPE } from '@octup/core/constants'
import type { DateRangePickerPropsType } from '@octup/core/ui-kit'
import { DateRangePicker } from '@octup/core/ui-kit'
import { format, parseISO } from 'date-fns'
import { datePickerActions } from '@/features/datePicker/datePickerSlice'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

export type DateRangePickerContainerPropsType = Omit<DateRangePickerPropsType, 'value'>

export const DateRangePickerContainer = ({
    viewType,
    ...props
}: DateRangePickerContainerPropsType) => {
    const dispatch = useAppDispatch()
    const datePicker = useAppSelector((state) => state.datePicker)
    const dateFormat = DATE_FORMAT_BY_VIEW_TYPE[viewType]
    const { startDate, endDate } = datePicker[viewType]

    const getFormattedDate = useCallback(
        (date?: Date | null) => date && format(date, dateFormat),
        [dateFormat]
    )

    const value = useMemo(
        () => ({ startDate: parseISO(startDate), endDate: parseISO(endDate) }),
        [endDate, startDate]
    )

    const handleChange = useCallback(
        (dateRange: DateRangePickerPropsType['value']) => {
            const newDates = {
                startDate: getFormattedDate(dateRange.startDate),
                endDate: getFormattedDate(dateRange.endDate),
            }

            dispatch(datePickerActions.changeDatesByViewType({ viewType, dates: newDates }))
        },
        [dispatch, getFormattedDate, viewType]
    )

    return <DateRangePicker value={value} viewType={viewType} onAccept={handleChange} {...props} />
}
