/**
 * GUIDES:
 * https://mui.com/material-ui/customization/palette/#custom-colors
 * https://mui.com/material-ui/customization/css-theme-variables/usage/
 */

import type { PaletteOptions } from '@mui/material'

declare module '@mui/material/styles' {
    interface TypeBackground {
        contrast: string
    }
}

export const COMMON_PALETTE: PaletteOptions = {
    common: {
        black: '#000',
        white: '#FFFFFF',
    },
    primary: {
        main: '#007F82',
        light: '#00A8A8',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#504B5A',
        light: '#7F7B87',
        dark: '#282831',
        contrastText: '#FFFFFF',
    },
    error: {
        main: '#DB2059',
        light: '#FC4F6D',
        contrastText: '#FFFFFF',
    },
    info: {
        main: '#00A8A8',
        contrastText: '#FFFFFF',
    },
    success: {
        main: '#078344',
        light: '#04CE72',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#DB2059',
        light: '#FC4F6D',
        contrastText: '#FFFFFF',
    },
    grey: {
        50: '#FBFAFC',
        100: '#F1F3F7',
        200: '#F1F0F5',
        300: '#EBEAEE',
        400: '#DDDBE1',
        500: '#C2C0C7',
        600: '#A4A0AA',
        700: '#7F7B87',
        800: '#504B5A',
        900: '#282831',
        A100: '#FBFAFC',
        A200: '#F8F7FB',
        A400: '#F6F5F9',
        A700: '#D6DEE1',
    },
}

export const LIGHT_PALETTE: PaletteOptions = {
    ...COMMON_PALETTE,
    mode: 'light',
    text: {
        primary: '#504B5A',
        secondary: '#7F7B87',
        disabled: '#7F7B87',
    },
    background: {
        paper: '#F8F7FB',
        default: '#F6F5F9',
        contrast: '#FFFFFF',
    },
    action: {
        hover: '#F1F3F7',
        disabled: '#7F7B87',
        disabledBackground: '#DDDBE1',
    },
    divider: '#DDDBE1',
}

export const DARK_PALETTE: PaletteOptions = {
    ...COMMON_PALETTE,
    mode: 'dark',
    text: {
        primary: '#DDDBE1',
        secondary: '#9A96A3',
        disabled: '#9A96A3',
    },
    background: {
        paper: '#282831',
        default: '#1A1B22',
        contrast: '#010409',
    },
    action: {
        hover: '#1A1B22',
        disabled: '#7F7B87',
        disabledBackground: '#DDDBE1',
    },
    divider: '#504B5A',
}
