import { useCallback, useMemo } from 'react'
import { Breadcrumbs, Grid, Stack, SvgIcon } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import { DATE_PICKER_VIEW_TYPES } from '@octup/core/models'
import { useOctupDispatch } from 'hooks/useOctupDispatch'
import { useLocation } from 'react-router-dom'
import { ChevronRightSvg } from 'shared/assets/icons'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { OctupColors } from 'shared/theme/colors/OctupColors'
import { OctupBadge } from 'shared/ui/Badges/OctupBadge'
import { OctupDatePicker } from 'shared/ui/DatePicker/OctupDatePicker'
import { OctupTooltip } from 'shared/ui/MUIWrappers/OctupTooltip'
import { DateRangePickerContainer } from './DateRangePickerContainer'
import { Title } from './PageTitle.styles'
import { TitleBreadcrumb } from './TitleBreadcrumb'

const PageTitle = ({
    hasCustomTitle = false,
    customTitleStyled,
    showDatePicker = true,
    mainPanel,
    sidePanel,
    sidePanelTools,
    showSidePanel = true,
    infoTooltip,
    badgeConfig,
    customRoutes,
    datePickerConfig = { viewType: DATE_PICKER_VIEW_TYPES.DAILY },
}) => {
    const location = useLocation()
    const octupDispatch = useOctupDispatch()

    const pathnames = useMemo(() => {
        const routes = customRoutes || location.pathname.split('/')
        return routes
            .map((route) => ({ path: `/${route}`, title: octupDictionary.pages[route] }))
            .filter((pathname) => pathname.title)
    }, [customRoutes, location.pathname])

    const handleLinkClick = useCallback(() => {
        octupDispatch.sidebar.setActiveSubMenuItem()
    }, [octupDispatch.sidebar])

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            position="relative"
        >
            <Stack direction="row" alignItems="center" gap="1rem" flexWrap="wrap">
                {hasCustomTitle || customTitleStyled ? (
                    <Title>{customTitleStyled}</Title>
                ) : (
                    <Breadcrumbs
                        separator={<ChevronRightSvg customColor={OctupColors.primaryTextLight} />}
                    >
                        {pathnames.map(({ title, path }, index) => (
                            <TitleBreadcrumb
                                key={title}
                                path={path}
                                title={title}
                                isMainPage={pathnames.length === 1}
                                isLastItem={index === pathnames.length - 1}
                                onClick={handleLinkClick}
                            />
                        ))}
                    </Breadcrumbs>
                )}
                {infoTooltip && (
                    <OctupTooltip title={infoTooltip} position="top">
                        <SvgIcon component={InfoIcon} color="text.primary" />
                    </OctupTooltip>
                )}
                {badgeConfig && (
                    <OctupTooltip isContainerized title={badgeConfig.tooltip}>
                        <OctupBadge title={badgeConfig.title} theme={badgeConfig.theme} />
                    </OctupTooltip>
                )}
                {mainPanel}
            </Stack>
            {showSidePanel && (
                <Grid item container xs gap="2rem" alignItems="center" justifyContent="flex-end">
                    {sidePanel && <Grid item>{sidePanel}</Grid>}
                    {showDatePicker && (
                        <Grid item>
                            {datePickerConfig.viewType === DATE_PICKER_VIEW_TYPES.DAILY && (
                                <OctupDatePicker {...datePickerConfig} />
                            )}
                            {datePickerConfig.viewType === DATE_PICKER_VIEW_TYPES.MONTHLY && (
                                <DateRangePickerContainer {...datePickerConfig} />
                            )}
                        </Grid>
                    )}
                    {sidePanelTools && <Grid item>{sidePanelTools}</Grid>}
                </Grid>
            )}
        </Grid>
    )
}

export default PageTitle
