import type { ThemeType } from '@octup/core/theme'
import { ThemeProvider } from '@octup/core/theme'
import { Grid, Stack, Typography } from '@octup/core/ui-kit'
import { CompanyNameLogoContainer } from '@/new_containers/components/__common__'
import {
    containerStyle,
    contentContainerStyle,
    contentStyle,
    sideContentContainerStyle,
} from './styles'

export type AuthLayoutPropsType = {
    theme: ThemeType
    title?: string
    children: React.ReactNode
    sideContent?: React.ReactNode
}

export const AuthLayout = ({ theme, title, children, sideContent }: AuthLayoutPropsType) => (
    <ThemeProvider theme={theme}>
        <Grid container sx={containerStyle}>
            <Grid
                item
                xs
                container
                wrap="nowrap"
                direction="column"
                gap={10}
                sx={contentContainerStyle}
            >
                <Grid item container>
                    <CompanyNameLogoContainer />
                </Grid>
                <Grid item container>
                    <Grid item container sx={contentStyle}>
                        <Stack width="100%" spacing={5}>
                            {title && <Typography variant="h5">{title}</Typography>}
                            {children}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs container alignItems="flex-end" sx={sideContentContainerStyle}>
                <Grid item container sx={contentStyle}>
                    {sideContent}
                </Grid>
            </Grid>
        </Grid>
    </ThemeProvider>
)
