import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { GeneralFieldsSettingsType, GeneralSettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { editGeneralSettings } from '@/services/settings'

type EditGeneralSettingsStateType = {
    data?: GeneralSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: EditGeneralSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchEditGeneralSettings = createAsyncThunk<
    GeneralSettingsType,
    GeneralFieldsSettingsType,
    ThunkAPIConfigType
>('fetchEditClientsSettings', editGeneralSettings)

export const editGeneralSettingsSlice = createSlice({
    name: 'editGeneralSettings',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEditGeneralSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEditGeneralSettings.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchEditGeneralSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const editGeneralSettingsReducer = editGeneralSettingsSlice.reducer
