import { Navigate, useLocation } from 'react-router-dom'
import { MainLayout } from '@/layouts'
import { APP_ROUTES, DEFAULT_ROUTES } from '@/models/appConfig'
import { useAppSelector } from '@/state/hooks'

export const MainLayoutContainer = () => {
    const location = useLocation()
    const { userInfo } = useAppSelector((state) => state.auth)
    const { settings } = useAppSelector((state) => state.appConfig)
    const { data: sidebarPages, error } = useAppSelector((state) => state.pages.sidebarPages)
    const { palette_mode } = settings.data || {}
    const defaultRoute = sidebarPages[0]?.route

    if (!userInfo) {
        return <Navigate to={APP_ROUTES.LOGIN} replace />
    }

    if (defaultRoute && location.pathname === DEFAULT_ROUTES.MAIN) {
        return <Navigate to={defaultRoute} replace />
    }

    return <MainLayout error={error} paletteMode={palette_mode} />
}
