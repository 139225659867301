import { useCallback, useEffect, useState } from 'react'
import { useQueryString } from '@/hooks'
import type { OrderServiceType } from '@/models/orders'
import Dropdown from '@/shared/ui/Dropdown/Dropdown'
import { fetchServiceTypes } from '@/state/features/logistics'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { useLogisticsDatePicker } from '../hooks'

type ServiceTypeFilterOptionsType = OrderServiceType & {
    name: OrderServiceType['label']
    isSelected: boolean
}

export const ServiceTypeFilterContainer = () => {
    const dispatch = useAppDispatch()
    const { dateRange } = useLogisticsDatePicker()
    const { queryParams, setQueryParams } = useQueryString()
    const { data } = useAppSelector((state) => state.logistics.serviceTypes)
    const [options, setOptions] = useState<ServiceTypeFilterOptionsType[]>([])

    useEffect(() => {
        dispatch(fetchServiceTypes({ ...dateRange, ...queryParams }))
    }, [dispatch, dateRange, queryParams])

    useEffect(() => {
        if (data) {
            setOptions(
                data.map((serviceType) => ({
                    ...serviceType,
                    name: serviceType.label,
                    isSelected: !!queryParams.service_types?.includes(serviceType.value),
                }))
            )
        }
    }, [queryParams.service_types, data])

    const handleClose = useCallback(
        ({ value }: { value: OrderServiceType[] }) => {
            const selected = value.map(({ value }) => value)
            setQueryParams({ service_types: selected })
        },
        [setQueryParams]
    )

    return (
        <Dropdown
            title="Service Type"
            //  @ts-expect-error: Dropdown is a JavaScript file
            options={options}
            isFilter
            trimSpace
            showClearFilters={false}
            onClose={handleClose}
            updateSelectedOptions
        />
    )
}
