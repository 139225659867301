import { DEFAULT_COMPARISON_MODE } from 'constants/metrics'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { metricsService } from '../metrics/metricService'
import { getFinitePercentageValue } from '../../shared/utils/metrics-utils'
import { extractErrMsg } from '../../shared/utils/utils'

const initialState = {
    deepdives: {},
    deepDiveRawData: null,
    breakdownRawData: null,
    breakdownData: null,
    breakdownTableData: { headers: [], rows: [] },
    isLoading: false,
    isRejected: false,
    isLoadingGetBreakdownMetrics: false,
    isRejectedGetBreakdownMetrics: false,
    comparisonMode: DEFAULT_COMPARISON_MODE,
    comparisonMetric: null,
    isBreakdown: false,
    selectedMetric: null,
}

export const calculateChange = (index, rows, metricName) => {
    if (index > 0 && rows && rows[index] && metricName in rows[index]) {
        let change_absolute = rows[index][metricName] - rows[index - 1][metricName]
        let change_precentage = parseFloat(change_absolute / rows[index - 1][metricName])
        change_precentage = getFinitePercentageValue(change_precentage)
        return {
            change_precentage: isFinite(change_precentage)
                ? change_precentage
                : change_precentage > 0
                  ? 1
                  : 0,
            change_absolute: parseFloat(change_absolute),
        }
    }
}

export const getDeepdiveMetrics = createAsyncThunk('getDeepdiveMetrics', async (data, thunkAPI) => {
    try {
        return thunkAPI.fulfillWithValue(await metricsService.getMetricsBySegments(data))
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const getBreakdownMetrics = createAsyncThunk(
    'getBreakdownMetrics',
    async (data, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(await metricsService.getMetricsBySegments(data))
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const deepdivesSectionsSlice = createSlice({
    name: 'deepdivesSection',
    initialState,
    reducers: {
        clearData() {
            return initialState
        },
        setComparisonMode(state, action) {
            state.comparisonMode = action.payload
        },
        setComparisonMetric(state, action) {
            state.comparisonMetric = action.payload
        },
        setIsBreakdown(state, action) {
            state.isBreakdown = action.payload
        },
        setSelectedMetric(state, action) {
            state.selectedMetric = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeepdiveMetrics.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getDeepdiveMetrics.fulfilled, (state, action) => {
                state.deepDiveRawData = action.payload
                state.isLoading = false
            })

            .addCase(getDeepdiveMetrics.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getBreakdownMetrics.pending, (state) => {
                state.isLoadingGetBreakdownMetrics = true
                state.isRejectedGetBreakdownMetrics = false
            })
            .addCase(getBreakdownMetrics.fulfilled, (state, action) => {
                state.isLoadingGetBreakdownMetrics = false
                state.breakdownRawData = action.payload
            })
            .addCase(getBreakdownMetrics.rejected, (state) => {
                state.isLoadingGetBreakdownMetrics = false
                state.isRejectedGetBreakdownMetrics = true
            })
    },
})

export const {
    clearData,
    setComparisonMode,
    setComparisonMetric,
    setIsBreakdown,
    setSelectedMetric,
} = deepdivesSectionsSlice.actions

export const deepdivesSectionState = (state) => state.deepdivesSection
export const deepdivesSectionsSliceReducer = deepdivesSectionsSlice.reducer
