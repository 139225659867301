import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOctupDispatch } from '@/hooks'
import { DEFAULT_ROUTES } from '@/models/appConfig'
import { ExpiredModal } from '@/new_components/__common__'
import { useAppSelector } from '@/state/hooks'

export const ExpiredModalContainer = () => {
    const navigate = useNavigate()
    const octupDispatch = useOctupDispatch()
    const { isValid } = useAppSelector((state) => state.session)

    const handleLogout = useCallback(() => {
        octupDispatch.auth.logout()
        navigate(DEFAULT_ROUTES.MAIN)
    }, [navigate, octupDispatch.auth])

    if (isValid) return null

    return <ExpiredModal onLogout={handleLogout} />
}
