import { useCallback, useEffect, useState } from 'react'
import { useOctupDispatch } from 'hooks/useOctupDispatch'
import { SLADaysRange } from 'new_components/Metrics'
import { useSelector } from 'react-redux'

export function SLAConfig() {
    const octupDispatch = useOctupDispatch()
    const { slaRange } = useSelector((state) => state.sla)
    const { datePickerValues } = useSelector((state) => state.datePicker)
    const [anchorEl, setAnchorEl] = useState(null)
    const [slaRangeData, setSlaRangeData] = useState({
        min: slaRange?.fulfillment_min_sla,
        max: slaRange?.fulfillment_max_sla,
    })

    useEffect(() => {
        octupDispatch.operations.getFulfilledOrdersSLARange()
    }, [octupDispatch.operations])

    useEffect(() => {
        setSlaRangeData({
            min: slaRange?.fulfillment_min_sla,
            max: slaRange?.fulfillment_max_sla,
        })
        octupDispatch.operations.getFulfilledOrdersSLAMetric(
            datePickerValues.startDate,
            datePickerValues.endDate
        )
    }, [slaRange])

    const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), [])
    const handleClose = useCallback(() => setAnchorEl(null), [])

    const handleSave = useCallback(() => {
        octupDispatch.operations.setFulfilledOrdersSLARange(slaRangeData)
        handleClose()
    }, [handleClose, octupDispatch.operations, slaRangeData])

    return (
        <SLADaysRange
            data={slaRangeData}
            anchorEl={anchorEl}
            onClick={handleClick}
            onClose={handleClose}
            onSave={handleSave}
            onRangeChange={setSlaRangeData}
        />
    )
}
