import PageLayout from '@/components/PageLayout/PageLayout'
import { TABS } from './utils'

export const UserManagementPageContainer = () => (
    // @ts-expect-error: PageLayout is a JavaScript file
    <PageLayout
        tabs={TABS}
        hasFixedHeight
        showDatePicker={false}
        customTitleStyled="User Management"
    />
)
