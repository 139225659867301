import { useCallback, useEffect, useMemo } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UseFormReturn } from 'react-hook-form'
import { ERROR_MESSAGES } from '@/constants'
import { useToast } from '@/hooks'
import type { GeneralFieldsSettingsType } from '@/models/settings'
import { FormContent as DefaultFormContent } from '@/new_components/__common__/Form'
import { OrderStatusForm } from '@/new_components/Clients'
import { FormContainer } from '@/new_containers/components/__common__'
import {
    fetchEditGeneralSettings,
    fetchGeneralSettings,
    updateGeneralSettings,
} from '@/state/features/settings'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { convertToForm, VALIDATION_SCHEMA } from './utils'

type GeneralSettingsPayloadActionType = PayloadAction<
    GeneralFieldsSettingsType,
    string,
    { arg: GeneralFieldsSettingsType }
>

export type OrderStatusFormContainerPropsType = {
    FormContent?: typeof DefaultFormContent
}

export const OrderStatusFormContainer = ({
    FormContent = DefaultFormContent,
}: OrderStatusFormContainerPropsType) => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const { data, error, isLoading } = useAppSelector((state) => state.settings.general)
    const defaultValues = useMemo(() => convertToForm(data), [data])

    useEffect(() => {
        if (!data) {
            dispatch(fetchGeneralSettings())
        }
    }, [data, dispatch])

    const handleSubmit = useCallback(
        (data: GeneralFieldsSettingsType) => dispatch(fetchEditGeneralSettings(data)),
        [dispatch]
    )

    const handleSuccess = useCallback(
        (data: GeneralSettingsPayloadActionType, formContext: UseFormReturn) => {
            const updatedSettings = data.meta.arg
            formContext.reset(updatedSettings)
            dispatch(updateGeneralSettings(updatedSettings))
            toast.success(`Order status definitions have been updated successfully`)
        },
        [dispatch, toast]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <FormContainer
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
        >
            <FormContent isLoading={isLoading} error={error}>
                <OrderStatusForm />
            </FormContent>
        </FormContainer>
    )
}
