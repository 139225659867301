import ReactDOM from 'react-dom'
import { Outlet } from 'react-router-dom'
import { ErrorBoundaryContainer } from '@/new_containers/components/__common__'
import { BaseOctupToastsContainer } from '@/shared/ui/Toasts/BaseOctupToastsContainer'

const toastRoot = document.getElementById('octup-toast-root')

export const Root = () => (
    <ErrorBoundaryContainer>
        <Outlet />
        {toastRoot && ReactDOM.createPortal(<BaseOctupToastsContainer />, toastRoot)}
    </ErrorBoundaryContainer>
)
