import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { ERROR_MESSAGES } from '@/constants'
import type { ResetPasswordType } from '@/models/auth'

export const DEFAULT_VALUES: ResetPasswordType = {
    email: '',
}

export const VALIDATION_SCHEMA: ObjectSchema<Partial<ResetPasswordType>> = object({
    email: string().email(ERROR_MESSAGES.INVALID_EMAIL).required(ERROR_MESSAGES.REQUIRED),
})
