import { useCallback, useContext, useEffect, useState } from 'react'
import { ModalsContext } from 'contexts/ModalsContext'
import { clearData } from 'features/sections/deepdivesSectionsSlice'
import { MetricDeepDiveModal } from 'new_components/Metrics'
import { useSelector, useDispatch } from 'react-redux'
import { useMetricsGroup } from './hooks'

export const MetricDeepDiveModalContainer = () => {
    const dispatch = useDispatch()
    const { deepdiveModal, setDeepdiveModal } = useContext(ModalsContext)
    const metricsGroup = useMetricsGroup(deepdiveModal.segments?.[0], deepdiveModal.customMetrics)
    const { userInfo } = useSelector((state) => state.auth)
    const { datePicker } = useSelector((state) => state.newMetrics)
    const currentClientId = deepdiveModal.clientId || userInfo.client.id
    const [selectedMetricData, setSelectedMetricData] = useState(null)
    const { metric_id, deep_dive, blended_dive_in } = selectedMetricData?.metric || {}

    useEffect(() => {
        const { selectedDDMetric } = deepdiveModal
        const newData = metricsGroup.find(({ metric }) => metric?.metricTitle === selectedDDMetric)
        setSelectedMetricData(newData)
    }, [deepdiveModal, deepdiveModal.selectedDDMetric, metricsGroup])

    const onMetricSelection = useCallback(
        ({ value }) => {
            if (selectedMetricData?.metric.metricTitle !== value?.metric.metricTitle) {
                setSelectedMetricData(value)
            }
        },
        [selectedMetricData?.metric.metricTitle]
    )

    const handleClose = useCallback(() => {
        setDeepdiveModal({ show: false, segments: [], selectedDDMetric: null })
        dispatch(clearData())
    }, [dispatch, setDeepdiveModal])

    return (
        <MetricDeepDiveModal
            metricId={metric_id}
            deepDiveId={deep_dive}
            blendedDeepDiveId={blended_dive_in}
            datePicker={datePicker}
            clientId={currentClientId}
            metricsGroup={metricsGroup}
            onMetricSelection={onMetricSelection}
            onClose={handleClose}
        />
    )
}
