export const containerStyle = {
    width: '100%',
    position: 'relative',

    '& > .MuiLinearProgress-root': {
        width: '100%',
        position: 'absolute',
        zIndex: 1,
    },
}
