import { useCallback, useEffect } from 'react'
import { ErrorMessageFallback } from '@/new_components'
import { UsersTable } from '@/pages/userManagement'
import { fetchUsers } from '@/state/features/users'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

export const UsersTableContainer = () => {
    const dispatch = useAppDispatch()
    const { clientId } = useAppSelector((state) => state.auth.userInfo)
    const users = useAppSelector((state) => state.users[clientId])
    const { data, error, isLoading } = users || {}

    const handleLoadUsers = useCallback(
        () => dispatch(fetchUsers({ clientId })),
        [dispatch, clientId]
    )

    useEffect(() => {
        if (!users) {
            handleLoadUsers()
        }
    }, [handleLoadUsers, users])

    if (error) return <ErrorMessageFallback />

    return (
        <UsersTable
            data={data}
            clientId={clientId}
            isLoading={isLoading}
            onUpdateData={handleLoadUsers}
        />
    )
}
