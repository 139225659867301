import { useParams } from 'react-router-dom'
import { DashboardActions } from '@/new_components/Dashboards'
import { DashboardPage } from '@/new_pages'
import { useAppSelector } from '@/state/hooks'

export const DashboardPageContainer = () => {
    const { dashboardId } = useParams()
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const { datePickerValues } = useAppSelector((state) => state.datePicker)
    const dashboard = dashboardsById.data?.[dashboardId!]

    return (
        <DashboardPage
            dashboardId={dashboardId!}
            sidePanelTools={<DashboardActions />}
            hasCustomTitle
            dateRange={datePickerValues}
            customTitleStyled={dashboard?.data?.name}
            noDataText="Click 'Edit' at the top to enter edit mode and select metrics."
        />
    )
}
