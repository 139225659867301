import type { DataGridPropsType, GridColDefType, GridRowModelType } from '@octup/core/ui-kit'
import type { OrderTableType } from '@/models/orders'
import { getInputDate } from '@/shared/utils/dates-util'
import { getFormattedVal, VALUE_TYPE_INDEX } from '@/shared/utils/metrics-utils'

export const DEFAULT_VALUES = { page: 0, pageSize: 100 }
export const DEFAULT_PAGE_OPTIONS = [25, 50, 100]

export const getTableConfig = (config?: OrderTableType): DataGridPropsType => {
    const { data: rows = [], metadata } = config || {}
    const { total_rows = 0 } = metadata || {}
    const getRowId = (row: GridRowModelType) => row.order_number

    const columns: GridColDefType<typeof rows>[] = [
        {
            field: 'order_number',
            headerName: 'Order Number',
            disableColumnMenu: true,
            cellClassName: 'clickable',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            disableColumnMenu: true,
            flex: 1,
            valueGetter: getInputDate,
        },
        {
            field: 'full_name',
            headerName: 'Full Name',
            disableColumnMenu: true,
            flex: 1,
        },
        {
            field: 'destination',
            headerName: 'Destination',
            disableColumnMenu: true,
            flex: 1,
        },
        {
            field: 'value',
            headerName: 'Value',
            disableColumnMenu: true,
            flex: 1,
            valueGetter: (value) =>
                //  @ts-expect-error: getFormattedVal is a JavaScript file
                getFormattedVal({
                    val: value,
                    valueType: VALUE_TYPE_INDEX.NUMBER,
                    isSupported: true,
                    showSymbol: true,
                    isShopCurrency: true,
                }),
        },
    ]

    const initialState = {
        pagination: {
            paginationModel: {
                page: DEFAULT_VALUES.page,
                pageSize: DEFAULT_VALUES.pageSize,
            },
        },
    }

    return {
        rows,
        columns,
        getRowId,
        density: 'compact',
        paginationMode: 'server',
        sortingMode: 'server',
        rowCount: total_rows,
        initialState,
        pageSizeOptions: DEFAULT_PAGE_OPTIONS,
    }
}
