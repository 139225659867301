import { useEffect, useState, useCallback } from 'react'
import { useUpdateEffect } from '@octup/core/hooks'
import { useSelector } from 'react-redux'
import { ActionableItemsTableContainer, TableWrapper } from '../InsightsPage.styles'
import { renderInsightsRows } from './InsightItem'
import { DismissInsightModal, InsightDetailsModal } from '../../../components/Modals/InsightsModals'
import { EditObjectiveModal } from '../../../components/Modals/ObjectivesModals/ObjectivesModal'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { CardExpendable } from '../../../shared/ui/Card/CardExpendable'
import { OctupBaseTable } from '../../../shared/ui/Tables/OctupBaseTable'
import { HOME_TABLES_TYPES } from '../../../shared/utils/strings-util'

const insightsTableData = {
    headersData: ['Insight', 'Status', 'Objectives', 'Severity', 'Segment', 'Owner', 'Date', ''],
}

const isRowDisabled = (row) => row.status === 'dismissed'

export function InsightsDetailedTable() {
    const octupDispatch = useOctupDispatch()
    const { objective, isObjectivesUpdated } = useSelector((state) => state.objectives)
    const [showInsightDetailsModal, setShowInsightDetailsModal] = useState({
        show: false,
        isEditState: false,
    })
    const [showEditObjectiveModal, setShowEditObjectiveModal] = useState({
        show: false,
        isEditState: false,
    })

    const [selectedRowData, setSelectedRowData] = useState(null)

    const { userInfo } = useSelector((state) => state.auth)
    const { insights } = useSelector((state) => state.insights)

    const [showDismissInsightModal, setShowDismissInsightModal] = useState({
        show: false,
        isEditState: false,
    })

    useEffect(() => {
        octupDispatch.users.getUsers()
    }, [octupDispatch.users])

    useEffect(() => {
        octupDispatch.insights.getInsights()
        octupDispatch.objectives.getObjectives()
    }, [octupDispatch.insights, octupDispatch.objectives])

    useUpdateEffect(() => {
        if (isObjectivesUpdated) {
            octupDispatch.insights.getInsights()
        }
    }, [isObjectivesUpdated])

    const handleSeeObjectiveBtnClick = (e, objective) => {
        e.preventDefault()
        e.stopPropagation()
        octupDispatch.objectives.setSelectedObjective(objective.id)
        setShowEditObjectiveModal({ show: true, isEditState: false })
    }
    const dismissInsightDetailsModal = () => {
        setSelectedRowData(null)
        setShowInsightDetailsModal({ show: false, isEditState: false })
    }
    const dismissDismissInsightModal = () => {
        setShowDismissInsightModal({ show: false, isEditState: false })
    }

    const dismissEditObjectiveModal = () => {
        octupDispatch.objectives.setSelectedObjective(null)
        setShowEditObjectiveModal({ show: false, isEditState: false })
    }

    const handleTableRowClick = useCallback(
        (row) => {
            if (isRowDisabled(row)) return

            if (row.status === 'new') {
                octupDispatch.insights.updateInsightStatus(row.id, 2)
            }

            setShowInsightDetailsModal((prevState) => ({ ...prevState, show: true }))
        },
        [octupDispatch.insights]
    )

    return (
        <>
            <ActionableItemsTableContainer className={'first'}>
                <CardExpendable>
                    <TableWrapper>
                        <OctupBaseTable
                            showDownloadCSV={true}
                            showDatePicker={false}
                            tableName={'Insights'}
                            isRowClickable={true}
                            customHeight={'calc(60vh - 2rem)'}
                            showSelectedRow={false}
                            tableData={{
                                headers: insightsTableData.headersData,
                                rows: insights,
                            }}
                            isRowDisabled={isRowDisabled}
                            renderRows={renderInsightsRows}
                            rowMenuActions={{
                                setShowEditObjectiveModal,
                                setShowDismissInsightModal,
                                setSelectedRowData,
                                handleSeeObjectiveBtnClick,
                            }}
                            defaultSortBy={'date'}
                            sortingHeadersToDataMapping={{
                                date: 'created_at',
                                insight: 'title',
                                severity: 'importance',
                                segment: 'segment',
                                owner: ['owner', 'fullName'],
                                status: 'vstatus',
                                type: 'origin',
                                objectives: ['objective_id', 'length'],
                            }}
                            requestedFilters={[
                                {
                                    title: 'status',
                                    dataPropsMapping: ['status'],
                                    dropdownConfig: { isMultiSelection: true },
                                },
                                {
                                    title: 'severity',
                                    dataPropsMapping: ['importance'],
                                    dropdownConfig: { isMultiSelection: true },
                                },
                                {
                                    title: 'segment',
                                    dataPropsMapping: ['segment'],
                                    dropdownConfig: { isMultiSelection: true },
                                },
                                {
                                    title: 'owner',
                                    dataPropsMapping: ['owner', 'fullName'],
                                    dropdownConfig: { isMultiSelection: true },
                                },
                                {
                                    title: 'type',
                                    dataPropsMapping: ['type'],
                                    dropdownConfig: { isMultiSelection: true },
                                },
                            ]}
                            setSelectedRowData={setSelectedRowData}
                            selectedRowData={selectedRowData}
                            tableRowClickHandler={handleTableRowClick}
                            showSearchFilter={true}
                            searchFilterPlaceholder={'Search...'}
                            searchFilterFields={{
                                importance: 'importance',
                                status: 'status',
                                segment: 'segment',
                                insight: 'title',
                                subtitle: 'subtitle',
                                owner: ['owner', 'fullname'],
                                type: 'type',
                            }}
                        />
                    </TableWrapper>
                </CardExpendable>
            </ActionableItemsTableContainer>
            {showInsightDetailsModal.show && (
                <InsightDetailsModal
                    dismissModalhandler={dismissInsightDetailsModal}
                    selectedItem={selectedRowData}
                    userInfo={userInfo}
                    // setShowSnoozeInsightModal={setShowSnoozeInsightModal}
                    setShowDismissInsightModal={setShowDismissInsightModal}
                    // setShowDislikeInsightModal={setShowDislikeInsightModal}
                    // setShowShareInsightModal={setShowShareInsightModal}
                    setShowInsightDetailsModal={setShowInsightDetailsModal}
                    setShowEditObjectiveModal={setShowEditObjectiveModal}
                    // setShowDismissObjectiveModal={setShowDismissObjectiveModal}
                />
            )}
            {showDismissInsightModal.show && (
                <DismissInsightModal
                    dismissModalhandler={dismissDismissInsightModal}
                    showOnHoldModal={{
                        onCancel: true,
                        onSubmit: false,
                        actionFn: () => {
                            setShowInsightDetailsModal((prevState) => {
                                return { ...prevState, show: true }
                            })
                        },
                    }}
                />
            )}
            {showEditObjectiveModal.show && objective && (
                <EditObjectiveModal
                    dismissModalhandler={dismissEditObjectiveModal}
                    isEditState={showEditObjectiveModal.isEditState}
                    data={objective}
                    activeTable={HOME_TABLES_TYPES.OBJECTIVES}
                />
            )}
            {showEditObjectiveModal.show && !objective && selectedRowData && (
                <EditObjectiveModal
                    dismissModalhandler={dismissEditObjectiveModal}
                    isEditState={showEditObjectiveModal.isEditState}
                    data={selectedRowData}
                    activeTable={HOME_TABLES_TYPES.INSIGHTS}
                />
            )}
        </>
    )
}
