import axios from 'axios'
import { useOctupDispatch } from '@/hooks/useOctupDispatch'
import { HTTP_STATUS_CODES } from '@/models/network'

// TODO: Temporary solution, create an instance of axios and replace the global one
export const useAxiosInterceptors = () => {
    const octupDispatch = useOctupDispatch()

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            switch (error.response.status) {
                case HTTP_STATUS_CODES.PAYMENT_REQUIRED:
                    octupDispatch.auth.paymentRequired()
                    break
                case HTTP_STATUS_CODES.EXPIRED_SESSION:
                    octupDispatch.auth.invalidateSession()
                    break
            }

            return Promise.reject(error)
        }
    )
}
