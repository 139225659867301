import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PageSettingsType, PageType } from '@/models/pages'
import { getPageSettingsById } from '@/services/pages'
import type { ThunkAPIConfigType } from '@/services/utils'

type PageSettingsStateType = {
    data?: PageSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE = {} as Record<PageType['id'], PageSettingsStateType>

export const fetchPageSettingsById = createAsyncThunk<
    PageSettingsType,
    PageType['id'],
    ThunkAPIConfigType
>('fetchPageSettingsById', getPageSettingsById)

export const pageSettingsByIdSlice = createSlice({
    name: 'pageSettingsById',
    initialState: INITIAL_STATE,
    reducers: {
        cleanPageSettings: () => INITIAL_STATE,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageSettingsById.pending, (state, action) => {
                const id = action.meta.arg
                const newState = { ...state?.[id], isLoading: true, error: undefined }
                return { ...state, [id]: newState }
            })
            .addCase(fetchPageSettingsById.fulfilled, (state, action) => {
                const id = action.meta.arg
                const newState = { data: action.payload, isLoading: false, error: undefined }
                return { ...state, [id]: newState }
            })
            .addCase(fetchPageSettingsById.rejected, (state, action) => {
                const id = action.meta.arg
                const error = action.error.message
                const newState = { ...state?.[id], isLoading: false, error }
                return { ...state, [id]: newState }
            })
    },
})

export const { cleanPageSettings } = pageSettingsByIdSlice.actions
export const pageSettingsByIdReducer = pageSettingsByIdSlice.reducer
