import type { DialogPropsType } from '@octup/core/ui-kit'
import { Dialog, DialogContent } from '@octup/core/ui-kit'
import type { MetricsDashboardProviderPropsType } from '@/new_containers/components/__common__'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'
import { dialogStyle } from './styles'

export type DashboardDialogPropsType = DialogPropsType & MetricsDashboardProviderPropsType

export const DashboardDialog = ({
    dashboardId,
    clientId,
    dateRange,
    sectionConfigByType,
    ...props
}: DashboardDialogPropsType) => (
    <Dialog fullWidth maxWidth="md" sx={dialogStyle} {...props}>
        <DialogContent>
            <MetricsDashboardProvider
                dashboardId={dashboardId}
                clientId={clientId}
                dateRange={dateRange}
                sectionConfigByType={sectionConfigByType}
            />
        </DialogContent>
    </Dialog>
)
