import type { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'

export type ThunkAPIConfigType = { state: { auth: { userInfo: { token: string } } } }
export type ThunkAPIType = BaseThunkAPI<ThunkAPIConfigType['state'], unknown>

type APIConfigType<T> = {
    thunkAPI?: ThunkAPIType
    contentType?: string
    params?: T
}

export const generateAPIConfig = <T = unknown>({
    thunkAPI,
    contentType,
    params,
}: APIConfigType<T> = {}) => {
    const token = thunkAPI
        ? thunkAPI.getState().auth.userInfo.token
        : JSON.parse(localStorage.getItem('userInfo') || '')?.token

    return {
        params,
        headers: {
            Authorization: token ? token : null,
            'Content-Type': contentType ? contentType : 'application/json',
        },
    }
}
