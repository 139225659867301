import { useMemo, useContext, useCallback } from 'react'
import { Box, OverflowTypography, Stack } from '@octup/core/ui-kit'
import { useSelector, useDispatch } from 'react-redux'
import { COMPARISON_MODES } from '@/constants/metrics'
import { ModalsContext } from '@/contexts/ModalsContext'
import { setIsBreakdown } from '@/features/sections/deepdivesSectionsSlice'
import { MetricsDropdownContainer } from '@/new_containers/components/Metrics'
import { RadioTabsSelectors } from '@/shared/ui/RadioTabsSelector/RadioTabsSelectors'

export const MetricsDetailsMetricSelection = ({ metricsGroup = [], onMetricSelection }) => {
    const dispatch = useDispatch()
    const { deepdiveModal } = useContext(ModalsContext)
    const deepdiveSection = useSelector((state) => state.deepdivesSection)
    const { comparisonMode, isBreakdown, selectedMetric, breakdownRawData } = deepdiveSection
    const showBreakdownTabs = !!selectedMetric?.blended_dive_in && !!breakdownRawData

    const isMetricComparisonMode = useMemo(
        () => comparisonMode === COMPARISON_MODES.METRIC,
        [comparisonMode]
    )

    const handleBreakdownSelection = useCallback(
        (isBreakdown) => dispatch(setIsBreakdown(isBreakdown)),
        [dispatch]
    )

    return (
        <Stack direction="row" alignItems="center" spacing={4}>
            <Box>
                {metricsGroup.length ? (
                    <MetricsDropdownContainer
                        metricsGroup={metricsGroup}
                        onSelect={onMetricSelection}
                        defaultValue={deepdiveModal.selectedDDMetric}
                    />
                ) : (
                    <OverflowTypography>{selectedMetric?.metricTitle}</OverflowTypography>
                )}
            </Box>
            {showBreakdownTabs && (
                <RadioTabsSelectors
                    tabsCustomStyle={{ width: '14rem' }}
                    tabs={[
                        {
                            id: 1,
                            title: 'Breakdown',
                            selected: isBreakdown,
                            disabled: isMetricComparisonMode,
                            onClick: () => {
                                handleBreakdownSelection(true)
                            },
                            ...(isMetricComparisonMode && {
                                tooltip: 'When comparing metrics, only blended data is available',
                            }),
                        },
                        {
                            id: 2,
                            title: 'Blended',
                            selected: !isBreakdown,
                            onClick: () => {
                                handleBreakdownSelection(false)
                            },
                        },
                    ]}
                />
            )}
        </Stack>
    )
}
