import type { TypographyOptions } from '@mui/material/styles/createTypography'
import { TYPOGRAPHY as DEFAULT_TYPOGRAPHY } from '@octup/core/theme'

const BOLD_FONT_WEIGHT = 600

export const TYPOGRAPHY: TypographyOptions = {
    ...DEFAULT_TYPOGRAPHY,
    htmlFontSize: 8,
    h1: {
        fontSize: '4rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    h2: {
        fontSize: '3rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    h3: {
        fontSize: '2.4rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    h4: {
        fontSize: '2.2rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    h5: {
        fontSize: '2rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    h6: {
        fontSize: '1.8rem',
        fontWeight: BOLD_FONT_WEIGHT,
    },
    body1: {
        fontSize: '1.7rem',
    },
    body2: {
        fontSize: '1.5rem',
    },
    subtitle1: {
        fontSize: '1.4rem',
    },
    subtitle2: {
        fontSize: '1.3rem',
    },
    caption: {
        fontSize: '1.25rem',
    },
    overline: {
        fontSize: '1.3rem',
    },
    button: {
        fontSize: '1.7rem',
        fontWeight: BOLD_FONT_WEIGHT,
        textTransform: 'capitalize',
        textWrap: 'nowrap',
    },
}
