import styled from 'styled-components'

export function OctupBackdrop({ onDismissModal, customStyle, isCloseable, customBackdropOpacity }) {
    return (
        <BackdropStyled
            customBackdropOpacity={customBackdropOpacity}
            onClick={(e) => {
                isCloseable && onDismissModal?.(e)
            }}
            style={customStyle}
        />
    )
}

const BackdropStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: #504b5a;
    //Backdrop must not be zero so it will fallback to 0.72 in case of zero, undefined or null
    opacity: ${(props) => props.customBackdropOpacity || 0.72};
    height: 100vh;
    width: 100%;
    z-index: 1400;
`
