import type {
    ClientType,
    CustomTableMetadataMetricsSectionType,
    DateRangeType,
    METRICS_SECTION_RESPONSE_DATA_TYPE,
    MetricsSectionDataType,
    MetricsSectionType,
} from '@octup/core/models'
import type { AxiosResponse } from 'axios'
import { GLOBAL_CONFIG } from '@/config'
import type { AnyPartOfType } from '@/models/__common__'
import type { ThunkAPIType } from '../utils'

export const SECTIONS_API_URL = GLOBAL_CONFIG.API_URL + '/sections'

export type MetricsSectionByIdRequestType = DateRangeType &
    Pick<MetricsSectionType, 'id' | 'type'> & {
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE
        clientId: ClientType['id']
    }

export type CustomTableMetricsSectionDataParamsType = Pick<
    MetricsSectionByIdRequestType,
    'id' | 'clientId' | 'startDate' | 'endDate'
> & {
    page?: CustomTableMetadataMetricsSectionType['page_number']
    pageSize?: CustomTableMetadataMetricsSectionType['page_size']
    sortBy?: CustomTableMetadataMetricsSectionType['sort_by']
    sortDescending?: CustomTableMetadataMetricsSectionType['sort_descending']
    search?: CustomTableMetadataMetricsSectionType['search']
}

export type MetricsSectionUpdateType<T = CustomTableMetricsSectionDataParamsType> = {
    fetch: (
        data: T,
        thunkAPI: ThunkAPIType
    ) => Promise<AxiosResponse<AnyPartOfType<MetricsSectionDataType>>['data']>
    params: T
    path: string
}

export type GetMetricsSectionType = (
    data: MetricsSectionByIdRequestType,
    thunkAPI: ThunkAPIType
) => Promise<Pick<AxiosResponse<MetricsSectionDataType>, 'data'> & { hasContent: boolean }>
