import { useParams } from 'react-router-dom'
import PageLayout from '@/components/PageLayout/PageLayout'
import { OrderModalTitle } from '@/pages/operations/single-order/OrderModalTitle'
import { SingleOrderDeepDive } from '@/pages/operations/single-order/SingleOrderDeepDive'

export const OrderPageContainer = () => {
    const { orderId } = useParams()

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <PageLayout showDatePicker={false} customTitleStyled={<OrderModalTitle />}>
            <SingleOrderDeepDive orderId={orderId} />
        </PageLayout>
    )
}
