import type {
    ClientType,
    DateRangeType,
    MetricsSectionByClientIdType,
    MetricsSectionStateType,
} from '@octup/core/models'
import { METRICS_SECTION_TYPE } from '@octup/core/models'
import type {
    GetMetricsSectionType,
    MetricsSectionByIdRequestType,
} from '@/services/metricsSections'
import { getCustomTableMetricsSectionById, getMetricsSectionById } from '@/services/metricsSections'

export const FETCHER_BY_SECTION_TYPE: Partial<Record<METRICS_SECTION_TYPE, GetMetricsSectionType>> &
    Record<'default', GetMetricsSectionType> = {
    [METRICS_SECTION_TYPE.CUSTOM_TABLE]: getCustomTableMetricsSectionById,
    default: getMetricsSectionById,
}

type GetUpdatedStateDataParamsType = {
    stateData?: MetricsSectionByClientIdType
    params: DateRangeType & {
        id: MetricsSectionByIdRequestType['id']
        clientId: ClientType['id']
    }
    getNewData: (data: MetricsSectionStateType) => MetricsSectionStateType
}

export const getUpdatedStateData = ({
    stateData,
    params,
    getNewData,
}: GetUpdatedStateDataParamsType) => {
    const { id, clientId, startDate, endDate } = params
    const clientData = stateData?.[clientId] || {}
    const sectionData = clientData?.[id] || {}
    const startDateData = sectionData?.[startDate] || {}
    const endDateData = startDateData?.[endDate] || {}

    return {
        ...stateData,
        [clientId]: {
            ...clientData,
            [id]: {
                ...sectionData,
                [startDate]: {
                    ...startDateData,
                    [endDate]: {
                        ...endDateData,
                        ...getNewData(endDateData),
                        startDate,
                        endDate,
                        clientId,
                    },
                },
            },
        },
    }
}
