import type { DateRangeType } from '@octup/core/models'

export type LogisticsFiltersType = DateRangeType & {
    countries?: string[]
    warehouses_names?: string[]
    service_types?: string[]
}

export const getLogisticsParams = (data: LogisticsFiltersType) => ({
    start_date: data.startDate,
    end_date: data.endDate,
    countries: data.countries?.join(','),
    warehouses_names: data.warehouses_names?.join(','),
    service_types: data.service_types?.join(','),
})
