const getMaxErrorMessage = ({ max }: { max: number }) => `Must be less or equal to ${max}`

export const ERROR_MESSAGES = {
    INVALID: 'Invalid value',
    REQUIRED: 'This is Required',
    POSITIVE: 'Positive only',
    MAX_LENGTH: getMaxErrorMessage,
    INVALID_EMAIL: 'Invalid email',
    PASSWORD: `Minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character`,
    PASSWORD_MATCH: 'Passwords must match',
    TERMS_REQUIRED: 'In order to continue you first need to agree to this',
    SERVER_ERROR: 'Something went wrong. Please try again.',
}
