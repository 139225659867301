import { Grid, Stack } from '@mui/material'
import { InvoicesDatePicker } from '@/new_components/__common__'
import { ProductDetailsPanel, ProductsPanel } from '@/new_components/UnitEconomics'
import { CostModalContainer } from '@/new_containers/components/__common__'
import { CostManagerButtonContainer } from '@/new_containers/components/UnitEconomics'
import { containerStyle } from './UnitEconomicsPage.styles'
import PageLayout from '../../components/PageLayout/PageLayout'

export const UnitEconomicsPage = ({
    error,
    showCostModal,
    onToggleCostModal,
    selectedProduct,
    onUpdateData,
    invoicesDates,
}) => (
    <PageLayout
        error={error}
        showDatePicker={false}
        hasFixedHeight
        sidePanel={
            <Stack direction="row" alignItems="center" spacing={3}>
                <CostManagerButtonContainer onToggleCostModal={onToggleCostModal} />
                <InvoicesDatePicker
                    availableMonths={invoicesDates.data}
                    isLoading={invoicesDates.isLoading}
                />
            </Stack>
        }
        infoTooltip="Dive into product profitability, taking into account selling price and other associated costs at the item level"
    >
        <Grid container columnSpacing={5} wrap="nowrap" sx={containerStyle}>
            <Grid xs={6} md={6.5} lg={7.5} xl={8} item container flexDirection="column">
                <ProductsPanel />
            </Grid>
            <Grid xs={6} md={5.5} lg={4.5} xl={4} item container>
                <ProductDetailsPanel selectedProduct={selectedProduct} />
            </Grid>
        </Grid>
        <CostModalContainer
            selectedEl={selectedProduct && { products: [selectedProduct] }}
            showCostModal={showCostModal}
            onClose={onToggleCostModal}
            isEditState={showCostModal.isEditState}
            onSuccess={onUpdateData}
        />
    </PageLayout>
)
