import styled from 'styled-components'
import { Row } from '../../../shared/styledComponents'
import { OctupColors } from '../../../shared/theme/colors/OctupColors'

const SingleOrderMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    width: 100%;
    height: 100%;
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 5rem;
    flex: 1;
    padding: 1rem;
    position: relative;
    background-color: ${OctupColors.pageBackground};
    width: 100%;
    height: 100%;
    border: 1px solid rgba(241, 240, 245, 1);
    border-radius: 1.6rem;
    margin-top: 2rem;
`

const BottomContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    column-gap: 5rem;
`

const ItemsContainer = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
`

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    font-size: 1.5rem;
    color: ${OctupColors.primaryTextDark};
    border-bottom: ${(p) => (p.showSeperator ? `1px solid ${OctupColors.tableRowExpanded}` : ``)};
`

const JourneyItemsContainer = styled(ItemsContainer)`
    display: flex;
    flex-direction: column;
    flex: 1.2;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 16px;
`
const JourneyItemContainer = styled(ItemContainer)`
    display: flex;
    align-items: center;

    a {
        color: #007f82;
    }
`

const JourneyDateContainer = styled.div`
    height: 100%;
    white-space: nowrap;
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    color: rgba(164, 160, 170, 1);
    font-weight: 600;
    text-transform: uppercase;
`

const PaymentText = styled.div`
    line-height: 2rem;
    font-size: 1.5rem;
`

const ItemImageAndName = styled(PaymentText)`
    display: flex;
    width: 98%;
    padding: 2rem 0;
    align-items: center;
    gap: 0.8rem;
    white-space: pre-wrap;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : '')};
`

const PaymentsContainer = styled.div`
    width: 100%;
    height: 100%;
    color: ${OctupColors.primaryTextDark};
    padding: 0 2rem;
    flex: 1;
`

const ItemImage = styled.img`
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 0px rgba(80, 75, 90, 0.16);
    background: rgba(255, 255, 255, 1);
`

const TitleDiv = styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: center;
    font-size: 1.7rem;
    line-height: 2.3rem;
    padding: 1rem 0;
    color: ${OctupColors.primaryTextDark};
`

const HeaderContainer = styled(Row)`
    height: fit-content;
`

const HeaderIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 100%;
`

const StyledPrice = styled.div`
    position: relative;
    font-size: 1.5rem;
    line-height: 2rem;
    white-space: nowrap;
    padding: 0 1rem;
`

const TextDiv = styled.div`
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    bottom: 0;
    line-height: 5rem;
`

const JourneyElements = styled.div`
    width: 100%;
    font-size: 1.3rem;
`

const JourneyImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(35%);
    height: 100%;
    gap: 1rem;
    width: 4rem;
    flex: 1;
`

const JourneyLine = styled.div`
    border-left: 2px solid ${OctupColors.seperator};
    height: 2rem;
`

const JourneyItemText = styled.div`
    font-size: 1.5rem;
    overflow-wrap: break-word;
`

const OrderIdContainer = styled.div`
    font-size: 1.7rem;
    line-height: 2.3rem;
`

const DateContainer = styled.div`
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: #7f7b87;
`

const PaymentTotalContainer = styled.div`
    border-radius: 8px;
    width: 100%;
    color: #504b5a;
    background: rgba(241, 240, 245, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const PaymentTotalText = styled.div`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 1.5rem 0.8rem;
`

const GrayCircle = styled.div`
    background-color: ${OctupColors.seperator};
    border-radius: 8px;
    min-width: 8px;
    min-height: 8px;
    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
`

const SubGroupDate = styled.div`
    height: 20px;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 25px;
    color: #a4a0aa;
    background: white;
    padding: 2rem 0;
    display: flex;
    align-items: center;
`

const HorizontalDivider = styled.div`
    border-bottom: 0.1px solid rgba(221, 219, 225, 1);
    width: 98%;
    height: 0;
    align-self: center;
    justify-self: center;
`

const VerticalDivider = styled.div`
    border-right: 0.1px solid rgba(235, 234, 238, 1);
    height: 98%;
    align-self: center;
    justify-self: center;
`

const HeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export {
    SingleOrderMainContainer,
    TopContainer,
    BottomContainer,
    ItemsContainer,
    ItemContainer,
    JourneyItemsContainer,
    JourneyItemContainer,
    JourneyDateContainer,
    PaymentText,
    ItemImageAndName,
    PaymentsContainer,
    ItemImage,
    TitleDiv,
    HeaderContainer,
    HeaderIcons,
    StyledPrice,
    TextDiv,
    JourneyElements,
    JourneyImage,
    JourneyLine,
    JourneyItemText,
    OrderIdContainer,
    DateContainer,
    PaymentTotalContainer,
    PaymentTotalText,
    GrayCircle,
    SubGroupDate,
    HorizontalDivider,
    VerticalDivider,
    HeaderStyled,
}
