import { useEffect } from 'react'
import { useQueryString } from '@/hooks'
import { useLogisticsDatePicker } from '@/new_containers/components/Logistics/hooks'
import { LogisticsPage } from '@/new_pages'
import { fetchLogistics } from '@/state/features/logistics'
import { useAppSelector, useAppDispatch } from '@/state/hooks'

export const LogisticsPageContainer = () => {
    const dispatch = useAppDispatch()
    const { queryParams } = useQueryString()
    const { dateRange, viewType } = useLogisticsDatePicker()
    const { userInfo } = useAppSelector((state) => state.auth)
    const logistics = useAppSelector((state) => state.logistics)
    const { sections, warehouses, warehouseCountries, serviceTypes } = logistics
    const dataState = Object.values([sections, warehouses, warehouseCountries, serviceTypes])
    const isLoading = dataState.some((data) => data.isLoading)
    const { error } = dataState.find((data) => data.error) || {}
    const hasWarehouses = !!userInfo?.client?.hasInvoices

    useEffect(() => {
        dispatch(fetchLogistics({ ...dateRange, ...queryParams }))
    }, [dispatch, dateRange, queryParams])

    return (
        <LogisticsPage
            error={error}
            isLoading={isLoading}
            hasLogistics={!!sections.data}
            hasWarehouses={hasWarehouses}
            warehouses={warehouses.data}
            datePickerViewType={viewType}
        />
    )
}
