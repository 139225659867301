import { useMemo } from 'react'
import { octupDictionary } from '@/shared/dictionary/dictionary'
import Dropdown from '@/shared/ui/Dropdown/Dropdown'

export const MetricsDropdownContainer = ({
    metricsGroup,
    defaultValue,
    onSelect,
    getGroupsFilter,
    ...props
}) => {
    const options = useMemo(
        () =>
            metricsGroup
                .filter((group) =>
                    group.metric.supported && getGroupsFilter ? getGroupsFilter(group) : true
                )
                .map((el, index) => ({
                    ...el,
                    name:
                        octupDictionary.titles.metricsTitles[el?.metric?.metricTitle] ||
                        el?.metric?.metricTitle,
                    isSelected: defaultValue
                        ? el?.metric?.metricTitle === defaultValue
                        : index === 0,
                })),
        [metricsGroup, getGroupsFilter, defaultValue]
    )

    return (
        <Dropdown
            options={options}
            showClearFilters={false}
            isMultiSelection={false}
            showCounter={false}
            onClickCallback={onSelect}
            {...props}
        />
    )
}
