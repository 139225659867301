import { useMemo } from 'react'
import { SearchTextFieldContainer } from '@octup/core/components'
import { Stack, Typography } from '@octup/core/ui-kit'
import type { MetricsSectionsCategoryType } from 'models/metrics'
import pluralize from 'pluralize'
import type { SelectedDashboardSection } from 'state/features/dashboards'
import { CategoriesList } from './CategoriesList'
import { containerStyle } from './MetricsSectionsListPanel.styles'

type MetricsSectionsListPanelPropsType = {
    options: MetricsSectionsCategoryType[]
    selectedDashboardSection: SelectedDashboardSection
    searchValue?: string
    onSearch: (value: string) => void
    searchInputRef: React.Ref<HTMLInputElement>
}

export const MetricsSectionsListPanel = ({
    options,
    selectedDashboardSection,
    searchValue,
    onSearch,
    searchInputRef,
}: MetricsSectionsListPanelPropsType) => {
    const groupPluralName = useMemo(
        () => pluralize(selectedDashboardSection.group),
        [selectedDashboardSection.group]
    )

    return (
        <Stack sx={containerStyle} spacing={2}>
            <Typography fontWeight={600}>{`Select from ${groupPluralName} library:`}</Typography>
            <SearchTextFieldContainer
                inputRef={searchInputRef}
                value={searchValue}
                onChange={onSearch}
                placeholder={`Search ${groupPluralName}`}
            />
            <CategoriesList options={options} />
        </Stack>
    )
}
