import { useSelector } from 'react-redux'
import {
    DateContainer,
    HeaderContainer,
    HeaderIcons,
    HeaderStyled,
    OrderIdContainer,
} from './SingleOrderDetails.styles'
import { FullfilledSvg } from '../../../shared/assets/icons/FullfilledSvg'
import { PaidSvg } from '../../../shared/assets/icons/PaidSvg'
import { Row } from '../../../shared/styledComponents'
import { getInputDate } from '../../../shared/utils/dates-util'

export function OrderModalTitle({ selectedItem }) {
    const { singleOrder } = useSelector((state) => state.metrics)
    const orderData = singleOrder?.data?.order

    return (
        <HeaderContainer className={'order-header'}>
            <HeaderStyled
                style={{
                    display: 'flex',
                    gap: '1rem',
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        height: '100%',
                    }}
                >
                    <OrderIdContainer>
                        Order {selectedItem?.order_number || selectedItem?.order_name}
                    </OrderIdContainer>
                    <HeaderIcons>
                        {orderData?.displayFinancialStatus === 'PAID' && <PaidSvg />}
                        {orderData?.displayFulfillmentStatus === 'FULFILLED' && <FullfilledSvg />}
                    </HeaderIcons>
                </Row>
                <DateContainer>{getInputDate(selectedItem?.date, '4')}</DateContainer>
            </HeaderStyled>
        </HeaderContainer>
    )
}
