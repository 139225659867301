import type { MetricsSectionPropsType } from '@octup/core/models'
import { LineChartContainer } from '@/new_containers/components/__common__'
import { MetricOverview, MetricsSectionCard } from '../__common__'

export const OverviewMetricsSection = ({ data, isLoading, ...props }: MetricsSectionPropsType) => {
    const [metric] = data?.metrics || []

    return (
        <MetricsSectionCard sources={metric?.sources} isLoading={isLoading} height={24} {...props}>
            <MetricOverview metric={metric} isLoading={isLoading} showTrend>
                <LineChartContainer
                    id={metric?.id}
                    metrics={[metric]}
                    showGradient
                    showTarget={true}
                    showYAxis={false}
                    showLabels={false}
                />
            </MetricOverview>
        </MetricsSectionCard>
    )
}
