import type { ThemeType } from '@octup/core/theme'

const DRAWER_WIDTH = '42rem'

export const formStyle = {
    width: '100%',
    height: '100%',
}

export const dashboardContainerStyle = (showDrawer: boolean) => (theme: ThemeType) => ({
    width: '100%',
    height: '100%',
    paddingBottom: theme.spacing(1.5),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(showDrawer && {
        overflow: 'auto',
        width: `calc(100% - ${DRAWER_WIDTH} + ${theme.spacing(6)})`,
        paddingRight: theme.spacing(4),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
})

export const drawerStyle = (theme: ThemeType) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: DRAWER_WIDTH,
        height: `calc(100% - ${theme.spacing(38)})`,
        top: theme.spacing(34),
        background: 'none',
        borderRadius: 0,
        boxShadow: theme.shadows[0],
        borderLeft: `${theme.spacing(0.1)} solid ${theme.palette.grey[400]}`,

        // Note: Temporary fix to avoid the drawer from covering the header when the user menu is open
        zIndex: 50,
    },
})
