import type { NavigateProps, RouteObject } from 'react-router-dom'

export enum DEFAULT_ROUTES {
    MAIN = '/',
    ALL = '/*',
}

export enum APP_ROUTES {
    LOGIN = 'Login',
    RESET_PASSWORD = 'reset-password',
    HOME = 'Home',
    MARKETING = 'Marketing',
    MARKETING_ATTRIBUTION = 'Marketing/Attribution',
    OPERATIONS = 'Operations',
    OPERATIONS_OVERVIEW = 'OperationsOverview',
    WAREHOUSING = 'warehousing',
    WAREHOUSING_OVERVIEW = 'warehousing-overview',
    UNIT_ECONOMICS = 'UnitEconomics',
    UNIT_ECONOMICS_COST_MANAGER = 'UnitEconomics/CostManager',
    INTEGRATIONS = 'Integrations',
    INSIGHTS = 'Insights',
    OBJECTIVES = 'Objectives',
    CLIENTS = 'Clients',
    DASHBOARDS = 'Dashboards',
    DASHBOARD = 'Dashboards/:dashboardId',
    DASHBOARD_EDIT = 'Dashboards/:dashboardId/edit',
    DASHBOARD_CREATE = 'Dashboards/:dashboardId/new',
    ACCOUNT = 'Account',
    USERS = 'users/:section?',
    USERS_BRAND = 'users/brand',
    USERS_PARTNER = 'users/partner',
    USERS_CLIENTS = 'users/clients',
    INVITE = 'invite',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    WAREHOUSE = 'Warehouse',
    SETTINGS = 'settings/:section?',
    SETTINGS_CLIENTS = 'settings/clients',
    SETTINGS_SLA = 'settings/sla',
    REGISTRATION = 'registration',
    SHOPIFY_REGISTRATION = 'registration/shopify',
    CLIENTS_LOGISTICS = 'clients-logistics',
    CLIENTS_SLA = 'clients-sla',
    ORDER = 'order/:orderId',
    ORDERS = 'orders',
    ORDERS_TO_FULFILL = 'orders-to-fulfill',
    ORDERS_FULFILLED = 'orders-fulfilled',
    ORDERS_DELAYED = 'orders-delayed',
    ORDERS_STUCK = 'orders-stuck',
    CLIENTS_ORDERS = 'clients-orders',
    CLIENTS_CURRENT_ORDERS_TO_FULFILL = 'clients-orders-current-to-fulfill',
    CLIENTS_CURRENT_ORDERS_STUCK = 'clients-orders-current-stuck',
    WMS_CLIENTS = 'wms-clients',
    REPORTS = 'reports',
    REPORTS_SLA_PERFORMANCE_LAST_12_MONTHS = 'reports-sla-performance-last-12-months',
    REPORTS_TOTAL_ORDERS_LAST_12_MONTHS = 'reports-total-orders-last-12-months',
    REPORTS_WEEK_OVERVIEW = 'reports-week-overview',
    ORDERS_PAGE = 'orders-page',
    INVOICE_HISTORY_PAGE = 'invoice-history-page',
    METRIC_DEEP_DIVE = 'metric/:metricId/deep-dive/:deepDiveId',
    METRIC_BLENDED_DEEP_DIVE = 'metric/:metricId/deep-dive/:deepDiveId/blended-deep-dive/:blendedDeepDiveId',
}

type ROUTES = DEFAULT_ROUTES | APP_ROUTES

export type NavigateRouteType = {
    element: (props: NavigateProps) => JSX.Element | null
    to: ROUTES
    isNavigate: true
    replace?: boolean
}

export type PageRouteType = {
    element: () => JSX.Element | null
}

export type AppRouteType = Omit<RouteObject, 'path' | 'element'> &
    (NavigateRouteType | PageRouteType) & {
        path: ROUTES
    }
