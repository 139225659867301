import { useContext } from 'react'
import { CircularProgress, Grid2, Stack } from '@octup/core/ui-kit'
import { ErrorMessageFallback } from '@/new_components/__common__'
import { MetricDeepDiveModalContainer } from '@/new_containers/components/Metrics'
import { TabsPageLayoutContainer } from '@/new_containers/layouts'
import PageTitle from './PageTitle'
import { ModalsContext } from '../../contexts/ModalsContext'

const PageLayout = ({ children, error, isLoading, hasFixedHeight = false, tabs, ...props }) => {
    const { deepdiveModal } = useContext(ModalsContext)

    if (error) return <ErrorMessageFallback />

    if (isLoading)
        return (
            <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        )

    return (
        <>
            <Grid2 container direction="column" wrap="nowrap" height="100%">
                <Grid2 pt={4.5} px={5.5}>
                    <PageTitle {...props} />
                </Grid2>
                <Grid2 container size="grow" p={5.5} pt={4} height={contentStyle(hasFixedHeight)}>
                    {tabs?.length && <TabsPageLayoutContainer tabs={tabs} />}
                    {children}
                </Grid2>
            </Grid2>

            {deepdiveModal?.show && <MetricDeepDiveModalContainer />}
        </>
    )
}

const contentStyle = (hasFixedHeight) => (theme) => ({
    ...(hasFixedHeight && {
        height: `calc(100% - ${theme.spacing(15)})`,
    }),
})

export default PageLayout
