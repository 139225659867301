import { COLOR_DISPLAY_TYPE_PATTERNS } from '@octup/core/models'
import type { PatternFormatterConfigType } from '@octup/core/models'

const CONDITION_RESULT_VALUES = {
    [COLOR_DISPLAY_TYPE_PATTERNS.POSITIVE]: true,
    [COLOR_DISPLAY_TYPE_PATTERNS.NEGATIVE]: false,
}

const removeCurlyBraces = (str: string): string => str.replace(/[{}]/g, '')

export const getConditionValidity = (
    data: Record<string, number | string | null>,
    pattern: PatternFormatterConfigType['pattern'],
    display_type_pattern: COLOR_DISPLAY_TYPE_PATTERNS
) => {
    const params = Object.keys(data)
    const values = Object.values(data)
    const condition = removeCurlyBraces(pattern)
    const conditionResult = new Function(...params, `return ${condition}`)(...values)
    return conditionResult === CONDITION_RESULT_VALUES[display_type_pattern]
}
