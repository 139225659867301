import {
    WarehouseFilterContainer,
    CountryFilterContainer,
    ServiceTypeFilterContainer,
} from 'new_containers/components/Logistics'

export const FILTERS = [
    { id: 1, name: 'Warehouse Name', component: WarehouseFilterContainer },
    { id: 2, name: 'Orders Destination', component: CountryFilterContainer },
    { id: 3, name: 'Service Type', component: ServiceTypeFilterContainer },
]
