import {
    addQuarters,
    addWeeks,
    addYears,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    format,
    isSameDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    endOfDay,
} from 'date-fns'
import { DATE_OPTIONS, getDefaultDatePickerObj } from 'features/datePicker/datePickerSlice'
import { defaultStaticRanges } from 'react-date-range'

/**
 *  0: week starts on Sunday
 *  1: week start on Monday
 * */
export const WEEK_START = 0
const UP_TO_DATE = true

const createRange = ({ label, startDate, endDate }) => ({
    label,
    range: () => ({ label, startDate, endDate }),
    isSelected(range) {
        const definedRange = this.range()
        return (
            label === range.label &&
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        )
    },
})

const DEFAULT_STATIC_RANGES_BY_LABEL = defaultStaticRanges.reduce(
    (acc, staticRange) => ({
        ...acc,
        [staticRange.label]: createRange({
            label: staticRange.label,
            ...staticRange.range(),
        }),
    }),
    {}
)

export const STATIC_RANGES = {
    ...DEFAULT_STATIC_RANGES_BY_LABEL,
    [DATE_OPTIONS.THIS_WEEK]: createRange({
        label: DATE_OPTIONS.THIS_WEEK,
        startDate: startOfWeek(new Date(), { weekStartsOn: WEEK_START }),
        endDate: UP_TO_DATE
            ? endOfDay(new Date())
            : endOfWeek(new Date(), { weekStartsOn: WEEK_START }),
    }),
    [DATE_OPTIONS.LAST_WEEK]: createRange({
        label: DATE_OPTIONS.LAST_WEEK,
        startDate: startOfWeek(addWeeks(new Date(), -1), { weekStartsOn: WEEK_START }),
        endDate: endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: WEEK_START }),
    }),
    [DATE_OPTIONS.THIS_MONTH]: createRange({
        label: DATE_OPTIONS.THIS_MONTH,
        startDate: startOfMonth(new Date()),
        endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfMonth(new Date()),
    }),
    [DATE_OPTIONS.THIS_QUARTER]: createRange({
        label: DATE_OPTIONS.THIS_QUARTER,
        startDate: startOfQuarter(new Date()),
        endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfQuarter(new Date()),
    }),
    [DATE_OPTIONS.LAST_QUARTER]: createRange({
        label: DATE_OPTIONS.LAST_QUARTER,
        startDate: startOfQuarter(addQuarters(new Date(), -1)),
        endDate: endOfQuarter(addQuarters(new Date(), -1)),
    }),
    [DATE_OPTIONS.THIS_YEAR]: createRange({
        label: DATE_OPTIONS.THIS_YEAR,
        startDate: startOfYear(new Date()),
        endDate: UP_TO_DATE ? endOfDay(new Date()) : endOfYear(new Date()),
    }),
    [DATE_OPTIONS.LAST_YEAR]: createRange({
        label: DATE_OPTIONS.LAST_YEAR,
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
    }),
}

export const getStaticRanges = ({ hideStaticDates, customStaticRanges }) => {
    if (hideStaticDates) return []

    const currentStaticRanges = customStaticRanges || Object.values(STATIC_RANGES)

    const staticRanges = currentStaticRanges.map((range) => ({
        ...(customStaticRanges ? STATIC_RANGES[range] : range),
        hasCustomRendering: true,
    }))

    return staticRanges
}

export const getFormattedDate = (datesRange) => ({
    label: datesRange[0].label,
    startDate: format(new Date(datesRange[0]?.startDate), 'yyyy-MM-dd'),
    endDate: format(new Date(datesRange[0]?.endDate), 'yyyy-MM-dd'),
})

export const getDateRange = (data) => [
    {
        ...getDefaultDatePickerObj(data),
        key: 'selection',
        color: '#007f82',
        label: data?.label,
    },
]
