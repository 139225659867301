import type { AnyPartOfType } from '@/models/__common__'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = Record<string, any>

export const updateObjectByPath = <T extends AnyObject, K extends AnyPartOfType<T>>(
    newData: K,
    obj?: T,
    path?: string
): T => {
    if (!obj || !path) return newData as T

    const keys = path.split('.')
    const lastKey = keys.pop()

    if (lastKey !== undefined) {
        const target = keys.reduce((acc, key) => (acc[key] = acc[key] ?? {}), obj as AnyObject)
        target[lastKey] = newData
    }

    return obj
}
