import { APP_ROUTES } from '@/models/appConfig'
import type { TabPageType } from '@/models/pages'
import { UsersTableContainer } from '@/new_containers/components/Users'

export const TABS: TabPageType[] = [
    {
        id: 1,
        label: 'Brand Users',
        route: APP_ROUTES.USERS_BRAND,
        Component: UsersTableContainer,
    },
    {
        id: 2,
        label: 'Partner Users',
        route: APP_ROUTES.USERS_PARTNER,
        Component: UsersTableContainer,
    },
    {
        id: 3,
        label: 'Clients Users',
        route: APP_ROUTES.USERS_CLIENTS,
        // TODO: https://ecomecom.atlassian.net/browse/OD-4401
        Component: () => null,
    },
]
