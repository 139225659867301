import { useParams } from 'react-router-dom'
import { MetricsDetailsMetricSelection } from '@/components/Modals/MetricsModals/MetricsDetailsMetricSelection'
import PageLayout from '@/components/PageLayout/PageLayout'
import { useQueryString } from '@/hooks'
import { MetricDeepDiveContainer } from '@/new_containers/components/Metrics'
import { useAppSelector } from '@/state/hooks'

export const MetricDeepDivePageContainer = () => {
    const { queryParams } = useQueryString()
    const { metricId, deepDiveId, blendedDeepDiveId } = useParams()
    const { datePickerValues } = useAppSelector((state) => state.datePicker)

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <PageLayout customTitleStyled={<MetricsDetailsMetricSelection />} hasFixedHeight>
            <MetricDeepDiveContainer
                metricId={metricId}
                deepDiveId={deepDiveId}
                blendedDeepDiveId={blendedDeepDiveId}
                datePicker={datePickerValues}
                clientId={queryParams.client_id}
            />
        </PageLayout>
    )
}
