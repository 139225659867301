import { useMemo } from 'react'
import type {
    ClientType,
    DateRangeType,
    MetricsBySectionType,
    MetricsSectionByIdType,
    MetricsSectionStateByIdType,
    MetricsSectionType,
} from '@octup/core/models'
import type { DashboardType } from '@/models/dashboards'
import { useAppSelector } from '@/state/hooks'

export type UseDashboardParamsType = DateRangeType & {
    id: DashboardType['id']
    clientId?: ClientType['id']
}

export const getDashboardSectionsStateById = (
    startDate: DateRangeType['startDate'],
    endDate: DateRangeType['endDate'],
    sectionsById?: MetricsSectionByIdType,
    sectionsList?: MetricsSectionType[]
) =>
    sectionsList?.reduce((acc: MetricsSectionStateByIdType, section) => {
        const sectionState = sectionsById?.[section?.id]?.[startDate]?.[endDate]
        return { ...acc, ...(sectionState && { [section?.id]: sectionState }) }
    }, {})

export const getMetricsBySectionType = (sectionStateById?: MetricsSectionStateByIdType) =>
    Object.values(sectionStateById || {}).reduce((acc, { data }) => {
        const sectionMetrics = data && { [data.type]: [...(acc[data.type] || []), ...data.metrics] }
        return { ...acc, ...sectionMetrics }
    }, {} as MetricsBySectionType)

export const useDashboard = ({
    id,
    startDate,
    endDate,
    clientId: customClientId,
}: UseDashboardParamsType) => {
    const { userInfo } = useAppSelector((state) => state.auth)
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const { metricsSections } = useAppSelector((state) => state.newMetrics)

    const dashboard = dashboardsById.data?.[id]
    const clientId = customClientId || userInfo.clientId
    const dashboardSectionsList = dashboard?.data?.sections
    const sectionsById = metricsSections?.data?.[clientId]

    const dashboardSectionStateById = useMemo(
        () =>
            getDashboardSectionsStateById(startDate, endDate, sectionsById, dashboardSectionsList),
        [endDate, sectionsById, dashboardSectionsList, startDate]
    )

    const dashboardMetricsBySectionType = useMemo(
        () => getMetricsBySectionType(dashboardSectionStateById),
        [dashboardSectionStateById]
    )

    const isDashboardSectionsLoading = useMemo(
        () => Object.values(dashboardSectionStateById || {}).some((state) => state.isLoading),
        [dashboardSectionStateById]
    )

    const isLoading = dashboard?.isLoading || isDashboardSectionsLoading

    return {
        startDate,
        endDate,
        clientId,
        dashboard,
        isLoading,
        sectionsList: dashboardSectionsList,
        sectionStateById: dashboardSectionStateById,
        metricsBySectionType: dashboardMetricsBySectionType,
    }
}
