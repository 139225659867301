import { forwardRef } from 'react'
import type { DialogProps } from '@mui/material'
import { Dialog as MuiDialog, DialogTitle } from '@mui/material'
import { CloseIcon } from '@octup/core/assets/icons'
import { Typography, SvgIcon, Grid, IconButton, FullScreenIconButton } from '@octup/core/ui-kit'

export type DialogPropsType = Omit<DialogProps, 'title'> & {
    title?: React.ReactNode
    onClose?: (event: React.MouseEvent) => void
    action?: React.ReactNode
    showCloseButton?: boolean
    showFullScreenButton?: boolean
    toggleFullScreen?: (event: React.MouseEvent) => void
}

export const Dialog = forwardRef<HTMLDivElement, DialogPropsType>(
    (
        {
            title,
            children,
            onClose,
            PaperProps,
            action,
            fullScreen,
            toggleFullScreen,
            showCloseButton = true,
            showFullScreenButton = false,
            ...props
        },
        ref
    ) => {
        const hasAction = action || showCloseButton || showFullScreenButton
        const showTitle = title || hasAction

        return (
            <MuiDialog
                ref={ref}
                onClose={onClose}
                fullScreen={fullScreen}
                PaperProps={{ ...PaperProps, elevation: 5 }}
                {...props}
            >
                {showTitle && (
                    <DialogTitle
                        component={Grid}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {title && (
                            <Grid item alignSelf="flex-start">
                                <Typography variant="h6" fontWeight={400}>
                                    {title}
                                </Typography>
                            </Grid>
                        )}
                        {hasAction && (
                            <Grid
                                item
                                xs
                                container
                                justifyContent="flex-end"
                                wrap="wrap-reverse"
                                gap={1}
                            >
                                {action}
                                {showFullScreenButton && (
                                    <FullScreenIconButton
                                        isOpen={fullScreen}
                                        onClick={toggleFullScreen}
                                    />
                                )}
                                {showCloseButton && (
                                    <IconButton onClick={onClose} color="secondary">
                                        <SvgIcon component={CloseIcon} />
                                    </IconButton>
                                )}
                            </Grid>
                        )}
                    </DialogTitle>
                )}
                {children}
            </MuiDialog>
        )
    }
)
