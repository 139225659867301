import { useCallback, useState } from 'react'
import type { DateRangeType } from '@octup/core/models'
import type { DashboardDialogPropsType } from '@/new_components/Dashboards'
import { DashboardDialog } from '@/new_components/Dashboards'
import { OctupDatePicker } from '@/shared/ui/DatePicker/OctupDatePicker'
import { useAppSelector } from '@/state/hooks'
import { METRICS_SECTION_CONFIG_BY_TYPE } from './utils'

export type MetricsDeepDiveDashboardContainerPropsType = Omit<DashboardDialogPropsType, 'isLoading'>

export const MetricsDeepDiveDashboardContainer = ({
    dashboardId,
    ...props
}: MetricsDeepDiveDashboardContainerPropsType) => {
    const [dateRange, setDateRange] = useState<DateRangeType>()
    const { dashboardsById } = useAppSelector((state) => state.dashboards)
    const dashboard = dashboardsById.data?.[dashboardId!]

    const handleChange = useCallback((newDateRange: DateRangeType) => {
        setDateRange(newDateRange)
    }, [])

    return (
        <DashboardDialog
            {...props}
            dateRange={dateRange}
            dashboardId={dashboardId}
            title={dashboard?.data?.name}
            sectionConfigByType={METRICS_SECTION_CONFIG_BY_TYPE}
            //  @ts-expect-error: OctupDatePicker is a JavaScript file
            action={<OctupDatePicker onChange={handleChange} />}
        />
    )
}
