import { Grid, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'

export const ExternalMenu = ({ anchorEl, onClose, config }) => {
    return (
        <Menu
            id="external-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'external-menu-trigger',
            }}
            sx={{
                '& .MuiMenu-paper': { ...config.menuStyle },
                '& .MuiMenuItem-gutters': { ...config.optionStyle },
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {config.options.map((el) => (
                <MenuItem
                    key={el.title}
                    onClick={() => {
                        el.clickHandler()
                        onClose()
                    }}
                    disabled={el.disabled}
                >
                    <Grid container alignItems="center" gap={0}>
                        {el.leftIcon && <ListItemIcon>{el.leftIcon}</ListItemIcon>}
                        <Typography variant="body2" color="text.primary">
                            {el.title}
                        </Typography>
                    </Grid>
                    <span>{el.rightIcon && el.rightIcon}</span>
                </MenuItem>
            ))}
        </Menu>
    )
}
