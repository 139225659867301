import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { ResetPasswordType } from '@/models/auth'
import { resetPassword } from '@/services/auth'

type ResetPasswordStateType = {
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: ResetPasswordStateType = {
    isLoading: false,
}

export const fetchResetPassword = createAsyncThunk<undefined, ResetPasswordType>(
    'fetchResetPassword',
    resetPassword
)

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchResetPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchResetPassword.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(fetchResetPassword.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const resetPasswordReducer = resetPasswordSlice.reducer
