import { Tooltip } from '@octup/core/ui-kit'

export type PatternFormatterPropsType = {
    description?: string
    children: React.ReactNode
}

export const PatternFormatter = ({ description, children }: PatternFormatterPropsType) => (
    <Tooltip title={description} placement="top-start">
        <span>{children}</span>
    </Tooltip>
)
