import type { Components, Theme } from '@mui/material'

type ListOverridesType = Pick<Components<Theme>, 'MuiListItemButton' | 'MuiListItemIcon'>

export const LIST_OVERRIDES: ListOverridesType = {
    MuiListItemIcon: {
        styleOverrides: {
            root: ({ theme }) => ({
                minWidth: theme.spacing(7),
            }),
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderRadius: theme.spacing(2),

                '&.MuiButtonBase-root': {
                    padding: theme.spacing(1, 2),
                },
            }),
        },
    },
}
