import { AlternativeHome } from '@/components/AlternativeHome/AlternativeHome'
import MetricsHome from '@/components/Metrics/MetricsHome'

export function HomeAlternativeView() {
    return (
        <>
            <MetricsHome />
            <AlternativeHome />
        </>
    )
}
