import { useMemo } from 'react'
import type { PatternFormatterPropsType, FORMATTER_DISPLAY_TYPES } from '@octup/core/models'
import { LINK_DISPLAY_TYPE_PATTERNS } from '@octup/core/models'
import { LinkPatternFormatter } from '@/new_components/__common__'
import { getLinkByPattern } from './utils'

export const LinkPatternFormatterContainer = ({
    data,
    value,
    config,
}: PatternFormatterPropsType<FORMATTER_DISPLAY_TYPES.LINK>) => {
    const { pattern, description, display_type_pattern } = config || {}
    const link = useMemo(() => getLinkByPattern(pattern, data), [pattern, data])
    const isExternal = display_type_pattern === LINK_DISPLAY_TYPE_PATTERNS.EXTERNAL

    return (
        <LinkPatternFormatter
            link={link}
            value={value}
            isExternal={isExternal}
            description={description}
        />
    )
}
