import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppExcludedFeaturesType } from 'models/appConfig'
import { getExcludedFeatures } from 'services/auth'
import type { ThunkAPIConfigType } from 'services/utils'
import { DEFAULT_EXCLUDED_FEATURES } from './utils'

type AppExcludedFeaturesStateType = {
    data: AppExcludedFeaturesType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: AppExcludedFeaturesStateType = {
    data: DEFAULT_EXCLUDED_FEATURES,
    isLoading: true,
}

export const fetchExcludedFeatures = createAsyncThunk<
    AppExcludedFeaturesType,
    undefined,
    ThunkAPIConfigType
>('fetchExcludedFeatures', getExcludedFeatures)

export const excludedFeaturesSlice = createSlice({
    name: 'excludedFeatures',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchExcludedFeatures.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchExcludedFeatures.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchExcludedFeatures.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const excludedFeaturesReducer = excludedFeaturesSlice.reducer
