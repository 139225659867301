import { AccordionDetails, AccordionActions } from '@octup/core/ui-kit'
import { FormSubmitButtonContainer } from '@/new_containers/components/__common__'
import type { FormContentPropsType } from '..'
import { FormContent } from '..'

export const AccordionFormContent = ({ children, isLoading, error }: FormContentPropsType) => (
    <>
        <AccordionDetails>
            <FormContent isLoading={isLoading} error={error}>
                {children}
            </FormContent>
        </AccordionDetails>
        {!isLoading && !error && (
            <AccordionActions>
                <FormSubmitButtonContainer />
            </AccordionActions>
        )}
    </>
)
