import { FORMATTER_DISPLAY_TYPES } from '@octup/core/models'
import { Link } from '@/new_components/__common__'
import { PatternFormatter } from '../PatternFormatter'

export type LinkPatternFormatterPropsType = {
    link: string
    value: string
    description?: string
    isExternal?: boolean
}

export const LinkPatternFormatter = ({
    link,
    value,
    description,
    isExternal,
}: LinkPatternFormatterPropsType) => (
    <PatternFormatter description={description}>
        <Link
            to={link}
            target="_blank"
            isExternal={isExternal}
            data-testid={FORMATTER_DISPLAY_TYPES.LINK}
        >
            {value}
        </Link>
    </PatternFormatter>
)
