import { useMemo } from 'react'
import type { AuthLayoutPropsType } from '@/layouts'
import { AuthLayout } from '@/layouts'
import { useAppSelector } from '@/state/hooks'
import { createTheme } from '@/theme'

type AuthLayoutContainerPropsType = Omit<AuthLayoutPropsType, 'theme'>

export const AuthLayoutContainer = (props: AuthLayoutContainerPropsType) => {
    const { settings } = useAppSelector((state) => state.appConfig)
    const { palette_mode } = settings.data || {}
    const theme = useMemo(() => createTheme(palette_mode), [palette_mode])

    return <AuthLayout theme={theme} {...props} />
}
