import type { DataGridPropsType } from '@octup/core/ui-kit'
import { DataGrid } from '@octup/core/ui-kit'
import type { OrderType } from '@/models/orders'
import { SingleOrderDeepDiveModal } from '@/pages/operations/single-order/SingleOrderDeepDiveModal'
import { tableStyle } from './styles'

export type OrdersTablePropsType = DataGridPropsType & {
    isDeepDiveOpen: boolean
    selectedRow?: OrderType
    onDeepDiveClose: () => void
}

export const OrdersTable = ({
    isDeepDiveOpen,
    selectedRow,
    onDeepDiveClose,
    ...props
}: OrdersTablePropsType) => (
    <>
        <DataGrid {...props} sx={tableStyle} />
        {isDeepDiveOpen && (
            <SingleOrderDeepDiveModal selectedItem={selectedRow} onDismissModal={onDeepDiveClose} />
        )}
    </>
)
