import { Stack, TextField, Typography, Select, MenuItem, Switch } from '@octup/core/ui-kit'
import type { EMAIL_FREQUENCY_UNITS } from '@/models/settings'
import {
    FormFieldContainer,
    FormSubmitButtonContainer,
} from '@/new_containers/components/__common__'
import { buttonStyle, inputStyle, selectStyle } from './styles'

export type EmailSettingsFormPropsType = {
    isEmailEnabled: boolean
    unitOptions: EMAIL_FREQUENCY_UNITS[]
}

export const EmailSettingsForm = ({ isEmailEnabled, unitOptions }: EmailSettingsFormPropsType) => (
    <Stack spacing={4}>
        <FormFieldContainer name="email_enabled" hasInlineError label="Send report" as={Switch} />
        {isEmailEnabled && (
            <Stack spacing={2} direction="row" alignItems="center">
                <Typography variant="body1">every</Typography>
                <FormFieldContainer
                    name="frequency"
                    hasInlineError
                    as={TextField}
                    type="number"
                    size="small"
                    sx={inputStyle}
                />
                <FormFieldContainer
                    name="unit"
                    hasInlineError
                    as={Select}
                    size="small"
                    sx={selectStyle}
                >
                    {unitOptions.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                            {unit}
                        </MenuItem>
                    ))}
                </FormFieldContainer>
            </Stack>
        )}
        <FormSubmitButtonContainer sx={buttonStyle} />
    </Stack>
)
