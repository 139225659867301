import { useCallback, useState } from 'react'

export const useToggle = (defaultVisibility: boolean = false) => {
    const [isVisible, setIsVisible] = useState(defaultVisibility)

    const toggleVisibility = useCallback((event: React.MouseEvent) => {
        event.stopPropagation()
        setIsVisible((prev) => !prev)
    }, [])

    return { isVisible, toggleVisibility }
}
