import { useCallback, useEffect, useMemo, useState } from 'react'
import type {
    GridApiCommonType,
    GridCallbackDetailsType,
    GridCellParamsType,
    GridPaginationModelType,
    GridSortModelType,
} from '@octup/core/ui-kit'
import type { OrderTableParamsType, OrderTableType } from '@/models/orders'
import { OrdersTable } from '@/new_components/Orders'
import { DEFAULT_VALUES, getTableConfig } from './utils'

export type OrdersTableContainerPropsType = {
    config?: OrderTableType
    fetchData: (data: OrderTableParamsType) => void
}

export const OrdersTableContainer = ({
    config,
    fetchData,
    ...props
}: OrdersTableContainerPropsType) => {
    const [selectedRow, setSelectedRow] = useState()
    const [isDeepDiveOpen, setIsDeepDiveOpen] = useState(false)
    const tableConfig = useMemo(() => getTableConfig(config), [config])
    const toggleIsDeepDiveOpen = useCallback(() => setIsDeepDiveOpen((open) => !open), [])

    useEffect(() => {
        fetchData(DEFAULT_VALUES)
    }, [fetchData])

    const handleCellClick = useCallback(
        ({ row, colDef }: GridCellParamsType) => {
            if (colDef.cellClassName === 'clickable') {
                setSelectedRow(row)
                toggleIsDeepDiveOpen()
            }
        },
        [toggleIsDeepDiveOpen]
    )

    const handleModelChange = useCallback(
        (state: GridApiCommonType['state']) => {
            const { pagination, sorting, search } = state
            const { page, pageSize } = pagination.paginationModel
            const { field, sort } = sorting.sortModel[0] || {}
            fetchData({ page, pageSize, sortBy: field, sortOrder: sort, search })
        },
        [fetchData]
    )

    const handleSortModelChange = useCallback(
        (_: GridSortModelType, details: GridCallbackDetailsType) =>
            handleModelChange(details.api.state),
        [handleModelChange]
    )

    const handlePaginationModelChange = useCallback(
        (_: GridPaginationModelType, details: GridCallbackDetailsType) =>
            handleModelChange(details.api.state),
        [handleModelChange]
    )

    const handleSearchChange = useCallback(
        (_: string, state: GridApiCommonType['state']) => handleModelChange(state),
        [handleModelChange]
    )

    return (
        <>
            <OrdersTable
                {...props}
                {...tableConfig}
                selectedRow={selectedRow}
                isDeepDiveOpen={isDeepDiveOpen}
                onDeepDiveClose={toggleIsDeepDiveOpen}
                onCellClick={handleCellClick}
                onSearchChange={handleSearchChange}
                onSortModelChange={handleSortModelChange}
                onPaginationModelChange={handlePaginationModelChange}
            />
        </>
    )
}
