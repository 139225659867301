import { Stack } from '@octup/core/ui-kit'
import { FormFieldArrayContainer, FormFieldContainer } from '@/new_containers/components/__common__'
import { containerStyle } from './styles'
import { WeekdaySelect } from './WeekdaySelect'
import { WorkingWeekdaysField } from './WorkingWeekdaysField'

export const WorkingHoursSettingsForm = () => (
    <Stack spacing={8} maxWidth={containerStyle}>
        <FormFieldArrayContainer as={WorkingWeekdaysField} name="hours_mapping" />
        <FormFieldContainer
            name="first_day"
            as={WeekdaySelect}
            label="First Working Day"
            fullWidth
        />
    </Stack>
)
