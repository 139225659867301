import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { MetricsSectionsListType } from '@/models/metrics'
import { getMetricsSectionsList } from '@/services/metricsSections'
import type { ThunkAPIConfigType } from '@/services/utils'

type MetricsSectionsListStateType = {
    data?: MetricsSectionsListType
    isLoading: boolean
    error?: string
    searchValue?: string
}

const INITIAL_STATE: MetricsSectionsListStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
    searchValue: undefined,
}

export const fetchMetricsSectionsList = createAsyncThunk<
    MetricsSectionsListType,
    undefined,
    ThunkAPIConfigType
>('fetchMetricsSectionsList', (_, thunkAPI) => getMetricsSectionsList(thunkAPI))

export const metricsSectionsListSlice = createSlice({
    name: 'metricsSectionsList',
    initialState: INITIAL_STATE,
    reducers: {
        setSearchValue(state, actions) {
            state.searchValue = actions.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetricsSectionsList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchMetricsSectionsList.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchMetricsSectionsList.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { setSearchValue } = metricsSectionsListSlice.actions
export const metricsSectionsListReducer = metricsSectionsListSlice.reducer
