import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useAbilities, useInterval } from '@/hooks'
import { APP_ROUTES } from '@/models/appConfig'
import { AverageIntegrationsStatusContainer } from '@/new_containers/components/Integrations'
import { fetchIntegrationsStatus } from '@/state/features/integrations'
import { useAppDispatch } from '@/state/hooks'
import { UserMenu } from '../../UserMenu/UserMenu'

const Header = () => {
    const dispatch = useAppDispatch()
    const { isPageAvailable } = useAbilities()
    const { isMainMenuCollapsed } = useSelector((state) => state.navigation)
    const { userInfo } = useSelector((state) => state.auth)

    const getIntegrationsStatus = useCallback(() => {
        dispatch(fetchIntegrationsStatus())
    }, [dispatch])

    useInterval(getIntegrationsStatus, 30 * 60 * 1000, {
        hasInitialCall: true,
        disabled: !isPageAvailable(APP_ROUTES.INTEGRATIONS),
    })

    return (
        <div>
            <HeaderContainer isMainMenuCollapsed={isMainMenuCollapsed}>
                <ButtonPrimaryContainer>
                    <AverageIntegrationsStatusContainer width="20rem" />
                    {userInfo?.client?.name && (
                        <TempStoreName>{userInfo?.client?.name}</TempStoreName>
                    )}
                    <LoggedUserBtn>
                        <UserMenu />
                    </LoggedUserBtn>
                </ButtonPrimaryContainer>
            </HeaderContainer>
        </div>
    )
}

const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    left: ${(props) => (props.isMainMenuCollapsed ? '5rem' : '24.2rem')};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: ${(props) => (props.isMainMenuCollapsed ? 'calc(100% - 5rem)' : 'calc(100% - 24.2rem)')};
`

const ButtonPrimaryContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    gap: 2rem;
`

const LoggedUserBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 3rem;
    height: 6rem;
    cursor: pointer;
`

const TempStoreName = styled.div`
    text-transform: capitalize;
    border: 0.1rem solid #504b5a;
    border-radius: 0.8rem;
    width: fit-content;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0.8rem 0px 1.2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
`

export default Header
