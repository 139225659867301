import { ExpandIcon, CollapseIcon } from '@octup/core/assets/icons'
import type { IconButtonPropsType } from '@octup/core/ui-kit'
import { IconButton, SvgIcon } from '@octup/core/ui-kit'

export type FullScreenIconButtonPropsType = IconButtonPropsType & {
    isOpen?: boolean
}

export const FullScreenIconButton = ({
    isOpen = false,
    ...props
}: FullScreenIconButtonPropsType) => (
    <IconButton color="secondary" {...props}>
        <SvgIcon component={isOpen ? CollapseIcon : ExpandIcon} />
    </IconButton>
)
