import { Stack } from '@octup/core/ui-kit'
import PageLayout from '@/components/PageLayout/PageLayout'
import { CardsContainer, TabsContainer } from '@/new_containers/components/Integrations'

export const IntegrationsPage = ({ error, onConnect, onDisconnect }) => (
    <PageLayout showDatePicker={false} error={error} hasFixedHeight>
        <Stack height="100%">
            <TabsContainer />
            <CardsContainer onClickHandler={onConnect} onDisconnectClick={onDisconnect} />
        </Stack>
    </PageLayout>
)
