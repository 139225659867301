import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GLOBAL_CONFIG } from '@/config'
import { extractErrMsg } from '@/shared/utils/utils'
import { authService } from './authService'

export const AUTH_API_URL = GLOBAL_CONFIG.AUTH_MS_BASE_URL + 'auth'
export const USER_API_URL = GLOBAL_CONFIG.AUTH_MS_BASE_URL

function updateFields(target, source) {
    for (let key in source) {
        if (source.hasOwnProperty(key)) {
            if (target.hasOwnProperty(key)) {
                target[key] = source[key]
            }
        }
    }
    return target
}

const initialSessionState = {
    isValid: true,
    isPaymentRequired: false,
}

const initialState = {
    inviteError: null,
    userInfo: localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    isLoading: false,
}

// register new Octup user
export const register = createAsyncThunk(`${AUTH_API_URL}/register`, async (userInfo, thunkAPI) => {
    try {
        return await authService.register(userInfo)
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

// login Octup user
export const login = createAsyncThunk(`${AUTH_API_URL}/login`, async (userInfo, thunkAPI) => {
    try {
        const login = await authService.login(userInfo)
        return login
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})

export const getUserProfile = createAsyncThunk(
    `${USER_API_URL}/user/profile`,
    async (token, thunkAPI) => {
        try {
            return authService.getUserProfile(token)
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const updateUserProfile = createAsyncThunk(
    `${USER_API_URL}/user/update_ui`,
    async (userInfo, thunkAPI) => {
        try {
            return authService.updateUserProfile(userInfo?.token, userInfo)
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const getClientInfo = createAsyncThunk(`${USER_API_URL}/client`, async (token, thunkAPI) => {
    try {
        return authService.getClientInfo(token)
    } catch (er) {
        return thunkAPI.rejectWithValue(extractErrMsg(er))
    }
})

export const resetPassword = createAsyncThunk(
    `${AUTH_API_URL}/password_reset`,
    async ({ passwordResetToken, password }, thunkAPI) => {
        try {
            return authService.resetPassword({ passwordResetToken, password })
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const otpGenerate = createAsyncThunk(
    `${AUTH_API_URL}/otp/generate`,
    async (userinfo, thunkAPI) => {
        try {
            return authService.otpGenerate(userinfo.token)
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const validateEmail = createAsyncThunk(
    `${AUTH_API_URL}/validate/email`,
    async (userinfo, thunkAPI) => {
        try {
            return authService.validateEmail(userinfo)
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const forgotPassword = createAsyncThunk(
    `${AUTH_API_URL}/forgot/password`,
    async (userinfo, thunkAPI) => {
        try {
            return authService.forgotPassword(userinfo)
        } catch (er) {
            return thunkAPI.rejectWithValue(extractErrMsg(er))
        }
    }
)

export const logout = createAsyncThunk(`${AUTH_API_URL}/logout`, async (thunkAPI) => {
    try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const res = await authService.logout(userInfo.token)
        return res
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const sendInvites = createAsyncThunk(`${USER_API_URL}/user/invite`, async (payload) => {
    return authService.sendInvites(payload)
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserInfo(state, action) {
            const { client, user, token } = action.payload
            const userInfo = { ...user, token, client }
            state.userInfo = userInfo
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInvites.rejected, (state) => {
                state.inviteError = 'Rejected'
            })
            .addCase(sendInvites.fulfilled, (state, action) => {
                state.inviteError = action.payload
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.userInfo = {
                    token: action?.payload?.token,
                    id: action?.payload?.id,
                    imageSrc: action?.payload?.imageSrc,
                    username: action?.payload?.fullName,
                }
                state.isLoading = false
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({
                        token: action?.payload?.token,
                    })
                )
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getUserProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.isLoading = false
                const userInfo = JSON.parse(localStorage.getItem('userInfo'))
                state.userInfo = {
                    ...action.payload,
                    token: userInfo?.token,
                    username: action?.payload?.fullName,
                }
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({ ...action.payload, token: userInfo?.token })
                )
            })
            .addCase(getClientInfo.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getClientInfo.fulfilled, (state, action) => {
                state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                state.isLoading = false
                state.userInfo['client'] = { ...action.payload }
                localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'))
                const updatedUserInfo = updateFields(storedUserInfo, action?.payload)
                state.userInfo = updatedUserInfo
                localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
            })
            .addCase(logout.pending, (state) => {
                state.isLoading = true
                state.userInfo = null
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false
                localStorage.setItem('showLCCountdown', true)
            })
            .addCase(logout.rejected, (state) => {
                state.isLoading = false
            })
    },
})

export const sessionSlice = createSlice({
    name: 'session',
    initialState: initialSessionState,
    reducers: {
        resetSession() {
            return initialSessionState
        },
        invalidateSession(state) {
            state.isValid = false
        },
        paymentRequired(state) {
            state.isPaymentRequired = true
        },
    },
})

export const { setUserInfo } = authSlice.actions
export const authenticationState = (state) => state.auth
export const authenticationSliceReducer = authSlice.reducer
export const sessionActions = sessionSlice.actions
export const sessionState = (state) => state.session
export const sessionSliceReducer = sessionSlice.reducer
