import type { Components, Theme } from '@mui/material'

type TooltipOverridesType = Pick<Components<Theme>, 'MuiTooltip'>

export const TOOLTIP_OVERRIDES: TooltipOverridesType = {
    MuiTooltip: {
        defaultProps: {
            arrow: true,
        },
        styleOverrides: {
            arrow: ({ theme }) => ({
                color: theme.palette.background.contrast,
            }),
            tooltip: ({ theme }) => ({
                fontWeight: 400,
                margin: theme.spacing(2),
                padding: theme.spacing(4),
                maxWidth: theme.spacing(86),
                fontSize: theme.typography.body2.fontSize,
                boxShadow: theme.shadows[5],
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.contrast,
                borderRadius: theme.spacing(2),
            }),
        },
    },
}
