import { forwardRef } from 'react'
import { LinearProgress as MuiLinearProgress } from '@mui/material'
import type { LinearProgressProps as MuiLinearProgressPropsType } from '@mui/material'
import { containerStyle } from './styles'
import { Box } from '..'

export type LinearProgressPropsType = MuiLinearProgressPropsType & {
    isLoading?: boolean
}

export const LinearProgress = forwardRef<LinearProgressPropsType['ref'], LinearProgressPropsType>(
    ({ isLoading, ...props }, ref) => (
        <Box sx={containerStyle}>{isLoading && <MuiLinearProgress ref={ref} {...props} />}</Box>
    )
)
