import { octupDictionary } from 'shared/dictionary/dictionary'
import { getInputDate } from 'shared/utils/dates-util'

export const isComparedTitle = (title) => title.includes('compared')

export const getDateRange = ({ data: { dataset }, timeSlice }) => {
    if (!dataset.length) return

    const [, startDate] = dataset[0]
    const [, endDate] = dataset[dataset.length - 1]
    return `${getInputDate(startDate, timeSlice)} - ${getInputDate(endDate, timeSlice)}`
}

export const getComparisonChartData = ({ data, timeSlice, isCompared }) => ({
    pointType: 'line',
    isDashed: isCompared,
    tooltipLabels: data?.dataset?.map((item) => getInputDate(item[1], timeSlice)),
})

export const getDefaultChartData = ({ data }) => ({
    label: octupDictionary.titles.metricsTitles[data.metric.metricTitle] || data.metric.metricTitle,
    data: data?.dataset?.map((item) => item[0] || 0),
})

export const getGroupIndexes = ({ item, datasets }) => {
    if (item.pointType === 'line') {
        return datasets.reduce((acc, dataset, index) => {
            if (item.borderDash?.length && dataset.isDashed) return [...acc, index]
            if (!item.borderDash?.length && !dataset.isDashed) return [...acc, index]
            return acc
        }, [])
    } else {
        return datasets.reduce(
            (acc, dataset, index) => (dataset.label === item.label ? [...acc, index] : acc),
            []
        )
    }
}
