import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { GeneralSettingsType, GeneralFieldsSettingsType } from 'models/settings'
import type { ThunkAPIConfigType } from 'services/utils'
import { getGeneralSettings } from '@/services/settings'

type GeneralSettingsStateType = {
    data?: GeneralFieldsSettingsType
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: GeneralSettingsStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchGeneralSettings = createAsyncThunk<
    GeneralSettingsType,
    undefined,
    ThunkAPIConfigType
>('fetchGeneralSettings', (_, thunkAPI) => getGeneralSettings(thunkAPI))

export const generalSettingsSlice = createSlice({
    name: 'generalSettings',
    initialState: INITIAL_STATE,
    reducers: {
        updateGeneralSettings: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneralSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchGeneralSettings.fulfilled, (state, action) => {
                const generalSettings = action.payload.general
                const [fieldsSettings] = Object.values(generalSettings)
                state.data = fieldsSettings
                state.isLoading = false
            })
            .addCase(fetchGeneralSettings.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const { updateGeneralSettings } = generalSettingsSlice.actions
export const generalSettingsReducer = generalSettingsSlice.reducer
