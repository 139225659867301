import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

export const UNIT_ECONOMICS_API_URL = GLOBAL_CONFIG.BASE_URL + 'cost'

/**
 * get invoices available dates
 */

export const getInvoicesDates = async (thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })

    const response = await axios.get(`${UNIT_ECONOMICS_API_URL}/invoices-dates`, config)
    return response.data
}

/**
 * get products
 * @date optional
 */

export const getProducts = async (data, thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI, params: data })
    const response = await axios.get(`${UNIT_ECONOMICS_API_URL}/products`, config)
    return response.data
}

/**
 * get all costs
 */

export const getCosts = async (thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${UNIT_ECONOMICS_API_URL}/cost_manager`, config)
    return response.data
}

/**
 * update or create cost
 */

export const updateCost = async (data, thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })

    try {
        const path = `${UNIT_ECONOMICS_API_URL}/cost_manager/update`
        const response = await axios.put(path, data, config)
        return response.data
    } catch (error) {
        // TODO: check how else to get a status code
        return thunkAPI.rejectWithValue({ status: error.response.status })
    }
}

/**
 * update or create cost
 */

export const deleteCost = async (data, thunkAPI) => {
    const config = generateAPIConfig({ thunkAPI })

    try {
        const path = `${UNIT_ECONOMICS_API_URL}/cost_manager/delete`
        const response = await axios.put(path, data, config)
        return response.data
    } catch (error) {
        // TODO: check how else to get a status code
        return thunkAPI.rejectWithValue({ status: error.response.status })
    }
}
