import { Grid2 } from '@mui/material'
import { LoadableContent } from 'new_components/__common__'
import {
    ChartContainer,
    ComparisonDropdownContainer,
    TableContainer,
} from 'new_containers/components/Metrics'
import { comparisonContainerStyle, tableContainerStyle } from './styles'

export const MetricDeepDive = ({ data, isLoading, metricsGroup = [] }) => (
    <Grid2 container width="100%" height="100%" direction="column" wrap="nowrap" gap={1}>
        <Grid2 container xs={2} sx={comparisonContainerStyle}>
            <ComparisonDropdownContainer metricsGroup={metricsGroup} />
        </Grid2>
        <LoadableContent isLoading={isLoading} height="30rem">
            <Grid2 container xs={3}>
                <ChartContainer data={data} />
            </Grid2>
        </LoadableContent>
        <LoadableContent isLoading={isLoading}>
            <Grid2 container size="grow" sx={tableContainerStyle}>
                <TableContainer data={data} />
            </Grid2>
        </LoadableContent>
    </Grid2>
)
