import { COMPARISON_MODES, DEFAULT_COMPARISON_MODE } from '@/constants/metrics'

const METRIC_OPTION = { name: 'Another metric', value: COMPARISON_MODES.METRIC }

const COMMON_COMPARISON_OPTIONS = [
    { name: 'None', value: COMPARISON_MODES.NONE },
    { name: 'Previous Period', value: COMPARISON_MODES.PERIOD },
    { name: 'Previous Year', value: COMPARISON_MODES.YEAR },
]

const BLENDED_COMPARISON_OPTIONS = [...COMMON_COMPARISON_OPTIONS, METRIC_OPTION]

const BREAKDOWN_COMPARISON_OPTIONS = [
    ...COMMON_COMPARISON_OPTIONS,
    {
        ...METRIC_OPTION,
        disabled: true,
        tooltip: `You're currently in breakdown mode. Switch to blended mode in order to compare to a metric`,
    },
]

export const isSlaMetric = (metric) => metric?.deep_dive?.includes?.('sla')

const excludeMetricComparison = (options) =>
    options.filter((option) => option.value !== COMPARISON_MODES.METRIC)

export const getOptions = ({ isBreakdown, metricsGroup, isSelectedSla }) => {
    const availableOptions = isBreakdown ? BREAKDOWN_COMPARISON_OPTIONS : BLENDED_COMPARISON_OPTIONS
    const currentOptions = availableOptions.map((option) => ({
        ...option,
        isSelected: option.value === DEFAULT_COMPARISON_MODE,
    }))

    if (metricsGroup.length < 2) {
        return excludeMetricComparison(currentOptions)
    }

    const slaMetricsCount = metricsGroup.reduce(
        (acc, metricGroup) => acc + (isSlaMetric(metricGroup.metric) ? 1 : 0),
        0
    )

    if (isSelectedSla && slaMetricsCount < 2) {
        return excludeMetricComparison(currentOptions)
    }

    return currentOptions
}
