import { useCallback, useMemo } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ERROR_MESSAGES } from '@/constants'
import { setUserInfo } from '@/features/auth/authSlice'
import { useToast } from '@/hooks'
import type {
    CreateUserFormType,
    UserFormDataType,
    SIGNUP_SOURCES,
    SessionType,
} from '@/models/auth'
import { CreateUserForm } from '@/new_components/Auth'
import { FormContainer } from '@/new_containers/components/__common__'
import { fetchSignupBySource } from '@/state/features/auth'
import { useAppDispatch } from '@/state/hooks'
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from './utils'

type CreateUserFormContainerPropsType = {
    source: SIGNUP_SOURCES
    userData: UserFormDataType
}

export const CreateUserFormContainer = ({ source, userData }: CreateUserFormContainerPropsType) => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const defaultValues = useMemo(() => ({ ...DEFAULT_VALUES, ...userData }), [userData])

    const handleSubmit = useCallback(
        ({ password }: CreateUserFormType) =>
            dispatch(fetchSignupBySource({ source, data: { ...userData, password } })),
        [dispatch, userData, source]
    )

    const handleSuccess = useCallback(
        (data: PayloadAction<SessionType>) => dispatch(setUserInfo(data.payload)),
        [dispatch]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        /* @ts-expect-error: FormContainer is a JavaScript file */
        <FormContainer
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
        >
            <CreateUserForm />
        </FormContainer>
    )
}
