import { object } from 'yup'
import { ERROR_MESSAGES } from '@/constants/validation'
import type { GeneralFieldsSettingsType } from '@/models/settings'
import { VALIDATION_RULES } from '@/utils'

export const convertToForm = (data?: GeneralFieldsSettingsType) => ({
    stuck_days: data?.stuck_days || '',
})

export const VALIDATION_SCHEMA = object({
    stuck_days: VALIDATION_RULES.NUMBER.positive(ERROR_MESSAGES.POSITIVE).required(
        ERROR_MESSAGES.REQUIRED
    ),
})
