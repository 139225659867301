import { useMemo, useEffect } from 'react'
import * as icons from '@mui/icons-material'
import { CircularProgress, OverflowTypography } from '@octup/core/ui-kit'
import type { NavigationType } from '@octup/core/ui-kit'
import { APP_ROUTES } from '@/models/appConfig'
import type { NavigationPageType } from '@/models/pages'
import { AddDashboardButtonContainer } from '@/new_containers/components/Dashboards/AddDashboardButtonContainer'
import { fetchDashboardsList } from '@/state/features/dashboards'
import { fetchSidebarPages } from '@/state/features/pages'
import { useAppDispatch, useAppSelector } from '@/state/hooks'

const getNavigation = (pages: NavigationPageType[]): NavigationType =>
    pages.map(({ name, route, icon, action, isLoading, menu_items, pattern }) => {
        const Icon = icon && icons[icon]
        return {
            segment: route,
            // NOTE: Temporary, for now Toolpad expects title to be a string
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            title: (<OverflowTypography>{name}</OverflowTypography>) as any,
            icon: Icon ? <Icon /> : null,
            action: isLoading ? <CircularProgress size={13} /> : action,
            children: menu_items ? getNavigation(menu_items) : undefined,
            pattern,
        }
    })

export const useSidebarNavigation = () => {
    const dispatch = useAppDispatch()
    const { userInfo } = useAppSelector((state) => state.auth)
    const { dashboardsList } = useAppSelector((state) => state.dashboards)
    const { data: sidebarPages } = useAppSelector((state) => state.pages.sidebarPages)

    const isDashboardsAvailable = useMemo(
        () => !!sidebarPages.find(({ route }) => route === APP_ROUTES.DASHBOARDS),
        [sidebarPages]
    )

    useEffect(() => {
        if (userInfo?.clientId && !sidebarPages.length) {
            dispatch(fetchSidebarPages())
        }
    }, [dispatch, sidebarPages, userInfo])

    useEffect(() => {
        if (isDashboardsAvailable) {
            dispatch(fetchDashboardsList())
        }
    }, [dispatch, isDashboardsAvailable])

    const pages = useMemo((): NavigationPageType[] => {
        if (!dashboardsList?.data) return sidebarPages
        return sidebarPages.map((page) => ({
            ...page,
            ...(page.route === APP_ROUTES.DASHBOARDS && {
                isLoading: dashboardsList.isLoading,
                action: <AddDashboardButtonContainer />,
                menu_items: dashboardsList.data.map((dashboard) => ({
                    route: dashboard.id,
                    name: dashboard.name,
                    pattern: `${dashboard.id}{/:view}?`,
                })),
            }),
        }))
    }, [sidebarPages, dashboardsList.data, dashboardsList.isLoading])

    const sidebarNavigation = useMemo(() => getNavigation(pages), [pages])

    return sidebarNavigation
}
