import { APP_FEATURES, APP_ROUTES } from 'models/appConfig'

const IGNORED_PATHS = [APP_ROUTES.LOGIN, APP_ROUTES.TERMS_AND_CONDITIONS]
const DEFAULT_EXCLUDED_PAGES = Object.values(APP_ROUTES).reduce((acc: APP_ROUTES[], path) => {
    const mainPath = path.split('/:')[0] as APP_ROUTES
    return acc.includes(mainPath) || IGNORED_PATHS.includes(mainPath) ? acc : [...acc, mainPath]
}, [])

export const DEFAULT_EXCLUDED_FEATURES = {
    names: Object.values(APP_FEATURES),
    pages: DEFAULT_EXCLUDED_PAGES,
}
