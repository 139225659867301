import { useMemo, useState } from 'react'
import type { SystemPagePropsType } from '@/models/pages'
import { SystemListPage } from '@/new_pages'
import { getSearchedOptions } from '@/utils'

export const SystemListPageContainer = ({ data, dateRange }: SystemPagePropsType) => {
    const [searchValue, setSearchValue] = useState<string | undefined>()

    const searchedData = useMemo(
        () => getSearchedOptions({ value: searchValue, options: data }),
        [data, searchValue]
    )

    return (
        <SystemListPage
            data={searchedData}
            dateRange={dateRange}
            searchValue={searchValue}
            onSearch={setSearchValue}
        />
    )
}
