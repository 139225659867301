import { useMemo } from 'react'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import type {
    DatePickerProps as DatePickerPropsType,
    BaseSingleInputPickersTextFieldProps as BaseSingleInputPickersTextFieldPropsType,
} from '@mui/x-date-pickers'
import { Button } from '@octup/core/ui-kit'
import { format } from 'date-fns'
import { buttonStyle } from './styles'

type ButtonFieldPropsType = BaseSingleInputPickersTextFieldPropsType<false> & {
    ownerState: DatePickerPropsType<Date>
}

export const ButtonField = ({
    id,
    disabled,
    InputProps,
    inputProps,
    ownerState,
}: ButtonFieldPropsType) => {
    const { value, loading, open, onOpen, format: dateFormat } = ownerState
    const label = useMemo(() => value && format(value, dateFormat!), [dateFormat, value])

    return (
        <Button
            id={id}
            data-testid="button-field"
            ref={InputProps?.ref}
            size="small"
            color="secondary"
            variant="secondary"
            onClick={onOpen}
            sx={buttonStyle}
            loading={loading}
            disabled={disabled}
            className={open ? 'opened' : ''}
            aria-label={inputProps?.['aria-label']}
            endIcon={<KeyboardArrowDownOutlined />}
        >
            {label}
        </Button>
    )
}
