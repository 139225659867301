import type { SearchTextFieldContainerPropsType } from '@octup/core/components'
import { SearchTextFieldContainer } from '@octup/core/components'
import { Stack } from '@octup/core/ui-kit'
import type { SystemPagePropsType } from '@/models/pages'
import { DashboardsList } from '@/new_components/Dashboards'
import { searchFieldStyle } from './styles'

export type SystemListPagePropsType = SystemPagePropsType & {
    searchValue?: SearchTextFieldContainerPropsType['value']
    onSearch?: SearchTextFieldContainerPropsType['onChange']
}

export const SystemListPage = ({
    data,
    dateRange,
    searchValue,
    onSearch,
}: SystemListPagePropsType) => (
    <Stack width="100%" spacing={5}>
        <Stack>
            <SearchTextFieldContainer
                fullWidth
                value={searchValue}
                onChange={onSearch}
                sx={searchFieldStyle}
                placeholder="Search by name"
            />
        </Stack>
        <DashboardsList data={data} dateRange={dateRange} />
    </Stack>
)
