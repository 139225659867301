import styled from 'styled-components'

const GMBOutterContainer = styled.div`
    border-radius: 0px 0px 1.6rem 1.6rem;
    position: absolute;
    top: ${(props) => (props.showGMB ? '0' : '-200vh')};
    transition: top 0.5s ease 0s; /* only transition top property */
    left: 2vw;
    height: 90vh;
    width: 96vw;
    z-index: 1500;
    background-color: #f6f5f9;
`

const GMBInnerContainer = styled.div`
    border-radius: 0px 0px 1.6rem 1.6rem;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const CloseGMBButtonContainer = styled.div`
    height: 4.8rem;
    width: 4.8rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #f6f5f9;
    bottom: 0;
    transform: translateY(50%);
    border-radius: 50%;
    padding: 3rem;
`
const GMBBody = styled.div`
    display: flex;
    flex-grow: 1;
    height: calc(100% - 23rem);
    width: 100%;
    border-radius: 0px 0px 1.6rem 1.6rem;
    flex-direction: column;
    padding: 2rem;
`

const GMBHeader = styled.div`
    max-height: 23rem;
    min-height: 23rem;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    background: url(GMBTopBg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 2rem;
`

const GMBTitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: flex-start;
    width: 100%;
`

const GMBTitleCloseBtn = styled.div`
    transform: translateY(-12.5%);
`

const GMBTitle = styled.div`
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.7rem;
    color: #504b5a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 1rem;
    /* position: absolute;
top: 0;
left: 0; */
    padding-bottom: 2rem;

    svg {
        margin-top: -0.6rem;
    }
`

const GMBMetrics = styled.div`
    height: 15rem;
    background: rgba(248, 247, 251, 0.9);
    box-shadow:
        -4px -4px 8px rgba(255, 255, 255, 0.75),
        8px 8px 24px rgba(80, 75, 90, 0.08);
    border-radius: 1.6rem;
    width: 100%;
    display: flex;
`

const GMBMetricItem = styled.div`
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    gap: 2rem;
`

const GMBMetricItemContainer = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-right: ${(props) => (props.showSeperator ? '1px solid #dddbe1' : 'none')};
`

const GMBMetricItemValue = styled.div`
    font-weight: 275;
    font-size: 4rem;
    line-height: 6rem;
    color: #504b5a;
`

const GMBMetricItemAdditionalData = styled.div`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    color: #7f7b87;
    text-transform: capitalize;
`

const GMBTableContainer = styled.div`
    padding-top: 2rem;
    padding-bottom: 0.5rem;
`

export {
    GMBTableContainer,
    GMBMetricItemAdditionalData,
    GMBMetricItemValue,
    GMBMetricItemContainer,
    GMBMetricItem,
    GMBMetrics,
    GMBTitle,
    GMBHeader,
    GMBBody,
    CloseGMBButtonContainer,
    GMBInnerContainer,
    GMBOutterContainer,
    GMBTitleContainer,
    GMBTitleCloseBtn,
}
