import { useEffect, useMemo, useState } from 'react'
import { Box, Stack, SvgIcon } from '@mui/material'
import { InfoIcon } from '@octup/core/assets/icons'
import styled from 'styled-components'
import { OctupPagination } from '@/components/Pagination/OctupPagination'
import { NoDataFallbackContainer } from '@/new_containers/components/__common__'
import { DownArrowSvg } from '@/shared/assets/icons'
import { octupDictionary } from '@/shared/dictionary/dictionary'
import { OctupTooltip } from '@/shared/ui/MUIWrappers/OctupTooltip'
import { compareStrings } from '@/shared/utils/strings-util'
import { resolveObjectPath } from '@/utils/resolveObjectPath'
import {
    getHeaderTitle,
    getHeaderField,
    flatData,
    getNestedValue,
    filterRows,
    sortTableRows,
} from './OctupBaseTable.utils'
import { OctupBaseTableBtnsRow } from './OctupBaseTableBtnsRow'
import { OctupBaseTableRow } from './OctupBaseTableRow'
import { OctupTableFilters } from './OctupTableFilters'
import { TableDataLoading } from './TableDataLoading'

export function OctupBaseTable({
    tableData = {
        rows: [],
        headers: [],
    },
    tableName = 'myTable',
    showDownloadCSV = false,
    showDatePicker = true,
    customStyle,
    renderRows,
    rowOnClick,
    renderSubRow,
    customRowStyle = null,
    customSubRowStyle = null,
    rowMenuActions = null,
    subRowMenuActions = null,
    defaultSortBy,
    showDotMenu = false,
    sortingHeadersToDataMapping = null,
    showCounter = true,
    customCounter,
    counterPostfix = null,
    counterPrefix = null,
    showFilters = true,
    showSelectedRow = true,
    requestedFilters,
    setSelectedRowData,
    searchFilterPlaceholder,
    showSearchFilter = true,
    searchFilterFields = null,
    tableRowClickHandler,
    isCollapsible = false,
    customContainerId,
    hiddenRowKey,
    isRowSelectable,
    isRowClickable,
    isSubRowClickable,
    customHeight,
    enableSubRowSelection,
    overrideRowKey,
    showPagination = false,
    count,
    page,
    setPage,
    isLoading,
    isRowsLoading,
    isRowDisabled,
    subRowField = 'subData',
    tooltipPath = 'tooltips',
    rowKeyField = 'id',
    subRowKeyField,
    unsortedRows = [],
    noSearchedDataFallbackProps,
    getDataValue,
    totalRows,
}) {
    const tableContainerEl = document.querySelector('#inner') // TO DO :replace with ref (!react BP)
    const tableOuterContainerEl = document.getElementById(customContainerId) // TO DO :replace with ref (!react BP)

    const [height, setHeight] = useState(0)
    const [csvData, setCsvData] = useState('')
    const [datePickerValue, setDatePickerValue] = useState(null)

    function handleResize() {
        let domRect = tableContainerEl?.getBoundingClientRect()
        let spaceBelow = 0
        if (tableOuterContainerEl) {
            let domRect2 = tableOuterContainerEl.getBoundingClientRect()
            spaceBelow = window.innerHeight - domRect2?.height
            setHeight(domRect?.height + spaceBelow - 25)
        } else {
            spaceBelow = window.innerHeight - domRect?.bottom
            setHeight(domRect?.height + spaceBelow - 25)
        }
    }

    window.addEventListener('resize', handleResize.bind(this))

    const [sortBy, setSortBy] = useState({
        ...defaultSortBy,
        direction: defaultSortBy?.direction || 1,
        unsortedRows: [...(defaultSortBy?.unsortedRows || []), ...unsortedRows],
    })

    const [tableRowsData, setTableRowsData] = useState(tableData.rows || [])
    const [tableHeadersData, setTableHeadersData] = useState(tableData.headers)
    const [activeFilters, setActiveFilters] = useState(null)
    const [activeFilter, setActiveFilter] = useState(null)
    const [searchFieldValue, setSearchFieldValue] = useState('')
    const [selectedItem, setSelectedItem] = useState(null)

    const dataMapping = sortingHeadersToDataMapping && sortingHeadersToDataMapping[sortBy.title]

    useEffect(() => {
        // TO DO :replace with ref (!react BP)
        if (tableContainerEl) {
            let domRect = tableContainerEl.getBoundingClientRect()
            let spaceBelow = window.innerHeight - domRect.bottom
            setHeight(domRect.height + spaceBelow - 25)
        }
    }, [tableContainerEl])

    useEffect(() => {
        showDownloadCSV &&
            showDatePicker &&
            setDatePickerValue(JSON.parse(sessionStorage.getItem('datepicker')))
    }, [showDatePicker, showDownloadCSV])

    useEffect(() => {
        setTableRowsData(tableData?.rows || [])
        setTableHeadersData(tableData?.headers || [])
    }, [tableData.rows, tableData.headers])

    useEffect(() => {
        activeFilter &&
            setActiveFilters((prevState) => {
                return {
                    ...prevState,
                    [`${activeFilter.type.toLowerCase()}`]: {
                        dataMapping: activeFilter.mapping,
                        selectedOptions: [...activeFilter.payload],
                    },
                }
            })
    }, [activeFilter])

    useEffect(() => {
        if (sortingHeadersToDataMapping) {
            const headers = Object.keys(sortingHeadersToDataMapping)
            const flattenedData = flatData({ data: tableRowsData, subRowField })
            const csvData = []

            flattenedData?.forEach((row, index) => {
                for (const header of headers) {
                    const currentField = sortingHeadersToDataMapping[header]
                    const field = getHeaderField({ data: row, currentField })

                    if (!field) return

                    if (sortingHeadersToDataMapping && !Array.isArray(field)) {
                        csvData[index] = {
                            ...csvData[index],
                            [header]: getDataValue?.({ row, field }) || row[field],
                        }
                    } else {
                        csvData[index] = {
                            ...csvData[index],
                            [header]: getNestedValue(row, field),
                        }
                    }
                }
            })
            setCsvData(csvData)
        }
    }, [tableRowsData])

    useEffect(() => {
        let tmp = tableData?.rows ? [...tableData.rows] : []
        for (const key in activeFilters) {
            tmp = tmp.filter((el) =>
                getNestedValue(el, activeFilters[key].dataMapping) &&
                activeFilters[key].selectedOptions.length > 0
                    ? activeFilters[key].selectedOptions.includes(
                          getNestedValue(el, activeFilters[key].dataMapping).toLowerCase()
                      )
                    : getNestedValue(el, activeFilters[key].dataMapping)
            )
        }
        setTableRowsData(tmp)
    }, [activeFilter, activeFilters, tableData.rows, searchFieldValue])

    useEffect(() => {
        if (sortBy.title) {
            setTableRowsData((prevState) =>
                sortTableRows({
                    rows: prevState,
                    sortBy,
                    dataMapping,
                    subRowField,
                })
            )
        }
    }, [dataMapping, sortBy, subRowField, tableData.rows])

    useEffect(() => {
        if (searchFieldValue.trim().length === 0) return

        const filteredRows = filterRows(
            tableData.rows,
            Object.values(searchFilterFields),
            searchFieldValue,
            subRowField,
            getDataValue
        )

        setTableRowsData(filteredRows)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFieldValue])

    const sortByHandler = (field) => {
        setSortBy((prevState) =>
            prevState.title !== field.toLowerCase()?.replace(/\s/g, '')
                ? { title: field?.replace(/\s/g, '').toLowerCase(), direction: 1, unsortedRows }
                : { ...prevState, direction: prevState.direction * -1, unsortedRows }
        )
    }

    const handleFilterSelection = (data) => {
        setActiveFilter(data)
    }

    const handleSearchFilterValue = (e) => {
        setSearchFieldValue(e.target.value)
    }

    const showNoSearchedDataFallback = useMemo(() => {
        const hasNoSearchedData = !tableRowsData.length && searchFieldValue
        return !isRowsLoading && noSearchedDataFallbackProps && hasNoSearchedData
    }, [isRowsLoading, noSearchedDataFallbackProps, searchFieldValue, tableRowsData.length])

    if (isLoading) {
        return <TableDataLoading />
    }

    return (
        <TableOuterContainer>
            {(showCounter || showSearchFilter || showDownloadCSV || showDotMenu) && (
                <TablePaneHeader className="tableHeader">
                    {showCounter && tableRowsData?.length >= 0 && (
                        <OctupTableCounter>
                            {customCounter || (
                                <>
                                    {counterPrefix && counterPrefix}
                                    {`Showing ${tableRowsData?.length} of ${
                                        totalRows || tableData?.rows?.length || 0
                                    }`}
                                    {counterPostfix && counterPostfix}
                                </>
                            )}
                        </OctupTableCounter>
                    )}
                    {(showSearchFilter || showDownloadCSV || showDotMenu) && (
                        <TablePanuHeaderRightSection>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <OctupBaseTableBtnsRow
                                    showDotMenu={showDotMenu}
                                    showDatePicker={showDatePicker}
                                    showSearchFilter={showSearchFilter}
                                    showDownloadCSV={showDownloadCSV}
                                    searchFilterPlaceholder={searchFilterPlaceholder}
                                    handleSearchFilterValue={handleSearchFilterValue}
                                    datePickerValue={datePickerValue}
                                    csvData={csvData}
                                    tableName={tableName}
                                />
                            </div>
                        </TablePanuHeaderRightSection>
                    )}
                </TablePaneHeader>
            )}
            <TableInnerContainer>
                <TableWrapper>
                    <DropdownFiltersContainer>
                        {showFilters && (
                            <OctupTableFilters
                                activeFilter={activeFilter}
                                activeFilters={activeFilters}
                                tableRowsData={tableRowsData}
                                tableRawData={tableData.rows}
                                requestedFilters={requestedFilters}
                                handleFilterSelection={handleFilterSelection}
                            />
                        )}
                    </DropdownFiltersContainer>

                    {showNoSearchedDataFallback ? (
                        <NoDataFallbackContainer {...noSearchedDataFallbackProps} />
                    ) : (
                        <OctupTableContainer
                            customStyle={customStyle}
                            className={'octup-tbl-container'}
                            id={'inner'}
                            style={{
                                maxHeight: customHeight || '100%',
                                height: customHeight,
                                minWidth: 'fit-content',
                            }}
                        >
                            <OctupBaseTableStyled>
                                <thead>
                                    <OctupBaseTableHeaders>
                                        {tableHeadersData &&
                                            tableHeadersData.map((header) => {
                                                const headerTitle = getHeaderTitle(header)
                                                const headerContent =
                                                    header?.renderHeader?.() || headerTitle

                                                return (
                                                    <OctupBaseTableHeader
                                                        key={headerTitle}
                                                        onClick={() =>
                                                            sortingHeadersToDataMapping &&
                                                            sortByHandler(headerTitle)
                                                        }
                                                    >
                                                        <HeaderTitleStyled>
                                                            {header?.tooltip ? (
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={1}
                                                                    alignItems="center"
                                                                >
                                                                    {header.showInfoIcon && (
                                                                        <Box>{headerContent}</Box>
                                                                    )}
                                                                    <OctupTooltip
                                                                        position="top"
                                                                        isContainerized={
                                                                            header.showInfoIcon
                                                                        }
                                                                        title={
                                                                            resolveObjectPath(
                                                                                octupDictionary,
                                                                                tooltipPath
                                                                            )[header.tooltip] ||
                                                                            header.tooltip
                                                                        }
                                                                    >
                                                                        {header.showInfoIcon ? (
                                                                            <SvgIcon
                                                                                component={InfoIcon}
                                                                                color="text.primary"
                                                                                fontSize="small"
                                                                            />
                                                                        ) : (
                                                                            <Box fontSize="inherit">
                                                                                {headerContent}
                                                                            </Box>
                                                                        )}
                                                                    </OctupTooltip>
                                                                </Stack>
                                                            ) : (
                                                                headerContent
                                                            )}

                                                            {sortingHeadersToDataMapping && (
                                                                <SortIconStyled
                                                                    isHeaderSortActivated={
                                                                        sortingHeadersToDataMapping &&
                                                                        sortBy.title &&
                                                                        compareStrings(
                                                                            sortBy.title,
                                                                            headerTitle
                                                                        )
                                                                    }
                                                                    isAscending={
                                                                        sortBy.direction === 1
                                                                    }
                                                                >
                                                                    <DownArrowSvg />
                                                                </SortIconStyled>
                                                            )}
                                                        </HeaderTitleStyled>
                                                    </OctupBaseTableHeader>
                                                )
                                            })}
                                    </OctupBaseTableHeaders>
                                </thead>
                                <OctupBaseTableBody>
                                    {isRowsLoading ? (
                                        <TableDataLoading
                                            rowStyle={customRowStyle}
                                            columns={tableHeadersData}
                                        />
                                    ) : (
                                        tableRowsData?.map((el, index) => {
                                            const rowId = el?.[rowKeyField]

                                            return (
                                                <OctupBaseTableRow
                                                    el={el}
                                                    key={rowId}
                                                    rowId={rowId}
                                                    rowIndex={index}
                                                    renderRows={renderRows}
                                                    rowOnClick={rowOnClick}
                                                    renderSubRow={renderSubRow}
                                                    showSelectedRow={showSelectedRow}
                                                    customRowStyle={customRowStyle}
                                                    customSubRowStyle={customSubRowStyle}
                                                    setSelectedRowData={setSelectedRowData}
                                                    tableRowClickHandler={tableRowClickHandler}
                                                    rowMenuActions={rowMenuActions}
                                                    subRowMenuActions={subRowMenuActions}
                                                    isCollapsible={isCollapsible}
                                                    setSelectedItem={setSelectedItem}
                                                    selectedItem={selectedItem}
                                                    hiddenRowKey={hiddenRowKey}
                                                    isRowSelectable={isRowSelectable}
                                                    enableSubRowSelection={enableSubRowSelection}
                                                    overrideRowKey={overrideRowKey}
                                                    isRowClickable={isRowClickable}
                                                    isRowDisabled={isRowDisabled}
                                                    isSubRowClickable={isSubRowClickable}
                                                    subRowField={subRowField}
                                                    subRowKeyField={subRowKeyField}
                                                    forceShowSubData={!!searchFieldValue}
                                                />
                                            )
                                        })
                                    )}
                                </OctupBaseTableBody>
                            </OctupBaseTableStyled>
                            {showPagination && (
                                <PaginationContainer>
                                    <OctupPagination count={count} page={page} setPage={setPage} />
                                </PaginationContainer>
                            )}
                        </OctupTableContainer>
                    )}
                </TableWrapper>
            </TableInnerContainer>
        </TableOuterContainer>
    )
}

const TableOuterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const TableInnerContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 2rem;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 2rem;
        border: 0.6rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }
`

const TableWrapper = styled.div`
    /* display: flex; */
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;

    .tbl-row-action-btns {
        opacity: 0;
    }
`

const OctupBaseTableStyled = styled.table`
    width: 100%;
    border-collapse: collapse;
    height: fit-content;
`

const OctupTableContainer = styled.div`
    height: 100%;
    overflow: auto;
`

const OctupBaseTableHeaders = styled.tr`
    height: 4.8rem;
    text-align: left;
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: #7f7b87;
    text-transform: capitalize;
`

const OctupBaseTableBody = styled.tbody`
    overflow-y: auto;
`

const OctupBaseTableHeader = styled.th`
    font-size: 1.3rem;
    margin: 0 auto;
    position: sticky;
    box-shadow:
        0 0 0 rgba(255, 255, 255, 0.75),
        inset 0 -0.2rem 0 rgba(80, 75, 90, 0.08);
    top: 0;
    background: #f8f7fb;
    z-index: 2;

    :first-child {
        padding-left: 1.5rem;
    }
`

const SortIconStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: ${(props) => (props.isHeaderSortActivated ? '1' : '0')};
    transform: ${(props) => (props.isAscending ? 'rotate(180deg)' : '')};
    transition: all 0.3s;
`

const HeaderTitleStyled = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    font-size: inherit;
    padding-right: 0.5rem;

    :hover {
        div {
            opacity: 1;
        }
    }
`

const OctupTableCounter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 5%;
    width: fit-content;
    white-space: nowrap;
    font-size: 1.7rem;
    line-height: 2rem;
    padding-left: 1rem;
    color: #504b5a;
    font-weight: 500;
`

const TablePaneHeader = styled.div`
    padding: 1rem 0 0.2rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

const TablePanuHeaderRightSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
`

const DropdownFiltersContainer = styled.div``

const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #ebeaee;
`
