import { useCallback, useEffect, useState } from 'react'
import { Stack, Typography } from '@octup/core/ui-kit'
import type { DatePickerPropsType } from '../DatePicker'
import { DatePicker } from '../DatePicker'
import type { DateRangePickerType } from './utils'
import { isDateRangeValid } from './utils'

export type DateRangePickerPropsType = Omit<DatePickerPropsType, 'onAccept' | 'value'> & {
    value: DateRangePickerType
    onAccept?: (dateRange: DateRangePickerType) => void
}

export const DateRangePicker = ({ value, onAccept, ...props }: DateRangePickerPropsType) => {
    const [dateRange, setDateRange] = useState(value)

    useEffect(() => {
        setDateRange(value)
    }, [value])

    const handleStartDateAccept = useCallback(
        (date: DatePickerPropsType['value']) => {
            const newDateRange = { ...dateRange, startDate: date }
            const isValid = isDateRangeValid(newDateRange)
            const endDate = isValid ? newDateRange.endDate : newDateRange.startDate
            onAccept?.({ ...newDateRange, endDate })
        },
        [dateRange, onAccept]
    )

    const handleEndDateAccept = useCallback(
        (date: DatePickerPropsType['value']) => onAccept?.({ ...dateRange, endDate: date }),
        [dateRange, onAccept]
    )

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <DatePicker value={dateRange.startDate} onAccept={handleStartDateAccept} {...props} />
            <Typography>&mdash;</Typography>
            <DatePicker
                value={dateRange.endDate}
                onAccept={handleEndDateAccept}
                minDate={dateRange.startDate ?? undefined}
                {...props}
            />
        </Stack>
    )
}
