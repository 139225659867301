import type { GridToolbarExportProps as GridToolbarExportPropsType } from '@mui/x-data-grid'
import {
    GridCsvExportMenuItem,
    GridToolbarContainer,
    GridToolbarExportContainer,
} from '@mui/x-data-grid'
import { Stack, Box, LinearProgress } from '@octup/core/ui-kit'
import type { DataGridToolbarSearchPropsType } from './DataGridToolbarSearch'
import { DataGridToolbarSearch } from './DataGridToolbarSearch'

export type DataGridToolbarPropsType = GridToolbarExportPropsType &
    DataGridToolbarSearchPropsType & {
        isLoading?: boolean
        hideToolbarSearch?: boolean
        hideToolbarExport?: boolean
    }

export const DataGridToolbar = ({
    isLoading,
    csvOptions,
    onSearchChange,
    hideToolbarSearch,
    hideToolbarExport,
}: DataGridToolbarPropsType) => (
    <GridToolbarContainer>
        <Stack width="100%" spacing={2} py={2}>
            <Stack width="100%" direction="row" justifyContent="flex-end" spacing={5}>
                {!hideToolbarExport && (
                    <GridToolbarExportContainer>
                        <GridCsvExportMenuItem options={csvOptions} />
                    </GridToolbarExportContainer>
                )}
                {!hideToolbarSearch && (
                    <Box>
                        <DataGridToolbarSearch onSearchChange={onSearchChange} />
                    </Box>
                )}
            </Stack>
            <LinearProgress isLoading={isLoading} />
        </Stack>
    </GridToolbarContainer>
)
