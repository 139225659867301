import { DATE_PICKER_VIEW_TYPES } from '@octup/core/models'
import type { DashboardsListType } from '@/models/dashboards'
import type { PageType } from './PageType'

export enum PAGE_VIEW_TYPES {
    LIST = 'list',
    FULL_PAGE = 'full-page',
}

export const PAGE_DATE_PICKER_TYPES = {
    NONE: 'none',
    DAILY: DATE_PICKER_VIEW_TYPES.DAILY,
    MONTHLY: DATE_PICKER_VIEW_TYPES.MONTHLY,
}

export type PAGE_DATE_PICKER_TYPES =
    (typeof PAGE_DATE_PICKER_TYPES)[keyof typeof PAGE_DATE_PICKER_TYPES]

export type PageSettingsType = Pick<PageType, 'name'> & {
    dashboards: DashboardsListType
    view: PAGE_VIEW_TYPES
    date_picker_type: PAGE_DATE_PICKER_TYPES
}

export type PageSettingsByIdType = Record<PageType['id'], PageSettingsType>
