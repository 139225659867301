import { HelpAndSupportMenuContainer } from '@/new_containers/components/HelpAndSupport'

export const SIDEBAR_FOOTER_LIST = [
    {
        id: 1,
        title: 'Help & Support',
        iconName: 'HelpOutline',
        Container: HelpAndSupportMenuContainer,
    },
]
