import { Box, TextField } from '@octup/core/ui-kit'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { formStyle } from './styles'

export const ClientSettingsForm = () => (
    <Box sx={formStyle}>
        <FormFieldContainer
            as={TextField}
            name="display_name"
            label="Display Name"
            fullWidth
            hasInlineError
        />
    </Box>
)
