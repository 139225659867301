import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { ButtonIcon } from '../Buttons/ButtonIcon'
import { OctupInput } from '../Inputs/Input/OctupInput'
import { DotsIconSvg, SearchIcon } from '../../assets/icons'
import { DownloadIconSvg } from '../../assets/icons/DownloadIconSvg'

export const OctupBaseTableBtnsRow = ({
    showDotMenu,
    showDatePicker,
    showSearchFilter,
    showDownloadCSV,
    searchFilterPlaceholder,
    handleSearchFilterValue,
    datePickerValue,
    csvData,
    tableName,
}) => {
    return (
        <TableButtonsRow>
            {showSearchFilter && (
                <SearchFieldContainer style={{ marginRight: showDotMenu === false ? '2rem' : 0 }}>
                    <OctupInput
                        leftIcon={SearchIcon}
                        type="search"
                        placeholder={searchFilterPlaceholder}
                        showClearField={true}
                        width="25rem"
                        customStyle={{
                            height: '4rem',
                        }}
                        onChange={(e) => {
                            handleSearchFilterValue(e)
                        }}
                    />
                </SearchFieldContainer>
            )}
            {showDownloadCSV && (
                <CSVLink
                    data={csvData}
                    filename={
                        tableName?.toLowerCase() +
                        '_table' +
                        (showDatePicker
                            ? '_from_' +
                              datePickerValue?.startDate +
                              '_to_' +
                              datePickerValue?.endDate
                            : '')
                    }
                >
                    <ButtonIcon
                        mainIcon={DownloadIconSvg}
                        colorScheme={'secondary'}
                        tooltipContent={'Download as CSV'}
                    />
                </CSVLink>
            )}
            {showDotMenu && <ButtonIcon mainIcon={DotsIconSvg} />}
        </TableButtonsRow>
    )
}

const TableButtonsRow = styled.div`
    display: flex;
`
const SearchFieldContainer = styled.div`
    width: fit-content;
    position: relative;
`
