import type {
    PatternFormatterPropsType,
    FORMATTER_DISPLAY_TYPES,
    PatternFormatterComponentType,
} from '@octup/core/models'
import { COMPONENT_BY_DISPLAY_TYPE } from './utils'

export const PatternFormatterContainer = ({
    config,
    ...props
}: PatternFormatterPropsType<FORMATTER_DISPLAY_TYPES>) => {
    const Component = COMPONENT_BY_DISPLAY_TYPE[config.display_type]
    const PatternFormatter = Component as PatternFormatterComponentType<FORMATTER_DISPLAY_TYPES>

    return <PatternFormatter config={config} {...props} />
}
