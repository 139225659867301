import { useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { getRoutes } from './routes.config'
import { useAppSelector } from './state/hooks'

export const Router = () => {
    const { data: sidebarPages } = useAppSelector((state) => state.pages.sidebarPages)
    const routes = useMemo(() => getRoutes(sidebarPages), [sidebarPages])
    const router = useMemo(() => createBrowserRouter(routes), [routes])

    return <RouterProvider router={router} />
}
