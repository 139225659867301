import styled from 'styled-components'
import { InviteSentBadge } from '@/shared/assets/icons/InviteSentBadge'
import { OctupAvatar } from '@/shared/ui/Avatar/OctupAvatar'
import { getShortTableDate } from '@/shared/utils/dates-util'
import { UserExtraOptions } from './UserExtraOptions'

export const UsersTableRow = ({ user, clientId }) => (
    <>
        <StyledTd>
            <OctupAvatar
                title={user?.fullName ? user?.fullName : user.email}
                imageSrc={user?.imageSrc}
                size={'large'}
                initialsSize={'5.2rem'}
            />
            <Span marginLeft={'-2rem'}>{user.fullName}</Span>
        </StyledTd>
        <StyledTd>
            <Span>{user.email}</Span>
        </StyledTd>
        <StyledTd>
            <Span color="#504B5A">{user?.role}</Span>
        </StyledTd>
        <StyledTd>
            <Span color="#504B5A">{getShortTableDate(user.created_at).primaryFormat}</Span>
        </StyledTd>
        <StyledTd>
            <RelativeContainer>
                <InviteSentContainer>{!user?.isActive && <InviteSentBadge />}</InviteSentContainer>
                <UserExtraOptions user={user} clientId={clientId} />
            </RelativeContainer>
        </StyledTd>
    </>
)

const Span = styled.div`
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px;
    color: ${(p) => p.color};
    margin-left: ${(p) => p.marginLeft};
`

const StyledTd = styled.td`
    height: 75px;
    text-transform: none !important;
`
const InviteSentContainer = styled.div`
    min-width: 15rem;
`

const RelativeContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
`
