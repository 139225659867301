import { NoDataFallbackContainer } from 'new_containers/components/__common__'
import { DashboardContainer } from 'new_containers/components/Logistics'
import { NoDataSvg } from 'shared/assets/images/__common__'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { NoWarehousesFallback } from '../NoWarehousesFallback'

export const PageContent = ({ hasLogistics, hasWarehouses, isLoading }) => {
    if (!hasWarehouses) {
        return <NoWarehousesFallback />
    }

    if (hasLogistics || isLoading) {
        return <DashboardContainer isLoading={isLoading} />
    }

    return (
        <NoDataFallbackContainer
            icon={<NoDataSvg />}
            title={octupDictionary.titles.logistics.noData}
            text={octupDictionary.messages.logistics.noData}
        />
    )
}
