import { Stack, Card } from '@octup/core/ui-kit'
import { CardFormContent } from '@/new_components/__common__/Form'
import {
    WorkingHoursSettingsFormContainer,
    OrderStatusFormContainer,
} from '@/new_containers/components/Clients'

const SLA_SETTINGS_GROUPS = [
    { title: 'Working Hours', FormContainer: WorkingHoursSettingsFormContainer },
    { title: 'Order Status Definitions', FormContainer: OrderStatusFormContainer },
]

export const SLASettings = () => (
    <Stack width="100%" spacing={5}>
        {SLA_SETTINGS_GROUPS.map(({ title, FormContainer }) => (
            <Card key={title} title={title}>
                <FormContainer FormContent={CardFormContent} />
            </Card>
        ))}
    </Stack>
)
