import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PAGE_MENU_TYPES } from '@/models/pages'
import type { MenuPagesType, PageType } from '@/models/pages'
import { getPagesByMenuType } from '@/services/pages'
import type { ThunkAPIConfigType } from '@/services/utils'

type AppExcludedFeaturesStateType = {
    data: PageType[]
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: AppExcludedFeaturesStateType = {
    data: [],
    isLoading: false,
}

export const fetchSidebarPages = createAsyncThunk<MenuPagesType, undefined, ThunkAPIConfigType>(
    'fetchSidebarPages',
    async () => getPagesByMenuType(PAGE_MENU_TYPES.SIDEBAR)
)

export const sidebarPagesSlice = createSlice({
    name: 'sidebarPages',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSidebarPages.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchSidebarPages.fulfilled, (state, action) => {
                state.data = action.payload.menu_items
                state.isLoading = false
            })
            .addCase(fetchSidebarPages.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const sidebarPagesReducer = sidebarPagesSlice.reducer
