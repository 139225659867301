import { LinearProgress, type LinearProgressProps as LinearProgressPropsType } from '@mui/material'
import { Stack, Box, Typography } from '..'

type LabeledLinearProgressPropsType = LinearProgressPropsType & { value: number }

export const LabeledLinearProgress = (props: LabeledLinearProgressPropsType) => (
    <Stack direction="row" alignItems="center" spacing={2}>
        <Box width="100%">
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
    </Stack>
)
