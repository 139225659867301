import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { metricsService } from './metricService'
import { GLOBAL_CONFIG } from '../../config'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../shared/dictionary/dictionary'
import { extractErrMsg } from '../../shared/utils/utils'

export const METRICS_API_URL = GLOBAL_CONFIG.DAL_BASE_URL + 'metrics'

export const SEGMENTS = {
    SYS: 0,
    HOME: 1,
    MARKETING: 2,
    OPERATIONS: 3,
    GRAPH: 4,
    ALTERNATIVE_HOME_METRICS: 5,
    ALTERNATIVE_HOME_YESTERDAY: 6,
    ALTERNATIVE_HOME_OPERATIONS: 7,
    ALTERNATIVE_HOME_MARKETING: 8,
    GMB_TOP: 9,
    GMB_TABLE: 10,
    DEEPDIVE_AOV: 18,
    DEEPDIVE_SALES: 19,
    DEEPDIVE_LOGISTICS: 20,
    DEEPDIVE_RETURNS: 21,
    DEEPDIVE_AD_SPEND: 22,
    DEEPDIVE_ORDER_METRICS: 23,
    DEEPDIVE_AVG_ITEMS_PER_ORDER: 24,
    DEEPDIVE_RETURNING_CUSTOMERS: 25,
    DEEPDIVE_ROAS: 26,
    DEEPDIVE_INCOME_FROM_PPC_SOURCES: 30,
    GMB_AD_SPEND_SUBSECTIONS: 32,
    GMB_INCOME_PPC_SUBSECTIONS: 33,
    MARKERING_AD_SPEND_BY_CHANNELS: 34,
    OPERATIONS_CUSTOMER_SUPPORT: 35,
    OPERATIONS_FULLFILLED_ORDERS: 36,
    MARKERING_ROAS: 37,
    MARKERING_AD_SPEND_BY_CHANNELS_GRAPH: 42,
}

export const METRICS_TO_DEEP_DIVE = {
    average_gross_order_value_usd: SEGMENTS.DEEPDIVE_AOV,
    sales: SEGMENTS.DEEPDIVE_SALES,
    logistics_cost: SEGMENTS.DEEPDIVE_LOGISTICS,
    refunds_shopify: SEGMENTS.DEEPDIVE_RETURNS,
    total_ad_spend: SEGMENTS.DEEPDIVE_AD_SPEND,
    order_number_gross: SEGMENTS.DEEPDIVE_ORDER_METRICS,
    avg_number_items_per_order: SEGMENTS.DEEPDIVE_AVG_ITEMS_PER_ORDER,
    returning_customers_percentage: SEGMENTS.DEEPDIVE_RETURNING_CUSTOMERS,
    // roas: SEGMENTS.DEEPDIVE_ROAS,
    // dd:SEGMENTS.DEEPDIVE_INCOME_FROM_PPC_SOURCES
}

export const DEEP_DIVE_TO_METRICS = {
    [SEGMENTS.DEEPDIVE_AOV]: 'average_gross_order_value_usd',
    [SEGMENTS.DEEPDIVE_SALES]: 'sales',
    [SEGMENTS.DEEPDIVE_LOGISTICS]: 'logistics_cost',
    [SEGMENTS.DEEPDIVE_RETURNS]: 'refunds_shopify',
    [SEGMENTS.DEEPDIVE_AD_SPEND]: 'total_ad_spend',
    [SEGMENTS.DEEPDIVE_ORDER_METRICS]: 'order_number_gross',
    [SEGMENTS.DEEPDIVE_AVG_ITEMS_PER_ORDER]: 'avg_number_items_per_order',
    [SEGMENTS.DEEPDIVE_RETURNING_CUSTOMERS]: 'returning_customers_percentage',
}

export const VALUE_TYPE = {
    FLOAT: 1,
    PERCENTAGE: 2,
    INT: 3,
}

const METRICS_INITIAL_STATE = {
    deepdives: {},
    ordersFulfilled: null,
    customerSuccess: [],
    marketingROAS: { metrics: [], graph: null },
}

const initialState = {
    metrics: METRICS_INITIAL_STATE,
    isCustomerSuccessLoading: false,
    tree: [],
    isLoadingTree: false,
    singleOrder: null,
    singleOrderIsLoading: false,
    singleOrderError: null,
    isLoading: false,
    showShimmer: false,
    isRejected: false,
    isExpended: false,
    isSlaConfigLoading: false,
}

export const getMetricsBySegment = createAsyncThunk(
    'getMetricsBySegment',

    async (requestData, thunkAPI) => {
        const token = thunkAPI.getState().auth.userInfo.token
        try {
            return thunkAPI.fulfillWithValue(
                await metricsService.getMetricsBySegments({ ...requestData, token })
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const getOrdersSLAStatus = createAsyncThunk(
    'getOrdersSLAStatus',
    async (requestData, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(await metricsService.getOrdersSLAStatus(requestData))
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const getSingleOrder = createAsyncThunk('getSingleOrder', async (requestData, thunkAPI) => {
    try {
        return thunkAPI.fulfillWithValue(await metricsService.getSingleOrder(requestData))
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrMsg(error))
    }
})

export const updateCustomDashboardMetrics = createAsyncThunk(
    'updateCustomDashboardMetrics',
    async (data, thunkAPI) => {
        try {
            console.log('FIND ME DATA', data)
            return thunkAPI.fulfillWithValue(
                await metricsService.updateCustomDashboardMetrics({ data })
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const metricsSlice = createSlice({
    name: 'metrics',
    initialState,
    reducers: {
        setIsExpended(state, action) {
            state.isExpended = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSingleOrder.pending, (state) => {
                state.singleOrderIsLoading = true
                state.singleOrder = null
            })
            .addCase(getSingleOrder.fulfilled, (state, action) => {
                state.singleOrder = action.payload
                state.singleOrderIsLoading = false
            })
            .addCase(getSingleOrder.rejected, (state, action) => {
                state.singleOrderError = action.error.message
                state.singleOrderIsLoading = false
            })
            .addCase(getMetricsBySegment.pending, (state) => {
                state.isLoading = true
                state.isCustomerSuccessLoading = true
                state.isRejected = false
            })
            .addCase(getMetricsBySegment.fulfilled, (state, action) => {
                let responsePayload =
                    typeof action.payload === 'object'
                        ? action.payload
                        : action.payload
                          ? JSON.parse(action.payload.replace('Infinity', null))
                          : []
                try {
                    for (const el in action.payload) {
                        if (+el === 36) {
                            let tmp = {}

                            for (const item of responsePayload[el]) {
                                for (const el of item.dataset) {
                                    if (tmp[el[1]]) {
                                        tmp[el[1]] += el[0]
                                    } else {
                                        tmp[el[1]] = el[0]
                                    }
                                }
                            }

                            state.metrics.ordersFulfilled = {
                                original_object: tmp,
                                dates: Object.keys(tmp),
                                values: Object.values(tmp),
                            }
                        } else if (+el === 35) {
                            let ans = []
                            let tickets_created = responsePayload[el].find(
                                (el) => el.metric.metricTitle === 'Number of tickets created'
                            )
                            let tickets_closed = responsePayload[el].find(
                                (el) => el.metric.metricTitle === 'Number of tickets closed'
                            )
                            let dates = Array.from(
                                new Set(
                                    tickets_closed?.dataset
                                        .map((el) => el[1])
                                        .concat(tickets_created?.dataset.map((el) => el[1]))
                                )
                            )

                            for (const metric of responsePayload[el]) {
                                if (
                                    metric?.metric?.metricTitle === 'Number of tickets created' ||
                                    metric?.metric?.metricTitle === 'Number of tickets closed'
                                ) {
                                    let tmp = metric.dataset
                                    for (const date of dates) {
                                        if (!metric.dataset.map((el) => el[1]).includes(date)) {
                                            tmp.push([null, date])
                                        }
                                    }
                                    metric.adjustedDataset = tmp.sort((a, b) =>
                                        new Date(a[1]) - new Date(b[1]) > 0 ? 1 : -1
                                    )
                                }

                                ans = [
                                    ...ans,
                                    {
                                        title: metric?.metric?.metricTitle,
                                        sources: metric?.metric?.sources,
                                        supported: metric?.metric?.supported,
                                        metricAlias: getDictionaryValue(
                                            metric?.metric?.metricTitle + '_cs',
                                            DICTIONARY_SECTIONS.METRICS_TITLES
                                        ),
                                        value:
                                            metric?.dataset?.length > 0 &&
                                            metric?.metric?.metricTitle !==
                                                'Number of tickets replied'
                                                ? metric?.metric?.value
                                                : [],
                                        dataset:
                                            metric?.metric?.metricTitle ===
                                                'Number of tickets created' ||
                                            metric?.metric?.metricTitle ===
                                                'Number of tickets closed'
                                                ? metric?.adjustedDataset
                                                : null,
                                        info: null,
                                    },
                                ]
                            }
                            state.metrics.customerSuccess = [...ans]
                            state.isCustomerSuccessLoading = false
                        } else if (+el === 37) {
                            let tmp = {}

                            for (const item of responsePayload[el]) {
                                // for (const el of item.dataset) {
                                //   if (tmp[el[1]]) {
                                //     tmp[el[1]] += el[0];
                                //   } else {
                                //     tmp[el[1]] = el[0];
                                //   }
                                // }

                                if (item.metric?.metricTitle === 'roas') {
                                    state.metrics.marketingROAS.metrics = [
                                        ...state.metrics.marketingROAS.metrics,
                                        item.metric,
                                    ]
                                }
                            }
                        }
                        // if (+el === 5) console.log("SEGEMETS_5", responsePayload[el]);
                        state.metrics[el] = responsePayload[el]
                    }
                } catch (e) {
                    console.error('error', e)
                } finally {
                    state.isLoading = false
                }
            })
            .addCase(getMetricsBySegment.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getOrdersSLAStatus.pending, (state) => {
                state.isSlaConfigLoading = true
                state.isRejected = false
            })
            .addCase(getOrdersSLAStatus.fulfilled, (state, action) => {
                state.slaConfig = action.payload
                state.isSlaConfigLoading = false
            })
            .addCase(getOrdersSLAStatus.rejected, (state) => {
                state.isSlaConfigLoading = false
                state.isRejected = true
            })
    },
})

export const metricsActions = metricsSlice.actions
export const metricsState = (state) => state.metrics
export const metricsSliceReducer = metricsSlice.reducer
