import type { MetricsSectionType } from '@octup/core/models'
import axios from 'axios'
import type { ThunkAPIType } from '@/services/utils'
import { generateAPIConfig } from '@/services/utils'
import { getMetricsSectionById } from './metricsSectionsService'
import type { CustomTableMetricsSectionDataParamsType, GetMetricsSectionType } from './utils'
import { SECTIONS_API_URL } from './utils'

export const getCustomTableConfig = async (
    id: MetricsSectionType['id'],
    thunkAPI: ThunkAPIType
) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(`${SECTIONS_API_URL}/${id}/custom-table/config`, config)
    return response.data
}

export const getCustomTableData = async (
    data: CustomTableMetricsSectionDataParamsType,
    thunkAPI: ThunkAPIType
) => {
    const params = {
        start_date: data.startDate,
        end_date: data.endDate,
        client_id: data.clientId,
        page_number: data.page,
        page_size: data.pageSize,
        sort_by: data.sortBy,
        sort_descending: data.sortDescending,
        search: data.search,
    }
    const config = generateAPIConfig({ thunkAPI, params })
    const response = await axios.get(`${SECTIONS_API_URL}/${data.id}/custom-table/data`, config)
    return response.data
}

export const getCustomTableMetricsSectionById: GetMetricsSectionType = async (data, thunkAPI) => {
    const sectionData = await getMetricsSectionById(data, thunkAPI)
    if (!sectionData.hasContent) return sectionData

    const tableConfig = await getCustomTableConfig(data.id, thunkAPI)
    const customTableSectionData = { ...sectionData.data, table: { config: tableConfig } }
    return { ...sectionData, data: customTableSectionData }
}
