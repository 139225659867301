import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@octup/core/ui-kit'
import enUS from 'date-fns/locale/en-US'
import { DateRangePicker } from 'react-date-range'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { DATE_OPTIONS, datePickerActions } from '@/features/datePicker/datePickerSlice'
import { CheckmarkSvg } from '@/shared/assets/icons'
import { OctupColors } from '@/shared/theme/colors/OctupColors'
import { WEEK_START, getFormattedDate, getStaticRanges, getDateRange } from './utils'

const btnStyle = {
    fontWeight: 400,
    fontSize: '1.3rem',
}
export const OctupDateRangePicker = ({
    show,
    dateLabel,
    setLabel,
    setLabelDates,
    setShowDatePicker,
    direction = 'horizontal',
    month = 2,
    hideStaticDates = false,
    defaultSelectionLabel = JSON.parse(sessionStorage.getItem('datepicker')),
    showCalendar = true,
    customStaticRanges,
    onChange,
}) => {
    const dispatch = useDispatch()
    const [datesRange, setDatesRange] = useState(getDateRange(defaultSelectionLabel))
    const [selectedItem, setSelectedItem] = useState(getFormattedDate(datesRange))

    const staticRanges = useMemo(
        () => getStaticRanges({ hideStaticDates, customStaticRanges }),
        [customStaticRanges, hideStaticDates]
    )

    const handleChangeSelected = useCallback(
        (newDateRange) => {
            if (onChange) return onChange(newDateRange)
            return dispatch(datePickerActions.changeDates(newDateRange))
        },
        [dispatch, onChange]
    )

    const handleSelect = useCallback(
        ({ newDateRange = datesRange }) => {
            setSelectedItem(newDateRange)
            handleChangeSelected(getFormattedDate(newDateRange))
            setLabel(newDateRange[0].label)
            setLabelDates(newDateRange[0])
            setShowDatePicker(false)
        },
        [datesRange, handleChangeSelected, setLabel, setLabelDates, setShowDatePicker]
    )

    const handleDismissDatePicker = () => {
        setDatesRange(selectedItem)
        setShowDatePicker(false)
    }

    const handleChange = useCallback(
        ({ selection }) => {
            const selected = staticRanges.find((staticRange) => staticRange.isSelected(selection))
            const label = selected?.label || DATE_OPTIONS.CUSTOM
            const newDateRange = [{ ...selection, label }]
            setDatesRange(newDateRange)

            if (!showCalendar) {
                handleSelect({ newDateRange })
            }
        },
        [handleSelect, showCalendar, staticRanges]
    )

    useEffect(() => {
        if (customStaticRanges && !customStaticRanges.includes(dateLabel)) {
            const [newDateRange] = getDateRange({ label: customStaticRanges[0] })
            handleChange({ selection: newDateRange })
        }
    }, [customStaticRanges, dateLabel, handleChange])

    useEffect(() => {
        setSelectedItem(datesRange)
        setLabelDates(datesRange[0])
    }, [])

    return (
        <>
            {show && (
                <Container hideStaticDates={hideStaticDates} showCalendar={showCalendar}>
                    <DateRangePicker
                        onChange={handleChange}
                        locale={{
                            ...enUS,
                            options: { firstWeekContainsDate: 1, weekStartsOn: WEEK_START },
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={true}
                        months={month}
                        weekStartsOn={WEEK_START}
                        ranges={datesRange}
                        maxDate={new Date()}
                        disableFuture={true}
                        direction={direction}
                        staticRanges={staticRanges}
                        renderStaticRangeLabel={(range) => (
                            <>
                                {range.label}
                                {!showCalendar && range.isSelected(datesRange[0]) && (
                                    <CheckmarkSvg
                                        customColor={OctupColors.primaryActionableLight}
                                    />
                                )}
                            </>
                        )}
                    />
                    {showCalendar && (
                        <ButtonsContainer hideStaticDates={hideStaticDates}>
                            <Button
                                sx={btnStyle}
                                onClick={handleDismissDatePicker}
                                variant={'text'}
                                size={'small'}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={btnStyle}
                                onClick={handleSelect}
                                variant={'contained'}
                                size={'small'}
                            >
                                Apply
                            </Button>
                        </ButtonsContainer>
                    )}
                </Container>
            )}
        </>
    )
}

const Container = styled.div`
    padding-bottom: ${(p) => (p.hideStaticDates ? '10%' : '')};
    background-color: white;
    border-radius: 0.8rem;
    overflow: hidden;

    .rdrDefinedRangesWrapper {
        display: ${(p) => (p.hideStaticDates ? 'none' : '')};
        width: ${(p) => (!p.showCalendar ? '16rem' : '')};
    }

    .rdrCalendarWrapper {
        font-size: 1rem;
        display: ${(p) => (!p.showCalendar ? 'none' : '')};
    }
`

const ButtonsContainer = styled.div`
    position: absolute;
    bottom: 1rem;
    right: ${(p) => (p.hideStaticDates ? 'auto' : '1rem')};
    display: flex;
    gap: 1rem;
`
