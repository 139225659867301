import { useEffect } from 'react'
import { OverflowTypography } from '@octup/core/ui-kit'
import { format, addDays } from 'date-fns'
import { useSelector } from 'react-redux'
import { NoDataError } from '../NoDataError/NoDataError'
import { GMBTable } from './components/GMBTable'
import {
    GMBTableContainer,
    GMBMetricItemAdditionalData,
    GMBMetricItemValue,
    GMBMetricItemContainer,
    GMBMetricItem,
    GMBMetrics,
    GMBBody,
    GMBInnerContainer,
} from './GoodMorningBoard.styles'
import { SEGMENTS } from '../../features/metrics/metricsSlice'
import { useOctupDispatch } from '../../hooks/useOctupDispatch'
import { getFormattedVal } from '../../shared/utils/metrics-utils'

const YESTERDAY_DATE = format(addDays(new Date(), -1), 'yyyy-MM-dd')
const TODAY_DATE = format(new Date(), 'yyyy-MM-dd')
const WEEK_BEFORE = format(addDays(new Date(), -7), 'yyyy-MM-dd')

const TODAY_DATES = {
    startDate: TODAY_DATE,
    endDate: TODAY_DATE,
}

const WEEK_DATES = {
    startDate: WEEK_BEFORE,
    endDate: YESTERDAY_DATE,
}

export const GoodMorningBoard = () => {
    const octupDispatch = useOctupDispatch()
    const { metrics } = useSelector((state) => state.metrics)
    const overviewMetrics = metrics[SEGMENTS.GMB_TOP]
    const tableMetrics = metrics[SEGMENTS.GMB_TABLE]

    useEffect(() => {
        if (!overviewMetrics) {
            octupDispatch.metrics.getMetricsBySegment([SEGMENTS.GMB_TOP], TODAY_DATES, 1)
        }
        if (!tableMetrics) {
            octupDispatch.metrics.getMetricsBySegment(
                [
                    SEGMENTS.GMB_TABLE,
                    SEGMENTS.GMB_AD_SPEND_SUBSECTIONS,
                    SEGMENTS.GMB_INCOME_PPC_SUBSECTIONS,
                ],
                WEEK_DATES,
                1
            )
        }
    }, [octupDispatch.metrics, overviewMetrics, tableMetrics])

    return (
        <>
            <GMBInnerContainer>
                <GMBMetrics>
                    {overviewMetrics?.map((el, index) => (
                        <GMBMetricItem key={el?.metric?.metricTitle}>
                            <GMBMetricItemContainer
                                showSeperator={index < overviewMetrics.length - 1}
                            >
                                <OverflowTypography>
                                    Today's {el?.metric?.metricTitle}
                                </OverflowTypography>
                                <GMBMetricItemValue>
                                    {getFormattedVal({
                                        val: el?.metric?.value || 0,
                                        valueType: el?.metric?.valueUnits,
                                        customNumOfDigits: null,
                                        currency: el?.metric?.currency,
                                        isSupported: el?.metric?.supported,
                                        showSymbol: true,
                                    })}
                                </GMBMetricItemValue>
                                <GMBMetricItemAdditionalData>
                                    {!el?.metric?.supported && <NoDataError />}
                                </GMBMetricItemAdditionalData>
                            </GMBMetricItemContainer>
                        </GMBMetricItem>
                    ))}
                </GMBMetrics>
                <GMBBody>
                    <GMBTableContainer id={'GMBody'}>
                        <GMBTable customContainerId={'GMBody'} tableRawData={tableMetrics} />
                    </GMBTableContainer>
                </GMBBody>
            </GMBInnerContainer>
        </>
    )
}
