import type { MetricsSectionPropsType } from '@octup/core/models'
import type { DataGridPropsType } from '@octup/core/ui-kit'
import { Grid, DataGrid } from '@octup/core/ui-kit'
import { LoadableContent } from '@/new_components'
import { MetricsSectionCard } from '../__common__'
import { tableContainerStyle } from './styles'

type TableMetricsSectionPropsType = Omit<MetricsSectionPropsType, 'data'> & {
    tableProps: DataGridPropsType
}

export const TableMetricsSection = ({
    tableProps,
    isLoading,
    isDataUpdating,
    ...props
}: TableMetricsSectionPropsType) => (
    <MetricsSectionCard
        showFullScreenButton
        height={80}
        minHeight={40}
        isLoading={isLoading}
        {...props}
    >
        <Grid container direction="column">
            <LoadableContent isLoading={isLoading}>
                <Grid item xs container sx={tableContainerStyle}>
                    <DataGrid isLoading={isDataUpdating} density="compact" {...tableProps} />
                </Grid>
            </LoadableContent>
        </Grid>
    </MetricsSectionCard>
)
