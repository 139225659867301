import type { PaletteModeType } from '@octup/core/theme'
import { ThemeProvider } from '@octup/core/theme'
import { DashboardLayout } from '@octup/core/ui-kit'
import { Outlet } from 'react-router-dom'
import Header from '@/components/Layout/Header/Header'
import { ErrorMessageFallback } from '@/new_components'
import {
    ErrorBoundaryContainer,
    ExpiredModalContainer,
    PaymentModalContainer,
} from '@/new_containers/components/__common__'
import { createTheme } from '@/theme'
import { SidebarFooter } from './SidebarFooter'
import { dashboardStyle } from './styles'

const SIDEBAR_EXPANDED_WIDTH = 220

export type MainLayoutPropsType = {
    error?: string
    paletteMode: PaletteModeType
}

export const MainLayout = ({ error, paletteMode }: MainLayoutPropsType) => (
    <ThemeProvider theme={createTheme(paletteMode)}>
        <DashboardLayout
            disableCollapsibleSidebar
            sidebarExpandedWidth={SIDEBAR_EXPANDED_WIDTH}
            slots={{ toolbarActions: Header, sidebarFooter: SidebarFooter }}
            sx={dashboardStyle}
        >
            <ThemeProvider theme={createTheme('light')}>
                <ErrorBoundaryContainer>
                    {error ? <ErrorMessageFallback /> : <Outlet />}
                </ErrorBoundaryContainer>
                <ExpiredModalContainer />
                <PaymentModalContainer />
            </ThemeProvider>
        </DashboardLayout>
    </ThemeProvider>
)
