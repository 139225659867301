import type { MouseEvent } from 'react'
import { useCallback, useState } from 'react'
import { Box } from '@octup/core/ui-kit'
import { ExternalMenu } from '@/components/ExternalMenu'
import { MENU_CONFIG } from './utils'

export type HelpAndSupportMenuContainerPropsType = {
    children: React.ReactNode
}

export const HelpAndSupportMenuContainer = ({ children }: HelpAndSupportMenuContainerPropsType) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const handleClose = useCallback(() => setAnchorEl(null), [])
    const handleOpen = useCallback(
        (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget),
        []
    )

    return (
        <>
            <Box onClick={handleOpen}>{children}</Box>
            <ExternalMenu anchorEl={anchorEl} onClose={handleClose} config={MENU_CONFIG} />
        </>
    )
}
