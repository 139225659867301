import { Grid2, Stack, Typography } from '@octup/core/ui-kit'
import { FILTERS } from './utils'

export const LogisticsFilters = () => (
    <Grid2 container spacing={8}>
        {FILTERS.map(({ id, name, component: Component }) => (
            <Stack key={id} direction="row" alignItems="center" spacing={2}>
                <Typography>{name}:</Typography>
                <Component />
            </Stack>
        ))}
    </Grid2>
)
