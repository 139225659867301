import { forwardRef } from 'react'
import { useToggle } from '@octup/core/hooks'
import type { DialogPropsType } from './Dialog'
import { Dialog } from './Dialog'

export const DialogContainer = forwardRef<HTMLDivElement, DialogPropsType>(
    ({ fullScreen, ...props }, ref) => {
        const fullScreenToggle = useToggle(fullScreen)
        const { isVisible: isFullScreenOpen, toggleVisibility: toggleFullScreen } = fullScreenToggle

        return (
            <Dialog
                ref={ref}
                fullScreen={isFullScreenOpen}
                toggleFullScreen={toggleFullScreen}
                {...props}
            />
        )
    }
)
