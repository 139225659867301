import { forwardRef } from 'react'
import type { DataGridProps as MuiDataGridPropsType } from '@mui/x-data-grid'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import type { DataGridToolbarPropsType } from './DataGridToolbar'
import { DataGridToolbar } from './DataGridToolbar'

declare module '@mui/x-data-grid' {
    interface ToolbarPropsOverrides extends DataGridToolbarPropsType {}
}

const LoadingOverlay = () => null
const LOCALE_TEXT = { toolbarExportCSV: 'CSV' }

export type DataGridPropsType = Omit<MuiDataGridPropsType, 'loading'> &
    Partial<DataGridToolbarPropsType> & { hideToolbar?: boolean }

export const DataGrid = forwardRef<HTMLDivElement, DataGridPropsType>(
    (
        {
            slotProps,
            isLoading,
            hideToolbar,
            onSearchChange,
            hideToolbarSearch,
            hideToolbarExport,
            ...props
        },
        ref
    ) => (
        <MuiDataGrid
            {...props}
            ref={ref}
            loading={isLoading}
            localeText={LOCALE_TEXT}
            slots={{
                loadingOverlay: LoadingOverlay,
                ...(!hideToolbar && { toolbar: DataGridToolbar }),
            }}
            slotProps={{
                ...slotProps,
                toolbar: {
                    isLoading,
                    onSearchChange,
                    hideToolbarSearch,
                    hideToolbarExport,
                    ...slotProps?.toolbar,
                },
            }}
        />
    )
)
