import { useMemo, useCallback, useEffect } from 'react'
import { DATE_FORMAT_BY_VIEW_TYPE } from '@octup/core/constants'
import type { DatePickerPropsType } from '@octup/core/ui-kit'
import { DatePicker } from '@octup/core/ui-kit'
import { format, parseISO } from 'date-fns'
import { useQueryString } from 'hooks'

const DEFAULT_VALUE = new Date()

export const DatePickerQueryContainer = ({ viewType, ...props }: DatePickerPropsType) => {
    const { queryParams, setQueryParams } = useQueryString()
    const dateFormat = DATE_FORMAT_BY_VIEW_TYPE[viewType]
    const { date } = queryParams

    const value = useMemo(() => (date ? parseISO(date) : DEFAULT_VALUE), [date])

    const getFormattedDate = useCallback(
        (date?: Date | null) => date && format(date, dateFormat),
        [dateFormat]
    )

    useEffect(() => {
        if (!date) {
            setQueryParams({ date: getFormattedDate(DEFAULT_VALUE) })
        }
    }, [getFormattedDate, queryParams, setQueryParams, date])

    const handleChange = useCallback(
        (date?: Date | null) => setQueryParams({ date: getFormattedDate(date) }),
        [getFormattedDate, setQueryParams]
    )

    return <DatePicker viewType={viewType} onAccept={handleChange} value={value} {...props} />
}
