import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ErrorMessageFallback, LoadingView } from '@/new_components/__common__'
import { CustomerDetails } from './CustomerDetails'
import { JourneyItem } from './JourneyItem'
import { OrderItems } from './OrderItems'
import { PaymentsData } from './PaymentsData'
import {
    SingleOrderMainContainer,
    TopContainer,
    BottomContainer,
    PaymentsContainer,
} from './SingleOrderDetails.styles'
import { TopContainerItems } from './TopContainerItems'
import { getItemsLength } from './util'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { CartIcon, CreditCardIcon } from '../../../shared/assets/icons'

const MAX_ITEMS = 3

export const SingleOrderDeepDive = ({ orderId }) => {
    const octupDispatch = useOctupDispatch()
    const {
        singleOrder,
        singleOrderIsLoading: isLoading,
        singleOrderError: error,
    } = useSelector((state) => state.metrics)
    const items = singleOrder?.data?.order?.lineItems?.nodes
    const singleOrderItems = items?.slice(0, MAX_ITEMS)
    const singleOrderTpItems = items?.slice(
        MAX_ITEMS,
        singleOrder?.data?.order?.lineItems?.nodes?.length
    )
    const reversedNodes = singleOrder?.data?.order?.events?.nodes?.slice().reverse()

    useEffect(() => {
        octupDispatch.operations.getSingleOrder({ itemId: orderId })
    }, [octupDispatch.operations, orderId])

    const singleOrderTopItems = [
        {
            container: PaymentsContainer,
            title: {
                icon: CartIcon,
                text: `Items (${getItemsLength(items)})`,
            },
            content: (
                <OrderItems
                    items={items}
                    singleOrder={singleOrder}
                    singleOrderItems={singleOrderItems}
                    singleOrderTpItems={singleOrderTpItems}
                />
            ),
        },
        {
            container: PaymentsContainer,
            title: {
                icon: CreditCardIcon,
                text: `Payments`,
            },
            content: (
                <PaymentsData
                    getItemsLength={getItemsLength}
                    items={items}
                    singleOrder={singleOrder}
                />
            ),
        },
    ]

    if (isLoading) return <LoadingView />
    if (error) return <ErrorMessageFallback />

    return (
        <SingleOrderMainContainer>
            <TopContainer>
                {singleOrderTopItems.map((el, index) => (
                    <TopContainerItems
                        {...el}
                        key={el.title.text}
                        showVerticalDivider={index === 0}
                    />
                ))}
            </TopContainer>
            <BottomContainer>
                <JourneyItem reversedNodes={reversedNodes} />
                <CustomerDetails singleOrder={singleOrder} />
            </BottomContainer>
        </SingleOrderMainContainer>
    )
}
