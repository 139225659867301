import { useQueryString } from '@/hooks'
import { SignupLayout } from '@/layouts'
import type { UserFormDataType } from '@/models/auth'
import { SIGNUP_SOURCES } from '@/models/auth'
import { CreateUserFormContainer } from '@/new_containers/components/Auth'

export const ShopifySignupPageContainer = () => {
    const { queryParams } = useQueryString<UserFormDataType>()

    return (
        <SignupLayout title="Create a new User">
            <CreateUserFormContainer source={SIGNUP_SOURCES.SHOPIFY} userData={queryParams} />
        </SignupLayout>
    )
}
