import { useCallback, useContext, useState } from 'react'
import type { MetricsSectionConfigType, MetricsSectionPropsType } from '@octup/core/models'
import { ModalsContext } from '@/contexts/ModalsContext'
import { MetricsDeepDiveDashboardContainer } from './MetricsDeepDiveDashboardContainer'
import { getDeepDiveMetrics } from './utils'

export type DeepDiveMetricsSectionProviderPropsType = MetricsSectionPropsType &
    Pick<MetricsSectionConfigType, 'Component'>

export const DeepDiveMetricsSectionProvider = ({
    data,
    clientId,
    Component,
    sectionTypeMetrics,
    ...props
}: DeepDiveMetricsSectionProviderPropsType) => {
    const { setDeepdiveModal } = useContext(ModalsContext)
    const [isDashboardDialogOpen, setIsDashboardDialogOpen] = useState(false)
    const { name: metricName, deep_dive, deep_dive_dashboard_id } = data?.metrics?.[0] || {}
    const hasDeepDive = !!deep_dive || !!deep_dive_dashboard_id
    const isClickable = !!sectionTypeMetrics && hasDeepDive

    const toggleDashboardDialogOpen = useCallback(() => {
        setIsDashboardDialogOpen((prev) => !prev)
    }, [])

    const handleDeepDiveShow = useCallback(() => {
        const customMetrics = getDeepDiveMetrics(sectionTypeMetrics!)
        setDeepdiveModal({ show: true, customMetrics, selectedDDMetric: metricName, clientId })
    }, [sectionTypeMetrics, metricName, clientId, setDeepdiveModal])

    const handleClick = useCallback(() => {
        if (deep_dive_dashboard_id) return toggleDashboardDialogOpen()
        if (deep_dive) return handleDeepDiveShow()
    }, [deep_dive, deep_dive_dashboard_id, handleDeepDiveShow, toggleDashboardDialogOpen])

    return (
        <>
            <Component data={data} onClick={handleClick} isClickable={isClickable} {...props} />
            {deep_dive_dashboard_id && (
                <MetricsDeepDiveDashboardContainer
                    open={isDashboardDialogOpen}
                    onClose={toggleDashboardDialogOpen}
                    dashboardId={deep_dive_dashboard_id}
                    clientId={clientId}
                />
            )}
        </>
    )
}
