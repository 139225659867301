import { useCallback, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { COMPARISON_MODES } from '@/constants/metrics'
import { setComparisonMetric, setComparisonMode } from '@/features/sections/deepdivesSectionsSlice'
import Dropdown from '@/shared/ui/Dropdown/Dropdown'
import { MetricsDropdownContainer } from '../MetricsDropdownContainer'
import { getOptions, isSlaMetric } from './utils'

export const ComparisonDropdownContainer = ({ metricsGroup }) => {
    const dispatch = useDispatch()
    const deepdiveSection = useSelector((state) => state.deepdivesSection)
    const { comparisonMode, isBreakdown, selectedMetric } = deepdiveSection
    const isSelectedSla = useMemo(() => isSlaMetric(selectedMetric), [selectedMetric])

    const getGroupsFilter = useCallback(
        (group) => {
            const isSelectedGroup = group.metric.metricTitle === selectedMetric?.metricTitle
            const isGroupSla = isSlaMetric(group.metric)

            if (isSelectedGroup) return !isSelectedGroup
            return isSelectedSla ? isGroupSla : !isGroupSla
        },
        [isSelectedSla, selectedMetric?.metricTitle]
    )

    const options = useMemo(
        () => getOptions({ isBreakdown, metricsGroup, isSelectedSla }),
        [isBreakdown, isSelectedSla, metricsGroup]
    )

    const handleModeSelect = useCallback(
        ({ value: option }) => {
            if (option) {
                dispatch(setComparisonMode(option.value))

                if (option.value !== COMPARISON_MODES.METRIC) {
                    dispatch(setComparisonMetric(null))
                }
            }
        },
        [dispatch]
    )

    const handleMetricSelect = useCallback(
        ({ value: option }) => {
            if (option) {
                dispatch(setComparisonMetric(option.metric))
            }
        },
        [dispatch]
    )

    const updateSelectedOptions = useMemo(
        () => !options.find(({ value }) => value === comparisonMode),
        [comparisonMode, options]
    )

    return (
        <Stack direction="row" alignItems="center" spacing={3} paddingLeft={2}>
            <Typography variant="body2">Compare to: </Typography>
            <Dropdown
                trimSpace
                options={options}
                showCounter={false}
                isMultiSelection={false}
                onClickCallback={handleModeSelect}
                updateSelectedOptions={updateSelectedOptions}
            />
            {comparisonMode === COMPARISON_MODES.METRIC && (
                <MetricsDropdownContainer
                    metricsGroup={metricsGroup}
                    onSelect={handleMetricSelect}
                    getGroupsFilter={getGroupsFilter}
                    updateSelectedOptions
                />
            )}
        </Stack>
    )
}
