import { FORMATTER_DISPLAY_TYPES } from '@octup/core/models'
import { Box } from '@octup/core/ui-kit'
import { PatternFormatter } from '../PatternFormatter'

export type ColorPatternFormatterPropsType = {
    value: string
    description?: string
    isValid: boolean
}

export const ColorPatternFormatter = ({
    value,
    description,
    isValid,
}: ColorPatternFormatterPropsType) => (
    <PatternFormatter description={description}>
        <Box
            component="span"
            color={isValid ? 'success.main' : 'error.main'}
            data-testid={FORMATTER_DISPLAY_TYPES.COLOR}
        >
            {value}
        </Box>
    </PatternFormatter>
)
