import { createContext, useState } from 'react'

export const ModalsContext = createContext()

export const ModalsContextProvider = ({ children }) => {
    const [deepdiveModal, setDeepdiveModal] = useState({
        show: false,
        segments: [],
        selectedDDMetric: null,
    })

    return (
        <ModalsContext.Provider value={{ deepdiveModal, setDeepdiveModal }}>
            {children}
        </ModalsContext.Provider>
    )
}
