import { CardContent, CardActions } from '@octup/core/ui-kit'
import { FormSubmitButtonContainer } from '@/new_containers/components/__common__'
import { cardActionStyle } from './styles'
import type { FormContentPropsType } from '..'
import { FormContent } from '..'

export const CardFormContent = ({ children, isLoading, error }: FormContentPropsType) => (
    <>
        <CardContent>
            <FormContent isLoading={isLoading} error={error}>
                {children}
            </FormContent>
        </CardContent>
        {!isLoading && !error && (
            <CardActions sx={cardActionStyle}>
                <FormSubmitButtonContainer />
            </CardActions>
        )}
    </>
)
