import type {
    PatternFormatterConfigType,
    PatternFormatterDataType,
    FORMATTER_DISPLAY_TYPES,
} from '@octup/core/models'

export const getLinkByPattern = (
    link: PatternFormatterConfigType<FORMATTER_DISPLAY_TYPES.LINK>['pattern'],
    data: PatternFormatterDataType
): string => link.replace(/{(.*?)}/g, (value, key) => `${data[key] ?? value}`)
