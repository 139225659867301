import { useMemo } from 'react'
import type { MetricsSectionPropsType } from '@octup/core/models'
import { Grid } from '@octup/core/ui-kit'
import type { DashboardCardPropsType } from '@/new_components/Dashboards'
import { DashboardCard } from '@/new_components/Dashboards'
import { MetricsSectionCardContent } from '../MetricsSectionCardContent'
import { MetricsSectionTitle } from '../MetricsSectionTitle'
import type { MetricsSectionTitlePropsType } from '../MetricsSectionTitle'
import { cardStyle } from './styles'

type MetricsSectionCardPropsType = Omit<MetricsSectionPropsType, 'data'> &
    Pick<DashboardCardPropsType, 'children' | 'showFullScreenButton'> &
    MetricsSectionTitlePropsType & {
        height: number
        minHeight?: number
    }

export const MetricsSectionCard = ({
    id,
    name,
    description,
    sources,
    children,
    hasContent,
    error,
    isLoading,
    height,
    minHeight,
    showFullScreenButton: customShowFullScreenButton,
    isClickable,
    onClick,
}: MetricsSectionCardPropsType) => {
    const cardSx = useMemo(() => cardStyle(height, minHeight), [height, minHeight])
    const showFullScreenButton = !isLoading && customShowFullScreenButton

    return (
        <DashboardCard
            data-section-id={id}
            data-testid="metrics-section-card"
            onClick={onClick}
            isClickable={isClickable}
            showFullScreenButton={showFullScreenButton}
            component={Grid}
            container
            direction="column"
            wrap="nowrap"
            sx={cardSx}
            title={
                <MetricsSectionTitle
                    name={name}
                    description={description}
                    sources={sources}
                    isLoading={isLoading}
                />
            }
        >
            <MetricsSectionCardContent hasContent={hasContent} error={error} isLoading={isLoading}>
                {children}
            </MetricsSectionCardContent>
        </DashboardCard>
    )
}
