import { Grid } from '@mui/material'
import { ProductsTableContainer } from 'new_containers/components/UnitEconomics'
import { ProductsStats } from 'pages/unit-economics/page-components'
import { CardExpendable } from 'shared/ui/Card/CardExpendable'
import { profitCardContainerStyle, tableCardContainerStyle } from './ProductsPanel.styles'

export const ProductsPanel = () => (
    <>
        <Grid item container sx={profitCardContainerStyle}>
            <CardExpendable>
                <ProductsStats />
            </CardExpendable>
        </Grid>
        <Grid item container sx={tableCardContainerStyle}>
            <CardExpendable>
                <ProductsTableContainer />
            </CardExpendable>
        </Grid>
    </>
)
