import { useEffect } from 'react'
import type { DateRangeType } from '@octup/core/models'
import PageLayout from '@/components/PageLayout/PageLayout'
import type { PageType } from '@/models/pages'
import { PAGE_DATE_PICKER_TYPES } from '@/models/pages'
import { fetchPageSettingsById } from '@/state/features/pages'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { PAGE_BY_VIEW } from './utils'

export type SystemPageContainerPropsType = Pick<PageType, 'id'>

export const SystemPageContainer = ({ id }: SystemPageContainerPropsType) => {
    const dispatch = useAppDispatch()
    const datePicker = useAppSelector((state) => state.datePicker)
    const settings = useAppSelector((state) => state.pages.settingsById[id])
    const { data, error, isLoading } = settings || {}
    const { name, view, dashboards, date_picker_type } = data || {}
    const showDatePicker = date_picker_type !== PAGE_DATE_PICKER_TYPES.NONE
    const dateRangeByType = datePicker as Record<PAGE_DATE_PICKER_TYPES, DateRangeType>
    const dateRange = date_picker_type ? dateRangeByType[date_picker_type] : undefined
    const Page = view && PAGE_BY_VIEW[view]

    useEffect(() => {
        if (!data) {
            dispatch(fetchPageSettingsById(id))
        }
    }, [dispatch, id, data])

    return (
        // @ts-expect-error: PageLayout is a JavaScript file
        <PageLayout
            key={id}
            error={error}
            isLoading={isLoading}
            hasCustomTitle
            customTitleStyled={name}
            showDatePicker={showDatePicker}
            datePickerConfig={{ viewType: date_picker_type }}
        >
            {Page && <Page data={dashboards} dateRange={dateRange} />}
        </PageLayout>
    )
}
