import { Stack, TextField } from '@octup/core/ui-kit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ObjectSchema } from 'yup'
import type { CreateUserFormType, SessionType } from '@/models/auth'
import { TermsAndPrivacyCheckbox } from '@/new_components/__common__'
import {
    FormFieldContainer,
    FormSubmitButtonContainer,
} from '@/new_containers/components/__common__'

export type CreateUserFormPropsType = {
    defaultValues: CreateUserFormType
    validationSchema: ObjectSchema<Partial<CreateUserFormType>>
    onSubmit: (data: CreateUserFormType) => void
    onSuccess: (data: PayloadAction<SessionType>) => void
    onFail: () => void
}

export const CreateUserForm = () => (
    <Stack spacing={4}>
        <FormFieldContainer
            as={TextField}
            fullWidth
            readOnly
            name="shop"
            disabled
            label="Store Name"
        />
        <FormFieldContainer
            as={TextField}
            fullWidth
            readOnly
            name="fullName"
            disabled
            label="Full Name"
        />
        <FormFieldContainer as={TextField} fullWidth readOnly name="email" disabled label="Email" />
        <FormFieldContainer
            as={TextField}
            type="password"
            fullWidth
            name="password"
            label="Password"
        />
        <FormFieldContainer
            as={TextField}
            type="password"
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
        />
        <FormSubmitButtonContainer size="large" fullWidth>
            Sign Up
        </FormSubmitButtonContainer>
        <Stack direction="row" alignItems="center" spacing={2}>
            <FormFieldContainer as={TermsAndPrivacyCheckbox} name="isTermAccepted" />
        </Stack>
    </Stack>
)
