import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ERROR_MESSAGES } from '@/constants/validation'
import { useToast } from '@/hooks'
import { HTTP_STATUS_CODES } from '@/models/network'
import { deleteUser } from '@/services/users'
import { TrashSvg } from '@/shared/assets/icons'
import { Column, DotMenuOptionsContainer } from '@/shared/styledComponents'
import { OctupColors } from '@/shared/theme/colors/OctupColors'
import { OctupBaseModal } from '@/shared/ui/Modal/OctupBaseModal'
import { OctupTableRowDotMenu } from '@/shared/ui/Tables/OctupTableRowDotMenu'
import { removeUser } from '@/state/features/users'

export const UserExtraOptions = ({ user, clientId }) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)

    const [showModal, setShowModal] = useState(false)
    const toggleShowModal = useCallback(() => setShowModal((prev) => !prev), [])

    const handleDeleteUser = useCallback(async () => {
        toggleShowModal()
        const response = await deleteUser({ id: user.id, clientId })

        if (response.status === HTTP_STATUS_CODES.OK) {
            toast.success(`${user.fullName} user has been deleted successfully`)
            dispatch(removeUser({ id: user.id, clientId }))
        } else {
            toast.error(ERROR_MESSAGES.SERVER_ERROR)
        }
    }, [clientId, dispatch, toast, toggleShowModal, user.fullName, user.id])

    return (
        <>
            {showModal && (
                <OctupBaseModal
                    title={'Delete a Member'}
                    onDismissModal={toggleShowModal}
                    buttons={[
                        { title: 'Cancel', variant: 'secondary', onClick: toggleShowModal },
                        { title: 'Delete Member', onClick: handleDeleteUser, variant: 'contained' },
                    ]}
                >
                    <Column>
                        <Title>Are you sure you wish to delete this member?</Title>
                        <Body>
                            {' '}
                            All their active objectives will be deleted and their data won’t be
                            restorable.
                        </Body>
                    </Column>
                </OctupBaseModal>
            )}
            {/*User Cannot Delete Himself*/}
            {userInfo.id !== user.id && (
                <OctupTableRowDotMenu
                    options={
                        <DotMenuOptionsContainer>
                            <MainContainer onClick={toggleShowModal}>
                                <TrashSvg active={true} customActiveColor="black" />
                                Delete Member
                            </MainContainer>
                        </DotMenuOptionsContainer>
                    }
                />
            )}
        </>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    gap: 0.6rem;
    padding: 1rem 1.5rem;
    background-color: ${OctupColors.pageBackground};
    border-radius: 8px;
    cursor: pointer;

    position: fixed;
    margin-top: -50px !important;
    margin-left: -200px !important;

    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);

    :hover {
        background: #f1f0f5;
        border-radius: 0.8rem;
    }
`

const Title = styled.div`
    font-size: 12px;
    font-weight: 600;
`

const Body = styled.div`
    font-size: 10px;
    font-weight: 400;
`
