import type { DateRangeType } from '@octup/core/models'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Stack,
} from '@octup/core/ui-kit'
import type { DashboardsListType } from '@/models/dashboards'
import { NoDataFallback } from '@/new_components/__common__'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'
import { dashboardStyle } from './styles'

type DashboardsListPropsType = {
    data?: DashboardsListType
    dateRange?: DateRangeType
}

export const DashboardsList = ({ data, dateRange }: DashboardsListPropsType) => {
    //  @ts-expect-error: NoDataFallback is a JavaScript file
    if (!data?.length) return <NoDataFallback showIcon={false} />

    return (
        <Stack width="100%">
            {data.map(({ id, name, client_id }, index) => (
                <Accordion key={`${id}-${client_id}`} defaultExpanded={index === 0}>
                    <AccordionSummary>
                        <Typography variant="h6">{name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={dashboardStyle}>
                        <MetricsDashboardProvider
                            dashboardId={id}
                            clientId={client_id}
                            dateRange={dateRange}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Stack>
    )
}
