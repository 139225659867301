import { Dialog, DialogContent } from '@octup/core/ui-kit'
import { MetricsDetailsMetricSelection } from '@/components/Modals/MetricsModals/MetricsDetailsMetricSelection'
import { DatePickerContainer, MetricDeepDiveContainer } from '@/new_containers/components/Metrics'
import { dialogStyle } from './styles'

export const MetricDeepDiveModal = ({
    metricId,
    deepDiveId,
    blendedDeepDiveId,
    datePicker,
    clientId,
    onClose,
    metricsGroup,
    onMetricSelection,
    ...props
}) => {
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            onClose={onClose}
            showFullScreenButton
            sx={dialogStyle}
            action={<DatePickerContainer />}
            title={
                <MetricsDetailsMetricSelection
                    metricsGroup={metricsGroup}
                    onMetricSelection={onMetricSelection}
                />
            }
            {...props}
        >
            <DialogContent>
                <MetricDeepDiveContainer
                    metricId={metricId}
                    deepDiveId={deepDiveId}
                    blendedDeepDiveId={blendedDeepDiveId}
                    datePicker={datePicker}
                    clientId={clientId}
                    metricsGroup={metricsGroup}
                />
            </DialogContent>
        </Dialog>
    )
}
