import { APP_ROUTES } from '@/models/appConfig'
import type { TabPageType } from '@/models/pages'
import { SLASettings } from '@/new_components/Clients'
import { ClientsSettingsContainer } from '@/new_containers/components/Clients'

export const TABS: TabPageType[] = [
    {
        id: 1,
        label: 'Clients Display Name',
        route: APP_ROUTES.SETTINGS_CLIENTS,
        Component: ClientsSettingsContainer,
    },
    {
        id: 2,
        label: 'SLA Settings',
        route: APP_ROUTES.SETTINGS_SLA,
        Component: SLASettings,
    },
]
