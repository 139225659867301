import { isAfter } from 'date-fns'
import type { DatePickerType } from '../DatePicker'

export type DateRangePickerType = {
    startDate?: DatePickerType['value']
    endDate?: DatePickerType['value']
}

export const isDateRangeValid = (dateRange: DateRangePickerType) => {
    const { startDate, endDate } = dateRange
    if (!startDate || !endDate || isAfter(startDate, endDate)) return false

    return true
}
