import type { MetricsSectionConfigByType } from '@octup/core/models'
import { METRICS_SECTION_RESPONSE_DATA_TYPE, METRICS_SECTION_TYPE } from '@octup/core/models'
import {
    CurrentMetricsSection,
    LineMetricsSection,
    OverviewMetricsSection,
} from '@/new_components/__common__/MetricsSections'
import {
    BarMetricsSectionContainer,
    DoughnutMetricsSectionContainer,
    TableMetricsSectionContainer,
    TotalMetricsSectionContainer,
} from '@/new_containers/components/__common__/MetricsSections'
import { CustomTableMetricsSectionProvider } from '../..'

export const METRICS_SECTION_CONFIG_BY_TYPE: MetricsSectionConfigByType = {
    [METRICS_SECTION_TYPE.OVERVIEW]: {
        Component: OverviewMetricsSection,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.DATES,
    },
    [METRICS_SECTION_TYPE.CURRENT]: {
        Component: CurrentMetricsSection,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.SUMMARIZED,
    },
    [METRICS_SECTION_TYPE.TOTAL]: {
        Component: TotalMetricsSectionContainer,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.SUMMARIZED,
    },
    [METRICS_SECTION_TYPE.DOUGHNUT]: {
        Component: DoughnutMetricsSectionContainer,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.SUMMARIZED,
    },
    [METRICS_SECTION_TYPE.BAR]: {
        Component: BarMetricsSectionContainer,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.SUMMARIZED,
    },
    [METRICS_SECTION_TYPE.LINE]: {
        Component: LineMetricsSection,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.DATES,
    },
    [METRICS_SECTION_TYPE.TABLE]: {
        Component: TableMetricsSectionContainer,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.DATES,
    },
    [METRICS_SECTION_TYPE.CUSTOM_TABLE]: {
        Component: CustomTableMetricsSectionProvider,
        responseDataType: METRICS_SECTION_RESPONSE_DATA_TYPE.SUMMARIZED,
    },
}
