import { DashboardContainer } from '@octup/core/components'
import type { DashboardSectionPropsType } from '@octup/core/components'
import type { DashboardTemplateType } from '@octup/core/models'
import { DASHBOARD_SECTIONS_FIELD_NAME } from '@/constants/dashboards'
import { FormFieldContainer } from '@/new_containers/components/__common__'
import { MetricsSectionFieldContainer } from '@/new_containers/components/Dashboards'

export type DashboardFormPropsType = {
    template: DashboardTemplateType
}

const renderSection = ({ index, group }: DashboardSectionPropsType) => (
    <FormFieldContainer
        as={MetricsSectionFieldContainer}
        name={`${DASHBOARD_SECTIONS_FIELD_NAME}.${index}`}
        showError={false}
        group={group}
    />
)

export const DashboardForm = ({ template }: DashboardFormPropsType) => (
    <DashboardContainer showGroupName template={template} renderSection={renderSection} />
)
