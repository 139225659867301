import type { RouteObject } from 'react-router-dom'
import { APP_ROUTES, DEFAULT_ROUTES } from '@/models/appConfig'
import {
    IntegrationsPageContainer,
    AttributionPageContainer,
    UnitEconomicsPageContainer,
    LogisticsPageContainer,
    CostManagerPageContainer,
    EditDashboardPageContainer,
    DashboardPageContainer,
    CreateDashboardPageContainer,
    SystemPageContainer,
    SettingsPageContainer,
    ShopifySignupPageContainer,
    WeekOverviewPageContainer,
    OrderPageContainer,
    MetricDeepDivePageContainer,
    UserManagementPageContainer,
} from '@/new_containers/pages'
import { ResetPasswordPage } from '@/new_pages'
import { App } from './App'
import type { PageType } from './models/pages'
import { PAGE_SOURCE_TYPES } from './models/pages'
import { AuthRedirectLayoutContainer, MainLayoutContainer } from './new_containers/layouts'
import { SignupPageContainer } from './new_containers/pages'
import { AccountPage } from './pages/account/AccountPage'
import HomePage from './pages/home/HomePage'
import InsightsPage from './pages/insight/InsightsPage'
import InviteUserPage from './pages/inviteUser/InviteUserPage'
import LoginPage from './pages/Login/LoginPage'
import MarketingPage from './pages/marketing/MarketingPage'
import { NotFoundPage } from './pages/NotFoundPage'
import ObjectivesPage from './pages/objectives/ObjectivesPage'
import OperationsOverviewPage from './pages/operations/OperationOverviewPage'
import { Root } from './Root'

const COMPONENT_BY_ROUTE: Record<PageType['route'], RouteObject['Component']> = {
    [APP_ROUTES.HOME]: HomePage,
    [APP_ROUTES.MARKETING]: MarketingPage,
    [APP_ROUTES.MARKETING_ATTRIBUTION]: AttributionPageContainer,
    [APP_ROUTES.WAREHOUSING_OVERVIEW]: LogisticsPageContainer,
    [APP_ROUTES.OPERATIONS_OVERVIEW]: OperationsOverviewPage,
    [APP_ROUTES.UNIT_ECONOMICS]: UnitEconomicsPageContainer,
    [APP_ROUTES.UNIT_ECONOMICS_COST_MANAGER]: CostManagerPageContainer,
    [APP_ROUTES.INTEGRATIONS]: IntegrationsPageContainer,
    [APP_ROUTES.INSIGHTS]: InsightsPage,
    [APP_ROUTES.OBJECTIVES]: ObjectivesPage,
    [APP_ROUTES.REPORTS_WEEK_OVERVIEW]: WeekOverviewPageContainer,
}

const getDynamicRoutes = (pages: PageType[]): RouteObject[] =>
    pages.map(({ id, route, source_type, menu_items }: PageType) => ({
        path: route,
        ...(source_type === PAGE_SOURCE_TYPES.DASHBOARD && {
            element: <SystemPageContainer id={id} />,
        }),
        ...(source_type === PAGE_SOURCE_TYPES.STATIC && {
            Component: COMPONENT_BY_ROUTE[route],
        }),
        ...(menu_items?.length && {
            children: getDynamicRoutes(menu_items),
        }),
    }))

export const getRoutes = (dynamicPages: PageType[]) => [
    {
        Component: Root,
        children: [
            {
                Component: App,
                children: [
                    {
                        path: DEFAULT_ROUTES.MAIN,
                        Component: MainLayoutContainer,
                        children: [
                            ...getDynamicRoutes(dynamicPages),
                            {
                                path: APP_ROUTES.UNIT_ECONOMICS_COST_MANAGER,
                                Component: CostManagerPageContainer,
                            },
                            {
                                path: APP_ROUTES.DASHBOARD,
                                Component: DashboardPageContainer,
                            },
                            {
                                path: APP_ROUTES.DASHBOARD_EDIT,
                                Component: EditDashboardPageContainer,
                            },
                            {
                                path: APP_ROUTES.DASHBOARD_CREATE,
                                Component: CreateDashboardPageContainer,
                            },
                            {
                                path: APP_ROUTES.ORDER,
                                Component: OrderPageContainer,
                            },
                            {
                                path: APP_ROUTES.METRIC_DEEP_DIVE,
                                Component: MetricDeepDivePageContainer,
                            },
                            {
                                path: APP_ROUTES.METRIC_BLENDED_DEEP_DIVE,
                                Component: MetricDeepDivePageContainer,
                            },
                            {
                                path: APP_ROUTES.SETTINGS,
                                Component: SettingsPageContainer,
                            },
                            {
                                path: APP_ROUTES.USERS,
                                Component: UserManagementPageContainer,
                            },
                            {
                                path: APP_ROUTES.ACCOUNT,
                                Component: AccountPage,
                            },
                            {
                                path: DEFAULT_ROUTES.ALL,
                                Component: NotFoundPage,
                            },
                        ],
                    },
                    {
                        // NOTE: Temporary, move redirection to AuthLayout once all pages are ready
                        Component: AuthRedirectLayoutContainer,
                        children: [
                            {
                                path: APP_ROUTES.LOGIN,
                                Component: LoginPage,
                            },
                            {
                                path: APP_ROUTES.REGISTRATION,
                                Component: SignupPageContainer,
                            },
                            {
                                path: APP_ROUTES.SHOPIFY_REGISTRATION,
                                Component: ShopifySignupPageContainer,
                            },
                            {
                                path: APP_ROUTES.RESET_PASSWORD,
                                Component: ResetPasswordPage,
                            },
                            {
                                path: APP_ROUTES.INVITE,
                                Component: InviteUserPage,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
