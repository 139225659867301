import { useEffect, useCallback } from 'react'
import { MetricsDashboardContainer } from '@octup/core/components'
import type {
    ClientType,
    DateRangeType,
    MetricsSectionConfigByType,
    MetricsSectionType,
} from '@octup/core/models'
import type { DashboardType } from '@/models/dashboards'
import { ErrorMessageFallback } from '@/new_components/__common__'
import { NoDataFallbackContainer } from '@/new_containers/components/__common__'
import { NoDataSvg } from '@/shared/assets/images/__common__'
import { fetchDashboardById } from '@/state/features/dashboards'
import { fetchMetricsSectionById } from '@/state/features/metrics'
import { useAppDispatch } from '@/state/hooks'
import { useDashboard } from './hooks'
import { METRICS_SECTION_CONFIG_BY_TYPE } from './utils'

export type MetricsDashboardProviderPropsType = {
    dashboardId: DashboardType['id']
    clientId?: ClientType['id']
    noDataText?: string
    dateRange?: DateRangeType
    sectionConfigByType?: MetricsSectionConfigByType
}

export const MetricsDashboardProvider = ({
    dashboardId,
    clientId: customClientId,
    noDataText,
    dateRange,
    sectionConfigByType = METRICS_SECTION_CONFIG_BY_TYPE,
}: MetricsDashboardProviderPropsType) => {
    const dispatch = useAppDispatch()
    const { startDate = '', endDate = '' } = dateRange || {}
    const { clientId, dashboard, isLoading, sectionsList, sectionStateById, metricsBySectionType } =
        useDashboard({ id: dashboardId, clientId: customClientId, startDate, endDate })

    useEffect(() => {
        if (!dashboard?.data) {
            dispatch(fetchDashboardById({ id: dashboardId, clientId }))
        }
    }, [dashboard?.data, dashboardId, dispatch, clientId])

    const getMetricsSectionDataById = useCallback(
        ({ id, type }: MetricsSectionType) => {
            if (!sectionStateById?.[id]) {
                const { responseDataType } = METRICS_SECTION_CONFIG_BY_TYPE[type]
                const params = { id, type, responseDataType, clientId, startDate, endDate }
                dispatch(fetchMetricsSectionById(params))
            }
        },
        [clientId, dispatch, sectionStateById, startDate, endDate]
    )

    useEffect(() => {
        sectionsList?.forEach((section) => section && getMetricsSectionDataById(section))
    }, [getMetricsSectionDataById, sectionsList])

    if (dashboard?.error) return <ErrorMessageFallback />
    if (!isLoading && !sectionsList?.length) {
        return (
            //  @ts-expect-error: NoDataFallbackContainer is a JavaScript file
            <NoDataFallbackContainer
                icon={<NoDataSvg />}
                title="This board is empty at the moment."
                text={noDataText}
            />
        )
    }

    return (
        <MetricsDashboardContainer
            data-dashboard-id={dashboardId}
            isLoading={isLoading}
            template={dashboard?.data?.template}
            sectionsList={sectionsList}
            sectionStateById={sectionStateById}
            sectionConfigByType={sectionConfigByType}
            metricsBySectionType={metricsBySectionType}
        />
    )
}
