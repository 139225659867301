import { useCallback, useMemo, useEffect } from 'react'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UseFormReturn } from 'react-hook-form'
import { ERROR_MESSAGES } from '@/constants'
import { useToast } from '@/hooks'
import type { SettingsType, WorkingHoursSettingsFormType } from '@/models/settings'
import { FormContent as DefaultFormContent } from '@/new_components/__common__/Form'
import { WorkingHoursSettingsForm } from '@/new_components/Clients'
import { FormContainer } from '@/new_containers/components/__common__'
import {
    fetchEditWorkingHoursSettings,
    updateWorkingHoursSettings,
    fetchWorkingHoursSettings,
} from '@/state/features/settings'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { convertToForm, convertToAPI, VALIDATION_SCHEMA } from './utils'

export type WorkingHoursSettingsFormContainerPropsType = {
    FormContent?: typeof DefaultFormContent
}

export const WorkingHoursSettingsFormContainer = ({
    FormContent = DefaultFormContent,
}: WorkingHoursSettingsFormContainerPropsType) => {
    const toast = useToast()
    const dispatch = useAppDispatch()
    const { data, error, isLoading } = useAppSelector((state) => state.settings.workingHours)
    const defaultValues = useMemo(() => convertToForm(data), [data])

    useEffect(() => {
        if (!data) {
            dispatch(fetchWorkingHoursSettings())
        }
    }, [data, dispatch])

    const handleSubmit = useCallback(
        (data: WorkingHoursSettingsFormType) =>
            dispatch(fetchEditWorkingHoursSettings(convertToAPI(data))),
        [dispatch]
    )

    const handleSuccess = useCallback(
        (data: PayloadAction<SettingsType>, formContext: UseFormReturn) => {
            const { working_hours } = data.payload
            formContext.reset(convertToForm(working_hours))
            dispatch(updateWorkingHoursSettings(working_hours))
            toast.success(`Working hours have been updated successfully`)
        },
        [dispatch, toast]
    )

    const handleFail = useCallback(() => toast.error(ERROR_MESSAGES.SERVER_ERROR), [toast])

    return (
        //  @ts-expect-error: PageLayout is a JavaScript file
        <FormContainer
            defaultValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFail={handleFail}
        >
            <FormContent isLoading={isLoading} error={error}>
                <WorkingHoursSettingsForm />
            </FormContent>
        </FormContainer>
    )
}
