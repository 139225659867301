export const ROW_KEY_FIELD = 'order_id'
export const DEFAULT_SORT_BY = { title: 'date' }
export const ORDERS_TABLE_HEADERS = ['Order Number', 'Date', 'Full Name', 'Destination', 'Value']

export const ORDERS_SEARCH_FIELDS = {
    order_number: 'order_number',
    destination: 'destination',
    full_name: 'full_name',
    date: 'date',
    value: 'value',
}

export const getTableName = (title) =>
    title +
    ' ' +
    new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })

export const ORDERS_TABLE_CONFIG = {
    showFilters: false,
    showDatePicker: false,
    showSelectedRow: false,
    rowKeyField: ROW_KEY_FIELD,
    defaultSortBy: DEFAULT_SORT_BY,
    searchFilterPlaceholder: 'Search...',
    showSearchFilter: false,
    requestedFilters: [],
    // TODO: remove these fields from the table
    hiddenRowKey: 'order_number',
    customRowStyle: { height: '5rem' },
}
