import type * as icons from '@mui/icons-material'
import type { APP_ROUTES } from '../appConfig'

export enum PAGE_MENU_TYPES {
    SIDEBAR = 'sidebar',
}

export enum PAGE_SOURCE_TYPES {
    STATIC = 'static',
    DASHBOARD = 'dashboard',
}

export type PageType = {
    id: string
    name: string
    icon?: keyof typeof icons
    route: APP_ROUTES | string
    menu_items?: PageType[]
    source_type?: PAGE_SOURCE_TYPES
}

export type MenuPagesType = {
    menu_items: PageType[]
}
