import { octupDictionary } from 'shared/dictionary/dictionary'
import { GoodMorningBoard } from '@/components/GoodMorningBoard/GoodMorningBoard'
import PageLayout from '@/components/PageLayout/PageLayout'
import { APP_ROUTES } from '@/models/appConfig'
import { useAppSelector } from '@/state/hooks'

export const WeekOverviewPageContainer = () => {
    const { isLoading } = useAppSelector((state) => state.metrics)

    return (
        // @ts-expect-error: PageLayout is a JavaScript file
        <PageLayout
            showDatePicker={false}
            isLoading={isLoading}
            hasCustomTitle
            customTitleStyled={octupDictionary.pages[APP_ROUTES.REPORTS_WEEK_OVERVIEW]}
        >
            <GoodMorningBoard />
        </PageLayout>
    )
}
