import type { ClientType, UserType } from '@octup/core/models'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { UserRequestType } from '@/services/users'
import { getUsers } from '@/services/users'

type UsersStateType = {
    data: UserType[]
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: Record<ClientType['id'], UsersStateType> = {}

type UsersResponseType = {
    data: Record<UserType['id'], UserType>
}

export const fetchUsers = createAsyncThunk<UsersResponseType, UserRequestType>(
    'fetchUsers',
    getUsers
)

export const usersSlice = createSlice({
    name: 'users',
    initialState: INITIAL_STATE,
    reducers: {
        removeUser(state, action) {
            const { id, clientId } = action.payload
            state[clientId].data = state[clientId].data.filter((user) => user.id !== id)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                const { clientId } = action.meta.arg
                state[clientId] = { ...state[clientId], isLoading: true, error: undefined }
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const { clientId } = action.meta.arg
                const data = Object.values(action.payload.data)
                state[clientId] = { data, isLoading: false, error: undefined }
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                const { clientId } = action.meta.arg
                const error = action.error.message
                state[clientId] = { ...state[clientId], isLoading: true, error }
            })
    },
})

export const { removeUser } = usersSlice.actions
export const usersReducer = usersSlice.reducer
