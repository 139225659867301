import type { Theme } from '@mui/material'

export const cardStyle = (height: number, minHeight?: number) => (theme: Theme) => ({
    maxHeight: '100%',
    minHeight: {
        xs: theme.spacing(height),
        lg: theme.spacing(minHeight || height),
    },
    '& .MuiCardHeader-root': {
        padding: theme.spacing(2, 3, 0),
    },
})
