import type { MetricType } from '@octup/core/models'

type DeepDiveMetricType = Partial<MetricType> & {
    metric_id: MetricType['id']
    metricTitle: MetricType['name']
    isPositiveTrend: MetricType['is_positive_trend']
    valueUnits: MetricType['value_units']
    trendValue: MetricType['trend_value']
    trendUnits: MetricType['trend_units']
    blended_dive_in: MetricType['blended_deep_dive']
}

type DeepDiveMetricsListType = Record<'metric', DeepDiveMetricType>[]

// NOTE: map metrics to support the old format for deep dive
export const getDeepDiveMetrics = (metrics: MetricType[]) =>
    metrics.reduce((acc: DeepDiveMetricsListType, metric) => {
        if (!metric.deep_dive) return acc

        const {
            id,
            name,
            is_positive_trend,
            value_units,
            trend_value,
            trend_units,
            blended_deep_dive,
            ...metricParams
        } = metric

        return [
            ...acc,
            {
                metric: {
                    metric_id: id,
                    metricTitle: name,
                    isPositiveTrend: is_positive_trend,
                    valueUnits: value_units,
                    trendValue: trend_value,
                    trendUnits: trend_units,
                    blended_dive_in: blended_deep_dive,
                    ...metricParams,
                },
            },
        ]
    }, [])
