import { useMemo } from 'react'
import type { MetricType } from '@octup/core/models'
import { Grid } from '@octup/core/ui-kit'
import { ChartsArea } from '@/shared/ui/Charts/ChartsArea'
import { getChartDataConfig } from './utils'

// Re-write chart components to tsx and extend line chart props here
type LineChartPropsType = {
    id?: number | string
    style?: object
    showGradient?: boolean
    showTarget?: boolean
    showLabels?: boolean
    showYAxis?: boolean
}

type LineChartContainerPropsType = LineChartPropsType & {
    metrics?: MetricType[]
}

export const LineChartContainer = ({ metrics, ...props }: LineChartContainerPropsType) => {
    const chartDataConfig = useMemo(() => getChartDataConfig(metrics), [metrics])

    /* the height is 0 to prevent the card from jumping */
    /* TODO: remove after re-writing the chart component */
    return (
        <Grid container direction="column" height="100%">
            <Grid item xs container height={0}>
                {/*  @ts-expect-error: ChartsArea is a JavaScript file */}
                <ChartsArea {...chartDataConfig} {...props} />
            </Grid>
        </Grid>
    )
}
