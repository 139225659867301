import { useEffect } from 'react'
import { format } from 'date-fns'
import { ColumnOutterContainer, Title, TitleContainer } from '../AlterativeHome.styles'
import { AlternativeHomeTable, YESTERDAY_DATE } from './base-components/AlternativeHomeTable'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { useOctupDispatch } from '../../../hooks/useOctupDispatch'
import { DICTIONARY_SECTIONS, getDictionaryValue } from '../../../shared/dictionary/dictionary'

const YESTERDAY_FORMATTED_DATE = format(YESTERDAY_DATE, 'yyyy-MM-dd')

const YESTERDAY_DATES = {
    startDate: YESTERDAY_FORMATTED_DATE,
    endDate: YESTERDAY_FORMATTED_DATE,
}

export function YesterdayStatsSection({ className }) {
    const octupDispatch = useOctupDispatch()

    useEffect(() => {
        octupDispatch.metrics.getMetricsBySegment(
            [SEGMENTS.GRAPH, SEGMENTS.ALTERNATIVE_HOME_YESTERDAY],
            YESTERDAY_DATES,
            1
        )
    }, [octupDispatch.metrics])

    return (
        <ColumnOutterContainer className={className}>
            <TitleContainer style={{ marginTop: '1.5rem' }}>
                <Title>{getDictionaryValue('yesterdays_stats', DICTIONARY_SECTIONS.TITLES)}</Title>
            </TitleContainer>
            <AlternativeHomeTable />
        </ColumnOutterContainer>
    )
}
