import { NoDataTooltip } from 'new_components'
import { octupDictionary } from 'shared/dictionary/dictionary'
import { getFormattedVal } from 'shared/utils/metrics-utils'
import styled from 'styled-components'

export const GMBTableSubRow = ({ el }) => {
    const Icon = el?.icon
    let rowData = el

    return (
        <>
            <TitleStyled>
                {el?.icon && <Icon />}
                {octupDictionary.titles.metricsTitles[rowData.metricTitle] || rowData.metricTitle}
            </TitleStyled>
            {el.values.map((element) => (
                <ValueStyled key={element.id}>
                    {typeof element?.value === 'number' || rowData.supported ? (
                        getFormattedVal({
                            val: element?.value || 0,
                            valueType: rowData?.valueUnits,
                            customNumOfDigits: null,
                            currency: rowData?.currency,
                            isSupported: rowData?.supported,
                            showSymbol: true,
                        })
                    ) : (
                        <NoDataTooltip />
                    )}
                </ValueStyled>
            ))}
        </>
    )
}

const TitleStyled = styled.td`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #504b5a;
`

const ValueStyled = styled.td`
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #504b5a;
    span {
        font-weight: 400;
        font-size: 1.3rem;
        color: rgba(219, 32, 89, 1);
    }
`
