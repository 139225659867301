import { forwardRef } from 'react'
import { CardHeader, Card as MuiCard, Stack } from '@mui/material'
import type { CardHeaderProps, CardProps, GridProps } from '@mui/material'
import { useToggle } from '@octup/core/hooks'
import { Dialog } from '../Dialog'
import { FullScreenIconButton } from '../FullScreenIconButton'

export type CardPropsType = Omit<Partial<GridProps> & CardProps, 'title'> &
    CardHeaderProps & {
        showFullScreenButton?: boolean
    }

export const Card = forwardRef<HTMLDivElement, CardPropsType>(
    ({ title, action, children, showFullScreenButton = false, ...props }, ref) => {
        const { isVisible: isFullScreenOpen, toggleVisibility: toggleFullScreen } = useToggle()
        const hasAction = action || showFullScreenButton

        return (
            <>
                <MuiCard ref={ref} {...props}>
                    {(title || hasAction) && (
                        <CardHeader
                            title={title}
                            action={
                                hasAction && (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {action}
                                        {showFullScreenButton && (
                                            <FullScreenIconButton
                                                isOpen={false}
                                                onClick={toggleFullScreen}
                                            />
                                        )}
                                    </Stack>
                                )
                            }
                        />
                    )}
                    {children}
                </MuiCard>
                <Dialog
                    fullScreen
                    title={title}
                    showFullScreenButton
                    open={isFullScreenOpen}
                    onClose={toggleFullScreen}
                    toggleFullScreen={toggleFullScreen}
                >
                    {children}
                </Dialog>
            </>
        )
    }
)
