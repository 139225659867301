import type { ClientType, UserType } from '@octup/core/models'
import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import { generateAPIConfig } from 'services/utils'

const USERS_API_URL = GLOBAL_CONFIG.BASE_URL + '/user'

export type UserRequestType = {
    clientId: ClientType['id']
}

export const getUsers = async (data: UserRequestType) => {
    const config = generateAPIConfig({ params: { client_id: data.clientId } })
    const response = await axios.get(`${USERS_API_URL}/associated_users`, config)
    return response.data
}

export type InviteUserRequestType = {
    email: string
    clientId: ClientType['id']
}

export const inviteUser = async (data: InviteUserRequestType) => {
    const body = { members: [data.email] }
    const config = generateAPIConfig({ params: { client_id: data.clientId } })
    const response = await axios.post(`${USERS_API_URL}/invite`, body, config)
    return response
}

export type DeleteUserRequestType = {
    id: UserType['id']
    clientId: ClientType['id']
}

export const deleteUser = async (data: DeleteUserRequestType) => {
    const config = generateAPIConfig({ params: { client_id: data.clientId } })
    const response = await axios.delete(`${USERS_API_URL}/delete_user/${data.id}`, config)
    return response
}
