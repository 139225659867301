import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { GMBTableRow } from './GMBTableRow'
import { GMBTableSubRow } from './GMBTableSubRow'
import { SEGMENTS } from '../../../features/metrics/metricsSlice'
import { FacebookIcon } from '../../../shared/assets/images/third-party-icons/FacebookIcon'
import { GoogleIcon } from '../../../shared/assets/images/third-party-icons/GoogleIcon'
import { octupDictionary } from '../../../shared/dictionary/dictionary'
import { OctupBaseTable } from '../../../shared/ui/Tables/OctupBaseTable'
import { getShortTableDate } from '../../../shared/utils/dates-util'

const METRIC_TO_CATEGORY = {
    'Facebook Ad Spend': 'Total Ad Spend',
    'Google Ad Spend': 'Total Ad Spend',
    'Facebook Conversions': 'Income from PPC sources',
    'Google Conversions': 'Income from PPC sources',
}

export const GMBTable = ({ customContainerId }) => {
    const { metrics } = useSelector((state) => state.metrics)
    const [tableData, setTableData] = useState({ rows: [], headers: [] })

    useEffect(() => {
        let headers = []
        let rawRows = []
        let dates = []

        if (metrics[SEGMENTS.GMB_TABLE]) {
            for (const metric of metrics[SEGMENTS.GMB_TABLE]
                .concat(metrics[SEGMENTS.GMB_AD_SPEND_SUBSECTIONS])
                .concat(metrics[SEGMENTS.GMB_INCOME_PPC_SUBSECTIONS])) {
                const adjustedDataset = [...metric.dataset].reverse()
                let values = []
                const metricTitle = metric?.metric?.metricTitle
                const title = octupDictionary.titles.metricsTitles[metricTitle] || metricTitle

                dates = [
                    ...new Set([
                        ...dates,
                        ...adjustedDataset.map((el) => {
                            if (el[1]) {
                                return el[1]
                            }
                        }),
                    ]),
                ]
                    .sort((a, b) => {
                        let elA = new Date(a.date)
                        let elB = new Date(b.date)
                        return elA < elB ? -1 : elA > elB ? 1 : 0
                    })
                    .slice(0, 7)

                headers = [
                    'stats',
                    ...dates.map(
                        (el) =>
                            `${
                                getShortTableDate(new Date(el), {
                                    weekday: 'long',
                                }).weekday
                            }, ${getShortTableDate(new Date(el)).month_and_day}`
                    ),
                ]

                let tmp = {}
                metric.dataset.forEach((el) => {
                    tmp[`${el[1]}`] = el[0]
                })

                dates.forEach((el) => {
                    const valueId = uuidv4()
                    tmp[el]
                        ? values.push({
                              ...metric.metric,
                              id: valueId,
                              value: tmp[el],
                          })
                        : values.push({ id: valueId })
                })

                const metricCategoryTitle = METRIC_TO_CATEGORY[title]

                if (metricCategoryTitle) {
                    rawRows.forEach((el) => {
                        const rawRowTitle =
                            octupDictionary.titles.metricsTitles[el.metricTitle] || el.metricTitle
                        if (rawRowTitle === metricCategoryTitle) {
                            el.subData = [
                                ...(el.subData || []),
                                {
                                    ...metric?.metric,
                                    values,
                                    icon: title.includes('Facebook')
                                        ? FacebookIcon
                                        : title.includes('Google') && GoogleIcon,
                                },
                            ]
                        }
                    })
                } else {
                    rawRows = [...new Set([...rawRows, { values, ...metric?.metric }])]
                }
            }
            setTableData({ rows: rawRows, headers: headers })
        }
    }, [metrics])

    return (
        <OctupBaseTable
            rowKeyField="metricTitle"
            subRowKeyField="metricTitle"
            hiddenRowKey="metricTitle"
            customRowStyle={{ height: '6.5rem' }}
            tableData={tableData}
            showSearchFilter={false}
            showFilters={false}
            showCounter={false}
            isCollapsible={false}
            showSelectedRow={false}
            customContainerId={customContainerId}
            renderRows={(el) => <GMBTableRow el={el} />}
            renderSubRow={(el) => <GMBTableSubRow el={el} />}
        />
    )
}
