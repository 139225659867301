import { DATE_PICKER_VIEW_TYPES } from '@octup/core/models'
import { DatePickerQueryContainer } from '@/new_containers/components/__common__'
import { CalendarHeader } from './CalendarHeader'
import { CalendarLayout } from './CalendarLayout'

export const InvoicesDatePicker = ({ availableMonths, ...props }) => (
    <DatePickerQueryContainer
        {...props}
        viewType={DATE_PICKER_VIEW_TYPES.MONTHLY}
        slots={{ calendarHeader: CalendarHeader, layout: CalendarLayout }}
        slotProps={{ layout: { availableMonths } }}
    />
)
