import './index.css'
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ThemeProvider } from '@octup/core/theme'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ModalsContextProvider } from '@/contexts/ModalsContext'
import { ToastsContextProvider } from '@/contexts/ToastsContext'
import { ErrorBoundaryContainer } from '@/new_containers/components/__common__'
import reportWebVitals from '@/reportWebVitals'
import { Router } from '@/Router'
import { setupStore } from '@/state'
import { createTheme } from '@/theme'
import { identifyUser } from '@/utils'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ErrorBoundaryContainer>
            <ThemeProvider theme={createTheme()}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={setupStore()}>
                        <ToastsContextProvider>
                            <ModalsContextProvider>
                                <Router />
                            </ModalsContextProvider>
                        </ToastsContextProvider>
                    </Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </ErrorBoundaryContainer>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
identifyUser()
