import { Typography } from '@octup/core/ui-kit'
import { APP_ROUTES } from '@/models/appConfig'
import { Link } from '@/new_components/__common__'
import { AppBenefitsInfoContainer } from '@/new_containers/components/Auth'
import { AuthLayoutContainer } from '@/new_containers/layouts'

type SignupLayoutPropsType = {
    title: string
    children: React.ReactNode
}

export const SignupLayout = ({ title, children }: SignupLayoutPropsType) => (
    <AuthLayoutContainer title={title} sideContent={<AppBenefitsInfoContainer />}>
        {children}
        <Typography variant="body2">
            Already have an account? <Link route={APP_ROUTES.LOGIN}>Log in</Link>
        </Typography>
    </AuthLayoutContainer>
)
