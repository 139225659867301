import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getServiceTypes } from 'services'
import type { OrderServiceType } from '@/models/orders'
import type { LogisticsFiltersType } from '@/services/logistics/utils'

type ServiceTypesStateType = {
    data?: OrderServiceType[]
    isLoading: boolean
    error?: string
}

const INITIAL_STATE: ServiceTypesStateType = {
    data: undefined,
    isLoading: false,
    error: undefined,
}

export const fetchServiceTypes = createAsyncThunk<OrderServiceType[], LogisticsFiltersType>(
    'fetchServiceTypes',
    getServiceTypes
)

export const serviceTypesSlice = createSlice({
    name: 'serviceTypes',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServiceTypes.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(fetchServiceTypes.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(fetchServiceTypes.rejected, (state, action) => {
                state.error = action.error.message
                state.isLoading = false
            })
    },
})

export const serviceTypesReducer = serviceTypesSlice.reducer
