import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { metricsService } from '../metrics/metricService'
import { GLOBAL_CONFIG } from '../../config'
import { extractErrMsg } from '../../shared/utils/utils'

export const METRICS_API_URL = GLOBAL_CONFIG.DAL_BASE_URL

const initialState = {
    sla: [],
    slaPage: 1,
    slaNumOfPages: 1,
    slaMetric: { min: 0, max: 0, between: 0 },
    isLoading: false,
    showShimmer: false,
    isRejected: false,
}

export const getOrdersSLAStatus = createAsyncThunk(
    'getOrdersSLAStatus',
    async (requestData, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(await metricsService.getOrdersSLAStatus(requestData))
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const getFulfilledOrdersSLARange = createAsyncThunk(
    'getFulfilledOrdersSLA',
    async (requestData, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(
                await metricsService.getFulfilledOrdersSLA(requestData)
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const setFulfilledOrdersSLARange = createAsyncThunk(
    'setFulfilledOrdersSLA',
    async (requestData, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(
                await metricsService.setFulfilledOrdersSLA(requestData)
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const getFulfilledOrdersSLAMetric = createAsyncThunk(
    'getFulfilledOrdersSLAMetric',
    async (requestData, thunkAPI) => {
        try {
            return thunkAPI.fulfillWithValue(
                await metricsService.getFulfilledOrdersSLAMetric({ ...requestData })
            )
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrMsg(error))
        }
    }
)

export const slaSectionSlice = createSlice({
    name: 'sla',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrdersSLAStatus.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getOrdersSLAStatus.fulfilled, (state, action) => {
                state.sla = action.payload?.data
                state.slaNumOfPages = action.payload?.pages
                state.slaPage = action.payload?.c_page
                state.isLoading = false
            })
            .addCase(getOrdersSLAStatus.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getFulfilledOrdersSLARange.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getFulfilledOrdersSLARange.fulfilled, (state, action) => {
                state.slaRange = {
                    fulfillment_min_sla: action.payload.fulfillment_min_sla,
                    fulfillment_max_sla: action.payload.fulfillment_max_sla,
                }
                state.isLoading = false
            })
            .addCase(getFulfilledOrdersSLARange.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(setFulfilledOrdersSLARange.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(setFulfilledOrdersSLARange.fulfilled, (state, action) => {
                state.slaRange = {
                    fulfillment_min_sla: action.payload[0],
                    fulfillment_max_sla: action.payload[1],
                }
                state.isLoading = false
            })
            .addCase(setFulfilledOrdersSLARange.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
            .addCase(getFulfilledOrdersSLAMetric.pending, (state) => {
                state.isLoading = true
                state.isRejected = false
            })
            .addCase(getFulfilledOrdersSLAMetric.fulfilled, (state, action) => {
                state.slaMetric = action.payload
                state.isLoading = false
            })
            .addCase(getFulfilledOrdersSLAMetric.rejected, (state) => {
                state.isLoading = false
                state.isRejected = true
            })
    },
})

export const slaActions = slaSectionSlice.actions
export const slaState = (state) => state.metrics
export const slaSliceReducer = slaSectionSlice.reducer
