import type { ButtonPropsType } from '@octup/core/ui-kit'
import { useFormContext } from 'react-hook-form'
import { FormSubmitButton } from '@/new_components/__common__/Form'

export const FormSubmitButtonContainer = (props: ButtonPropsType) => {
    const { formState } = useFormContext()
    const { isSubmitting, dirtyFields } = formState
    const hasDirtyFields = !!Object.keys(dirtyFields).length

    return <FormSubmitButton disabled={!hasDirtyFields} loading={isSubmitting} {...props} />
}
