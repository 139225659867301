type SearchedOptionsArgsType<T> = {
    value?: string
    options?: T[]
    getOptionLabel?: (option: T) => string
    subOptionsField?: keyof T
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSearchedOptions = <T extends Record<string, any>>({
    value,
    options = [],
    getOptionLabel = (option: T) => option.name,
    subOptionsField,
}: SearchedOptionsArgsType<T>) => {
    if (!value) return options

    const searchedValue = value.trim().toLowerCase()

    const filterOptions = (options: T[]): T[] =>
        options.reduce((acc: T[], option: T) => {
            const label = getOptionLabel(option)
            if (label?.toLowerCase().includes(searchedValue)) {
                return [...acc, option]
            }

            if (subOptionsField && option[subOptionsField]?.length) {
                const filteredSubOptions = filterOptions(option[subOptionsField])
                if (filteredSubOptions.length) {
                    return [...acc, { ...option, [subOptionsField]: filteredSubOptions }]
                }
            }

            return acc
        }, [])

    return filterOptions(options)
}
