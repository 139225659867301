import { ErrorMessageFallback, LoadingView } from '@/new_components'

export type FormContentPropsType = {
    isLoading?: boolean
    error?: string
    children: React.ReactNode
}

export const FormContent = ({ isLoading, error, children }: FormContentPropsType) => {
    if (isLoading) return <LoadingView />
    if (error) return <ErrorMessageFallback />

    return <>{children}</>
}
