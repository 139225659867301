import { useState, useMemo, useCallback } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import { PlusIconSvg } from '@/shared/assets/icons/PlusIconSvg'
import { CardExpendable } from '@/shared/ui/Card/CardExpendable'
import { OctupBaseTable } from '@/shared/ui/Tables/OctupBaseTable'
import { InviteUserModal } from './InviteUserModal'
import { UsersTableRow } from './UsersTableRow'

const HEADERS = ['Full Name', 'Email', 'Role', 'Member Since', '']

export const UsersTable = ({ data, clientId, isLoading, onUpdateData }) => {
    const [showModal, setShowModal] = useState(false)
    const toggleShowModal = useCallback(() => setShowModal((prev) => !prev), [])
    const tableData = useMemo(() => ({ headers: HEADERS, rows: data }), [data])

    const renderRows = useCallback(
        (user) => <UsersTableRow user={user} clientId={clientId} />,
        [clientId]
    )

    return (
        <>
            {showModal && (
                <InviteUserModal
                    clientId={clientId}
                    onClose={toggleShowModal}
                    onUpdateData={onUpdateData}
                />
            )}
            <Box maxHeight="calc(100% - 10rem)" width="100%">
                <CardExpendable>
                    <Grid container direction="column" wrap="nowrap" height="100%" rowSpacing={2}>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            padding={2.5}
                            paddingBottom={1}
                        >
                            <Grid item>
                                <Typography variant="h5" fontWeight={500}>
                                    {tableData?.rows?.length} Members
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StyledButton onClick={toggleShowModal}>
                                    <PlusIconSvg />
                                    <StyledButtonText>Add New Member</StyledButtonText>
                                </StyledButton>
                            </Grid>
                        </Grid>
                        <Grid item container justifySelf="flex-end" maxHeight="calc(100% - 5.3rem)">
                            <OctupBaseTable
                                hiddenRowKey={'id'}
                                isRowsLoading={isLoading}
                                showSelectedRow={false}
                                showSearchFilter={false}
                                showFilters={false}
                                showCounter={false}
                                showDotMenu={false}
                                tableData={tableData}
                                renderRows={renderRows}
                                defaultSortBy={'Full Name'}
                                sortingHeadersToDataMapping={{
                                    fullName: 'Full Name',
                                    email: 'email',
                                    role: 'Role',
                                    memberSince: 'memberSince',
                                }}
                                customRowStyle={{ height: '9.4rem' }}
                                searchFilterPlaceholder={'Search...'}
                                searchFilterFields={{
                                    fullName: 'Full Name',
                                    email: 'email',
                                    role: 'Role',
                                    memberSince: 'Member Since',
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardExpendable>
            </Box>
        </>
    )
}

const StyledButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 8px;
    gap: 8px;
    width: 192px;
    height: 32px;
    background: #f8f7fb;
    box-shadow:
        -2px -2px 8px #ffffff,
        2px 2px 4px rgba(80, 75, 90, 0.16);
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 2rem;
`

const StyledButtonText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #007f82;
    text-transform: none !important;
    white-space: nowrap;
`
