import { useUpdateEffect } from '@octup/core/hooks'
import { SelectAccountsForm } from 'new_components'
import { useFormContext } from 'react-hook-form'
import { SELECT_ALL } from '../SelectAccountsModalContainer'

export const SelectAccountsFormContainer = ({ accounts }) => {
    const {
        watch,
        setValue,
        formState: { defaultValues },
    } = useFormContext()

    const isAllSelected = watch(SELECT_ALL)

    useUpdateEffect(() => {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                setValue(key, isAllSelected)
            })
        }
    }, [isAllSelected])

    return <SelectAccountsForm accounts={accounts} />
}
