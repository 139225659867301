import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getTableConfig } from './utils'

export const TableContainer = ({ data = [] }) => {
    const { comparisonMetric, selectedMetric } = useSelector((state) => state.deepdivesSection)

    const { tableConfig, TableComponent } = useMemo(
        () => getTableConfig({ data, selectedMetric, comparisonMetric }),
        [comparisonMetric, data, selectedMetric]
    )

    if (!TableComponent) return

    return <TableComponent {...tableConfig} />
}
