import { useCallback } from 'react'
import type { DashboardSectionPropsType, DashboardContainerPropsType } from '@octup/core/components'
import { DashboardContainer } from '@octup/core/components'
import type {
    MetricsBySectionType,
    MetricsSectionConfigByType,
    MetricsSectionStateByIdType,
    MetricsSectionType,
} from '@octup/core/models'
import { LinearProgress, Stack } from '@octup/core/ui-kit'

export type MetricsDashboardContainerPropsType = Omit<
    DashboardContainerPropsType,
    'renderSection'
> & {
    isLoading: boolean
    sectionsList?: MetricsSectionType[]
    sectionStateById?: MetricsSectionStateByIdType
    sectionConfigByType: MetricsSectionConfigByType
    metricsBySectionType?: MetricsBySectionType
}

export const MetricsDashboardContainer = ({
    isLoading,
    sectionsList = [],
    sectionStateById,
    sectionConfigByType,
    metricsBySectionType,
    ...props
}: MetricsDashboardContainerPropsType) => {
    const renderDashboardSection = useCallback(
        ({ index }: DashboardSectionPropsType) => {
            const section = sectionsList[index]
            if (!section) return null

            const config = sectionConfigByType[section.type]
            const sectionState = sectionStateById?.[section.id]
            const sectionTypeMetrics = metricsBySectionType?.[section.type]
            const { Component: MetricsSection } = config

            return (
                <MetricsSection
                    {...sectionState}
                    id={section.id}
                    name={section.name}
                    description={section.description}
                    sectionTypeMetrics={sectionTypeMetrics}
                />
            )
        },
        [sectionsList, sectionConfigByType, sectionStateById, metricsBySectionType]
    )

    return (
        <Stack width="100%" height="100%" gap={4}>
            <LinearProgress isLoading={isLoading} />
            <DashboardContainer renderSection={renderDashboardSection} {...props} />
        </Stack>
    )
}
