import * as icons from '@mui/icons-material'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@octup/core/ui-kit'
import { SIDEBAR_FOOTER_LIST } from './utils'

export const SidebarFooter = () => (
    <List>
        {SIDEBAR_FOOTER_LIST.map(({ id, iconName, title, Container }) => {
            const Icon = icons[iconName as keyof typeof icons]

            return (
                <Container key={id}>
                    <ListItemButton>
                        <ListItemIcon>{<Icon />}</ListItemIcon>
                        <ListItemText primary={title} />
                    </ListItemButton>
                </Container>
            )
        })}
    </List>
)
