import { Dialog, DialogContent, Grid2 } from '@octup/core/ui-kit'
import { ErrorMessageFallback, LoadableContent } from '@/new_components'
import { MetricsDropdownContainer } from '@/new_containers/components/Metrics'
import { MetricsDetailsGraph } from './MetricsDetailsGraph'
import { MetricsDetailsTable } from './MetricsDetailsTable'

export const MetricsDetailsModal = ({
    onClose,
    selectedMetricData,
    data,
    deepdiveModal,
    isBreakdown,
    isLoadingDeepdives,
    breakdownItemsData,
    rearrangedMetricArray,
    deepdives,
    onMetricSelection,
    showGraph = true,
    originalReferenceType,
    error,
    ...props
}) => (
    <Dialog
        open
        fullWidth
        maxWidth="md"
        onClose={onClose}
        showFullScreenButton
        sx={dialogStyle}
        title={
            <MetricsDropdownContainer
                metricsGroup={data}
                onSelect={onMetricSelection}
                defaultValue={deepdiveModal.selectedDDMetric}
            />
        }
        {...props}
    >
        <DialogContent>
            {error ? (
                <ErrorMessageFallback />
            ) : (
                <Grid2
                    container
                    width="100%"
                    height="100%"
                    direction="column"
                    wrap="nowrap"
                    gap={1}
                >
                    {showGraph && (
                        <LoadableContent isLoading={isLoadingDeepdives} height="40rem">
                            <Grid2 container xs={3}>
                                <MetricsDetailsGraph
                                    isLoadingDeepdives={isLoadingDeepdives}
                                    selectedMetricData={selectedMetricData}
                                    isBreakdown={isBreakdown}
                                    breakdownItemsData={breakdownItemsData}
                                    data={data}
                                />
                            </Grid2>
                        </LoadableContent>
                    )}

                    <LoadableContent isLoading={isLoadingDeepdives}>
                        <Grid2 container size="grow" overflow="auto">
                            <MetricsDetailsTable
                                selectedMetricData={selectedMetricData}
                                isLoadingDeepdives={isLoadingDeepdives}
                                deepdives={deepdives}
                                rearrangedMetricArray={rearrangedMetricArray}
                                isBreakdown={isBreakdown}
                                breakdownItemsData={breakdownItemsData}
                                originalReferenceType={originalReferenceType}
                            />
                        </Grid2>
                    </LoadableContent>
                </Grid2>
            )}
        </DialogContent>
    </Dialog>
)

const dialogStyle = {
    '& .MuiDialog-paper ': {
        height: '100%',
    },
}
