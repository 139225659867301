import { useCallback, useEffect, useMemo } from 'react'
import type { MetricsSectionPropsType } from '@octup/core/models'
import type {
    GridApiCommonType,
    GridCallbackDetailsType,
    GridPaginationModelType,
    GridSortModelType,
} from '@octup/core/ui-kit'
import { TableMetricsSection } from '@/new_components/__common__/MetricsSections'
import type { CustomTableMetricsSectionDataParamsType } from '@/services/metricsSections'
import { getCustomTableData } from '@/services/metricsSections'
import { fetchUpdateMetricsSection } from '@/state/features/metrics'
import { useAppDispatch } from '@/state/hooks'
import { getTableConfig } from './utils'

export const CustomTableMetricsSectionProvider = ({
    data,
    clientId,
    startDate,
    endDate,
    ...props
}: MetricsSectionPropsType) => {
    const dispatch = useAppDispatch()
    const { id, table } = data || {}
    const tableConfig = useMemo(() => getTableConfig(table), [table])
    const hasDateRange = typeof startDate === 'string' && typeof endDate === 'string'

    const handleUpdateData = useCallback(
        (extraParams: Partial<CustomTableMetricsSectionDataParamsType>) => {
            if (!id || !clientId || !hasDateRange) return
            const params = { id, clientId, startDate, endDate, ...extraParams }
            const updateParams = { path: 'table.data', fetch: getCustomTableData, params }
            dispatch(fetchUpdateMetricsSection(updateParams))
        },
        [id, clientId, hasDateRange, startDate, endDate, dispatch]
    )

    const handleModelChange = useCallback(
        (state: GridApiCommonType['state']) => {
            const { pagination, sorting, search } = state
            const { page, pageSize } = pagination.paginationModel
            const { field: sortBy, sort: sortDescending } = sorting.sortModel[0] || {}
            handleUpdateData({ page, pageSize, sortBy, sortDescending, search })
        },
        [handleUpdateData]
    )

    useEffect(() => {
        if (!table?.config || 'data' in table) return
        handleModelChange(tableConfig.initialState)
    }, [handleModelChange, table, tableConfig.initialState])

    const handleSortModelChange = useCallback(
        (_: GridSortModelType, details: GridCallbackDetailsType) =>
            handleModelChange(details.api.state),
        [handleModelChange]
    )

    const handlePaginationModelChange = useCallback(
        (_: GridPaginationModelType, details: GridCallbackDetailsType) =>
            handleModelChange(details.api.state),
        [handleModelChange]
    )

    const handleSearchChange = useCallback(
        (_: string, state: GridApiCommonType['state']) => handleModelChange(state),
        [handleModelChange]
    )

    return (
        <TableMetricsSection
            {...props}
            tableProps={{
                ...tableConfig,
                onSearchChange: handleSearchChange,
                onSortModelChange: handleSortModelChange,
                onPaginationModelChange: handlePaginationModelChange,
            }}
        />
    )
}
