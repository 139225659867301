import { useMemo } from 'react'
import { useAppSelector } from '@/state/hooks'

export const useLogisticsDatePicker = () => {
    const datePicker = useAppSelector((state) => state.datePicker)
    const { sections } = useAppSelector((state) => state.logistics)

    const { datePickerViewType } = sections
    const dateRange = datePicker[datePickerViewType]

    const datePickerConfig = useMemo(
        () => ({ dateRange, viewType: datePickerViewType }),
        [datePickerViewType, dateRange]
    )

    return datePickerConfig
}
