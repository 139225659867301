// TODO: Re-write Dropdown

import { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useUpdateEffect } from '@octup/core/hooks'
import styled from 'styled-components'
import { OctupAvatar } from '../Avatar/OctupAvatar'
import DropdownItem from './DropdownItem'
import AllItemsSvg from '../../assets/images/AllItems.svg'
import AllUsersSvg from '../../assets/images/AllUsers.svg'
import { OctupIcons } from '../../assets/OctupIcons'
import { isString } from '../../utils/strings-util'

const { ChevronDownSvg, ChevronUpSvg, NoImageSvg, ClearFieldsSvg } = OctupIcons

const Dropdown = ({
    title = 'Items',
    id = '',
    name = '',
    onSelection = () => {},
    options = [
        {
            name: '',
            value: {
                someValue: '',
                imgObj: { src: null, alt: null },
                defaultImgObj: { src: null, alt: null },
                childOptions: [],
            },
            isSelected: false,
            count: -1,
        },
    ],
    droplistPosition = 'bottom',
    enableTextCapitalization = true,
    isMultiSelection = true,
    showOptionsImages = false,
    isAvatarDropdown = false,
    isFilter = false,
    titlePostfix = ': All',
    titlePrefix = 'Select ',
    showImageInTitle = false,
    showEmpty = true,
    showCounter = true,
    isRequired = false,
    customContainerStyle = {},
    customOptionsStyle = {},
    showClearFilters = isMultiSelection,
    showSelectAllOption = false,
    requestedFiltersDataMapping = [],
    onClickCallback = () => {},
    showErrors = false,
    resetValue = false,
    trimSpace = false,
    updateSelectedOptions = false,
    onClose,
    isLoading,
}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [activeItem, setActiveItem] = useState(null)
    const [firstSelectedItemsObj, setFirstSelectedItemsObj] = useState(null)
    const [isSelectAllActive, setIsSelectAllActive] = useState(false)
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const hasSelected = selectedItems[0]
    const dropdownRef = useRef()

    const dropdownHeaderBaseTitle =
        (!isFilter ? titlePrefix : '') + title + (isFilter ? titlePostfix : '')

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    const handleClose = useCallback(() => {
        setShowDropdown(false)
        onClose?.({ value: isMultiSelection ? selectedItems : selectedItems[0] })
    }, [isMultiSelection, onClose, selectedItems])

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            handleClose()
        }
        event.stopPropagation()
    }, [])

    useUpdateEffect(() => {
        if (resetValue) {
            resetFiltersHandler()
        }
    }, [resetValue])

    useEffect(() => {
        if (!showDropdown) return
        document.addEventListener('keydown', escFunction, true)
        return () => {
            document.removeEventListener('keydown', escFunction, true)
        }
    }, [escFunction, showDropdown])

    useEffect(() => {
        if (dropdownRef.current) {
            setDimensions({
                width: dropdownRef.current.offsetWidth,
                height: dropdownRef.current.offsetHeight,
            })
        }
    }, [])

    useUpdateEffect(() => {
        if (updateSelectedOptions) {
            setSelectedItems(options.filter((el) => el.isSelected === true))
        }
    }, [updateSelectedOptions, options])

    useEffect(() => {
        setSelectedItems(options.filter((el) => el.isSelected === true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isSelectAllActive) return
        if (selectedItems.length < options.length) setSelectedItems(options)
        else if (selectedItems.length === options.length) setSelectedItems([])
    }, [isAllSelected])

    useEffect(() => {
        //closes menu if mouse click outside of container
        const onMouseDownClick = (event) => {
            showDropdown &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                handleClose()
        }
        //listen to mousedown
        document.addEventListener('mousedown', onMouseDownClick)
        activeItem && droplistItemClickHandler()

        //clean up
        return () => {
            document.removeEventListener('mousedown', onMouseDownClick)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem, showDropdown])

    useEffect(() => {
        setFirstSelectedItemsObj(selectedItems[0])
        onSelection(selectedItems)
    }, [selectedItems])

    useEffect(() => {
        onClickCallback &&
            onClickCallback({
                type: title,
                payload: selectedItems.map((el) => el?.name?.toLowerCase()),
                mapping: requestedFiltersDataMapping,
                options: options,
                value: isMultiSelection ? selectedItems : selectedItems[0],
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems])

    const droplistItemClickHandler = () => {
        if (selectedItems.find((el) => el.name === activeItem.name)) {
            if (!isMultiSelection) {
                handleClose()
                setActiveItem(null)
                return
            } else
                setSelectedItems((prevState) => [
                    ...prevState.filter((el) => el.name !== activeItem.name),
                ])
        } else {
            !isMultiSelection
                ? setSelectedItems([activeItem])
                : setSelectedItems((prevState) => [...prevState, activeItem])
        }

        //single selection should be closed on selection
        !isMultiSelection && handleClose()
        setActiveItem(null)
    }

    const onItemSelection = (itemId) => {
        // to set new active item active item should be null (null is the reset & initial value)
        if (activeItem) return
        setActiveItem(options[itemId])
    }

    const onSelectAllSelection = () => {
        setIsSelectAllActive(true)
        setIsAllSelected((prevState) => !prevState)
    }

    const resetFiltersHandler = (event) => {
        event?.stopPropagation()
        handleClose()
        setActiveItem(null)
        setSelectedItems([])
        setIsTouched(true)
    }

    return (
        <DropdownOutterContainer
            onClick={() => !isLoading && setIsTouched(true)}
            isAvatarDropdown={isAvatarDropdown && showImageInTitle}
            showDropdown={showDropdown}
            ref={dropdownRef}
            selectedItems={selectedItems}
            enableTextCapitalization={enableTextCapitalization}
            style={customContainerStyle}
            isRequired={isRequired && ((isTouched && !showDropdown) || showErrors)}
            trimSpace={trimSpace}
            isLoading={isLoading}
            className={
                ((isTouched && !showDropdown) || showErrors) &&
                isRequired &&
                selectedItems.length === 0
                    ? 'error'
                    : ''
            }
        >
            <DropdownInnerContainer
                selectedItems={selectedItems}
                onClick={() => {
                    if (!isLoading) {
                        setShowDropdown((prevState) => !prevState)
                        if (showDropdown) {
                            onClose?.({ value: selectedItems })
                        }
                    }
                }}
            >
                {isLoading ? (
                    <CircularProgress size="1.8rem" color="secondary" />
                ) : (
                    <>
                        {hasSelected &&
                            selectedItems.length !== options.length &&
                            showImageInTitle &&
                            firstSelectedItemsObj &&
                            firstSelectedItemsObj.value &&
                            firstSelectedItemsObj.value.imgObj &&
                            firstSelectedItemsObj.value.imgObj.src !== '' &&
                            firstSelectedItemsObj.value.imgObj.src !== null &&
                            (!isAvatarDropdown ? (
                                <ImgContainer>
                                    <img
                                        width={'fit-content'}
                                        height={'100%'}
                                        src={firstSelectedItemsObj.value.imgObj.src || NoImageSvg}
                                        alt={firstSelectedItemsObj.value.imgObj.alt}
                                    />
                                </ImgContainer>
                            ) : (
                                <OctupAvatar
                                    imageSrc={firstSelectedItemsObj.value.imgObj.src}
                                    title={firstSelectedItemsObj.value.fullName}
                                    onClick={() => {
                                        // console.log("selectedItems", selectedItems);
                                    }}
                                    size="medium"
                                />
                            ))}
                        {showImageInTitle &&
                            hasSelected &&
                            selectedItems.length !== options.length &&
                            firstSelectedItemsObj &&
                            firstSelectedItemsObj.value &&
                            !firstSelectedItemsObj.value.imgObj.src &&
                            !isAvatarDropdown && <NoImageSvg customColor="#504B5A" />}

                        {showImageInTitle &&
                        !isAvatarDropdown &&
                        hasSelected &&
                        selectedItems.length === options.length ? (
                            <ImgContainer>
                                <img
                                    width={'fit-content'}
                                    height={'100%'}
                                    src={AllItemsSvg}
                                    alt={'all item icon'}
                                />
                            </ImgContainer>
                        ) : (
                            showImageInTitle &&
                            isAvatarDropdown &&
                            hasSelected &&
                            selectedItems.length === options.length && (
                                <OctupAvatar
                                    imageSrc={AllUsersSvg}
                                    size={'medium'}
                                    title={'ALL USERS'}
                                    userInitials={'All'}
                                />
                            )
                        )}

                        <DropdownTitle
                            id={id}
                            name={name}
                            selectedItems={selectedItems}
                            isFilter={isFilter}
                        >
                            {(selectedItems.length >= 1 &&
                                selectedItems.length !== options.length) ||
                            (!isMultiSelection &&
                                selectedItems.length === 1 &&
                                selectedItems.length === options.length)
                                ? `${
                                      hasSelected &&
                                      (!isString(selectedItems[0].name) ||
                                          selectedItems[0].name.length < 25)
                                          ? selectedItems[0].name
                                          : selectedItems[0].name.substring(0, 25) + '...'
                                  } ${
                                      isMultiSelection && selectedItems.length > 1
                                          ? `+${selectedItems.length - 1}`
                                          : ''
                                  }
                `
                                : (!hasSelected ||
                                        (hasSelected && selectedItems.length === options.length)) &&
                                    isFilter
                                  ? dropdownHeaderBaseTitle
                                  : hasSelected && selectedItems.length === options.length
                                    ? 'All ' + title
                                    : dropdownHeaderBaseTitle}
                        </DropdownTitle>
                        <IconContainer>
                            {showClearFilters && hasSelected && (
                                <ClearFiltersContainer onClick={resetFiltersHandler}>
                                    <ClearFieldsSvg />
                                </ClearFiltersContainer>
                            )}
                            {showDropdown ? (
                                <ChevronUpSvg
                                    active={selectedItems.length > 0}
                                    customColor={'#504B5A'}
                                    customActiveColor={'#504B5A'}
                                />
                            ) : (
                                <ChevronDownSvg
                                    active={selectedItems.length > 0}
                                    customColor={'#504B5A'}
                                    customActiveColor={'#504B5A'}
                                />
                            )}
                        </IconContainer>
                    </>
                )}
            </DropdownInnerContainer>
            <DropdownFixedPosition isAvatarDropdown={isAvatarDropdown}>
                <DropdownOptionsContainer
                    style={customOptionsStyle}
                    containerDimensions={dimensions}
                    isAvatarDropdown={isAvatarDropdown}
                    showDropdown={showDropdown}
                    droplistPosition={droplistPosition}
                >
                    {options.map((el, index) => {
                        return (
                            <OptionContainer
                                key={
                                    index === 0 && showSelectAllOption
                                        ? 'all_items'
                                        : `${el.name + index}`
                                }
                            >
                                {index === 0 && showSelectAllOption && (
                                    <DropdownItem
                                        name={`All ${title}`}
                                        value={selectedItems}
                                        isSelectAllOption={true}
                                        showSemiAll={
                                            isMultiSelection &&
                                            // isSelectAllActive && TO DO : TEST WHY
                                            selectedItems.length > 0 &&
                                            selectedItems.length < options.length
                                        }
                                        showCounter={showCounter}
                                        initials={'All'}
                                        selectedItems={selectedItems}
                                        isMultiSelection={isMultiSelection}
                                        isSelected={selectedItems.length === options.length}
                                        onItemSelection={onSelectAllSelection}
                                        isAvatarDropdown={isAvatarDropdown}
                                        showOptionsImages={showOptionsImages}
                                        isSelectAllActive={isSelectAllActive}
                                        showEmpty={showEmpty}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}
                                    />
                                )}
                                {el.name && (
                                    <DropdownItem
                                        id={index}
                                        name={el.name}
                                        disabled={el.disabled}
                                        tooltip={el.tooltip}
                                        customLabelPostfix={el.customLabelPostfix}
                                        customLabelPrefix={el.customLabelPrefix}
                                        count={el.count}
                                        showCounter={showCounter}
                                        imgObj={el.value && el.value.imgObj}
                                        avatar={el.avatar}
                                        initials={el.initials}
                                        isMultiSelection={isMultiSelection}
                                        isSelected={
                                            !!selectedItems.find((item) => item.name === el.name)
                                        }
                                        selectedItems={selectedItems}
                                        onItemSelection={onItemSelection}
                                        isAvatarDropdown={isAvatarDropdown}
                                        showOptionsImages={showOptionsImages}
                                        showEmpty={showEmpty}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}
                                    />
                                )}
                            </OptionContainer>
                        )
                    })}
                </DropdownOptionsContainer>
            </DropdownFixedPosition>
        </DropdownOutterContainer>
    )
}

const DropdownOutterContainer = styled.div`
    position: relative;
    min-width: fit-content;
    height: 3.4rem;
    background: #f8f7fb;
    box-shadow: ${(props) =>
        props.isAvatarDropdown
            ? 'none'
            : props.showDropdown
              ? `inset -.2rem -.2rem .4rem #FFFFFF, inset .2rem .2rem .8rem rgba(80, 75, 90, 0.32);`
              : '-.2rem -.2rem .8rem #ffffff, .2rem .2rem .4rem rgba(80, 75, 90, 0.16);'};
    border-radius: 0.8rem;
    text-transform: ${(props) => (props.enableTextCapitalization ? 'capitalize' : 'none')};
    cursor: pointer;
    padding: ${(props) => (props.isAvatarDropdown ? '0' : '0 1.2rem 0 1.2rem')};
    margin: ${(props) => (props.trimSpace ? 0 : '1rem')};
    border: ${(props) =>
        props.selectedItems.length > 0 && !props.isAvatarDropdown
            ? // ? "1px solid #C2C0C7"
              'none'
            : 'none'};
    border: ${(p) =>
        p.isRequired && p.selectedItems.length === 0 ? '0.2rem solid #DB2059' : 'none'};
    &:hover {
        background: rgba(241, 243, 247, 1);
    }
    &.error::after {
        content: 'required field';
        color: #db2059;
        position: absolute;
        left: 0;
        top: 4.2rem;
        color: #db2059;
        font-size: 1.3rem;
        line-height: 1.8rem;
    }
    ${(props) =>
        props.isLoading &&
        `
            width: 15rem;
            opacity: 0.5;
    `}
`

const DropdownFixedPosition = styled.div`
    position: fixed;
    // z-index: 9999;
    z-index: 1400;
    margin-left: ${(props) => (props.isAvatarDropdown ? '0' : '-1.2rem')};
`

const DropdownInnerContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    font-weight: ${(props) => (props.selectedItems.length > 0 ? '500' : '400')};
    font-size: 3rem;
    line-height: 2.3rem;
    gap: 1rem;
`

const DropdownOptionsContainer = styled.ul`
    display: ${(props) => (props.showDropdown ? 'flex' : 'none')};
    /* gap: ${(props) => (props.isAvatarDropdown ? '.rem' : '0')}; */
    position: absolute;
    min-width: 100%;
    height: fit-content;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
    bottom: 0;
    /* padding: ${(props) => (props.isAvatarDropdown ? '.6rem 0' : '.1rem 0')}; */
    background: #f8f7fb;
    box-shadow:
        -0.2rem -0.2rem 0.8rem #ffffff,
        0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
    left: ${(props) =>
        props.droplistPosition.toLowerCase() === 'left'
            ? null
            : props.droplistPosition.toLowerCase() === 'right'
              ? props.containerDimensions.width + 'px'
              : 0};
    right: ${(props) =>
        props.droplistPosition.toLowerCase() === 'left'
            ? -props.containerDimensions.width + 'px'
            : null};
    transform: ${(props) =>
        props.droplistPosition.toLowerCase() === 'top'
            ? 'translateY(-3.4rem)'
            : props.droplistPosition.toLowerCase() === 'right'
              ? `translateY(100%)`
              : props.droplistPosition.toLowerCase() === 'left'
                ? `translateY(100%)`
                : 'translateY(100%)'};
    /* z-index: 4; */
    z-index: 99;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0.8rem;

    &h1 {
        font-size: 1.2rem;
        line-height: 1.5;
    }

    ::-webkit-scrollbar {
        width: 1rem !important;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 2rem;
        border: 0.2rem solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }
`

const ImgContainer = styled.div`
    overflow: hidden;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f5f9;
    border-radius: 0.6rem;
    margin-right: 0.6rem;
`

const DropdownTitle = styled.div`
    white-space: nowrap;
    /* color: ${(props) => (props.selectedItems.length > 0 ? '#007F82' : '#504B5A')}*/
    font-weight: ${(props) => (props.selectedItems.length > 0 ? '600' : '400')};
    font-size: 1.3rem;
`

const IconContainer = styled.div`
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

const ClearFiltersContainer = styled.div`
    line-height: 1;
    transform: translateY(6%);
`

const OptionContainer = styled.li`
    width: 100%;
    padding: 0;
`

export default Dropdown
