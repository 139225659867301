import axios from 'axios'
import { HTTP_STATUS_CODES } from '@/models/network'
import type { ThunkAPIType } from '@/services/utils'
import { generateAPIConfig } from '@/services/utils'
import type { GetMetricsSectionType } from './utils'
import { SECTIONS_API_URL } from './utils'

export const getMetricsSectionsList = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(SECTIONS_API_URL, config)
    return response.data
}

export const getMetricsSectionById: GetMetricsSectionType = async (data, thunkAPI) => {
    const config = generateAPIConfig({
        thunkAPI,
        params: {
            start_date: data.startDate,
            end_date: data.endDate,
            response_data_type: data.responseDataType,
            client_id: data.clientId,
        },
    })

    const response = await axios.get(`${SECTIONS_API_URL}/${data.id}`, config)
    return { data: response.data, hasContent: response.status !== HTTP_STATUS_CODES.NO_CONTENT }
}
