import type { SystemPagePropsType } from '@/models/pages'
import { MetricsDashboardProvider } from '@/new_containers/components/__common__'

export const SystemFullPage = ({ data, dateRange }: SystemPagePropsType) => {
    const [dashboard] = data || []

    return (
        <MetricsDashboardProvider
            dateRange={dateRange}
            dashboardId={dashboard.id}
            clientId={dashboard.client_id}
        />
    )
}
