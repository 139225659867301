import type { ClientType } from '@octup/core/models'
import axios from 'axios'
import { GLOBAL_CONFIG } from 'config'
import type { EditDashboardType, CreateDashboardType, DashboardType } from 'models/dashboards'
import type { ThunkAPIType } from 'services/utils'
import { generateAPIConfig } from 'services/utils'

const DASHBOARDS_API_URL = GLOBAL_CONFIG.API_URL + '/dashboards'
const DASHBOARD_TEMPLATES_API_URL = GLOBAL_CONFIG.API_URL + '/dashboard-templates'

export const getTemplatesList = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(DASHBOARD_TEMPLATES_API_URL, config)
    return response.data
}

export const getDashboardsList = async (thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.get(DASHBOARDS_API_URL, config)
    return response.data
}

export type DashboardByIdRequestType = {
    id: DashboardType['id']
    clientId: ClientType['id']
}

export const getDashboardById = async (data: DashboardByIdRequestType, thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI, params: { client_id: data.clientId } })
    const response = await axios.get(`${DASHBOARDS_API_URL}/${data.id}`, config)
    return response.data
}

export const createDashboard = async (data: CreateDashboardType, thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const body = { name: data.name, section_ids: data.sectionIds, template_id: data.templateId }
    const response = await axios.post(DASHBOARDS_API_URL, body, config)
    return response.data
}

export const editDashboard = async (data: EditDashboardType, thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const body = { name: data.name, section_ids: data.sectionIds, template_id: data.templateId }
    const response = await axios.put(`${DASHBOARDS_API_URL}/${data.id}`, body, config)
    return response.data
}

export const deleteDashboard = async (id: DashboardType['id'], thunkAPI: ThunkAPIType) => {
    const config = generateAPIConfig({ thunkAPI })
    const response = await axios.delete(`${DASHBOARDS_API_URL}/${id}`, config)
    return response.data
}
