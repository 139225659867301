import { Tab, Tabs, Stack } from '@octup/core/ui-kit'
import type { APP_ROUTES } from '@/models/appConfig'
import type { TabPageType } from '@/models/pages'
import { Link } from '@/new_components/__common__'

export type TabsPageLayoutPropsType = {
    tabs: TabPageType[]
    currentRoute: APP_ROUTES
    children: React.ReactNode
}

export const TabsPageLayout = ({ tabs, currentRoute, children }: TabsPageLayoutPropsType) => (
    <Stack width="100%" spacing={5}>
        <Tabs value={currentRoute} variant="scrollable">
            {tabs.map((tab) => (
                <Tab
                    key={tab.id}
                    value={tab.route}
                    label={tab.label}
                    component={Link}
                    route={tab.route}
                    underline="none"
                />
            ))}
        </Tabs>
        {children}
    </Stack>
)
