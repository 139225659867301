import type { PreloadedState } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

// TODO: old reducers, move into the new folder structure
import { authenticationSliceReducer, sessionSliceReducer } from 'features/auth/authSlice'
import { datePickerSliceReducer } from 'features/datePicker/datePickerSlice'
import insightSliceReducer from 'features/insights/insightSlice'
import { integrationsSliceReducer } from 'features/integrations/integrationsSlice'
import { metricsSliceReducer } from 'features/metrics/metricsSlice'
import { notificationsSliceReducer } from 'features/notifications/notificationsSlice'
import objectiveSliceReducer from 'features/objectives/objectiveSlice'
import { alternativeMarketingMetricsSliceReducer } from 'features/sections/alternativeMarketingSlice'
import { alternativeOperationsMetricsSliceReducer } from 'features/sections/alternativeOperationsSlice'
import { deepdivesSectionsSliceReducer } from 'features/sections/deepdivesSectionsSlice'
import { homePageMetricsSliceReducer } from 'features/sections/homePageSectionsSlice'
import { slaSliceReducer } from 'features/sections/slaSectionSlice'
import { toastsSliceReducer } from 'features/toasts/toastsSlice'
import navigationSliceReducer from 'store/slices/navigation-slice'
import shopSliceReducers from 'store/slices/shop-slice'
import { simulatorSliceReducer } from 'store/slices/simulator-slice'

// new reducers
import { APP_ACTION_TYPES, type AppActionsType } from '@/models/appActions'
import * as appConfig from './features/appConfig'
import { attributionReducer } from './features/attribution'
import * as auth from './features/auth'
import * as costManager from './features/costManager'
import * as dashboards from './features/dashboards'
import * as integrations from './features/integrations'
import * as logistics from './features/logistics'
import * as metrics from './features/metrics'
import { ordersReducer } from './features/orders'
import * as pages from './features/pages'
import { paymentSessionSliceReducer, paymentsSliceReducer } from './features/payments'
import * as settings from './features/settings'
import * as unitEconomics from './features/unitEconomics'
import * as users from './features/users'

const appReducer = combineReducers({
    // old reducers
    navigation: navigationSliceReducer,
    insights: insightSliceReducer,
    objectives: objectiveSliceReducer,
    auth: authenticationSliceReducer,
    session: sessionSliceReducer,
    datePicker: datePickerSliceReducer,
    shop: shopSliceReducers,
    homePageMetricsSection: homePageMetricsSliceReducer,
    deepdivesSection: deepdivesSectionsSliceReducer,
    alternativeOperationsMetrics: alternativeOperationsMetricsSliceReducer,
    alternativeMarketingMetrics: alternativeMarketingMetricsSliceReducer,
    metrics: metricsSliceReducer,
    simulator: simulatorSliceReducer,
    notifications: notificationsSliceReducer,
    toasts: toastsSliceReducer,
    sla: slaSliceReducer,

    // new reducers
    // TODO: add combineReducers for logistics reducers
    users: users.usersReducer,
    newAuth: combineReducers({
        signup: auth.signupReducer,
        resetPassword: auth.resetPasswordReducer,
    }),
    appConfig: combineReducers({
        settings: appConfig.appSettingsReducer,
        excludedFeatures: appConfig.excludedFeaturesReducer,
    }),
    attribution: attributionReducer,
    orders: ordersReducer,
    payments: paymentsSliceReducer,
    paymentSession: paymentSessionSliceReducer,
    logistics: combineReducers({
        sections: logistics.logisticsReducer,
        warehouses: logistics.warehousesReducer,
        warehouseCost: logistics.warehouseCostReducer,
        warehouseCostsReport: logistics.warehouseCostsReportReducer,
        warehouseCountries: logistics.warehouseCountriesReducer,
        serviceTypes: logistics.serviceTypesReducer,
    }),
    unitEconomics: combineReducers({
        invoicesDates: unitEconomics.invoicesDatesReducer,
        products: unitEconomics.productsReducer,
    }),
    costManager: combineReducers({
        costs: costManager.costsReducer,
        updatedCost: costManager.updatedCostReducer,
        products: costManager.productsReducer,
    }),
    // TODO delete the new prefix after moving metrics into a new folder structure
    newMetrics: combineReducers({
        datePicker: metrics.datePickerReducer,
        metricsSections: metrics.metricsSectionsReducer,
        metricsSectionsList: metrics.metricsSectionsListReducer,
    }),
    dashboards: combineReducers({
        dashboard: dashboards.dashboardReducer,
        dashboardsById: dashboards.dashboardsByIdReducer,
        dashboardsList: dashboards.dashboardsListReducer,
        updatedDashboard: dashboards.updateDashboardReducer,
        deletedDashboard: dashboards.deleteDashboardReducer,
        templatesList: dashboards.templatesListReducer,
    }),
    integrations: combineReducers({
        integrationsList: integrationsSliceReducer,
        connectedExtension: integrations.integrationExtensionReducer,
        status: integrations.integrationsStatusReducer,
    }),
    pages: combineReducers({
        sidebarPages: pages.sidebarPagesReducer,
        settingsById: pages.pageSettingsByIdReducer,
    }),
    settings: combineReducers({
        email: settings.emailSettingsReducer,
        editedEmail: settings.editEmailSettingsReducer,
        clients: settings.clientsSettingsReducer,
        editedClient: settings.editClientSettingsReducer,
        workingHours: settings.workingHoursSettingsReducer,
        editedWorkingHours: settings.editWorkingHoursSettingsReducer,
        general: settings.generalSettingsReducer,
        editedGeneral: settings.editGeneralSettingsReducer,
    }),
})

const rootReducer = (state: AppStateType, action: AppActionsType) => {
    if (action.type === APP_ACTION_TYPES.SOFT_RESET_STORE) {
        state = {
            appConfig: state?.appConfig,
            auth: state?.auth,
            pages: { sidebarPages: state?.pages.sidebarPages },
        } as AppStateType
    }

    return appReducer(state, action)
}

export const setupStore = (preloadedState?: PreloadedState<RootReducers>) =>
    configureStore({ reducer: rootReducer, preloadedState })

export type RootReducers = typeof rootReducer
export type AppStateType = ReturnType<typeof appReducer> | undefined
export type RootState = ReturnType<RootReducers>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
