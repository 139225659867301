import {
    containsLowerCase,
    containsNumber,
    containsSpecialChars,
    containsUppercase,
} from '../../shared/utils/strings-util'

export const evaluatePasswordRequirements = (password) => {
    return [
        {
            key: 0,
            message: 'At lease 8 characters',
            condition: password?.length > 7,
        },
        {
            key: 1,
            message: 'At least one uppercase',
            condition: containsUppercase(password),
        },
        {
            key: 2,
            message: 'At least one lowercase',
            condition: containsLowerCase(password),
        },
        {
            key: 3,
            message: 'At lease one number',
            condition: containsNumber(password),
        },
        {
            key: 4,
            message: 'At least one special character',
            condition: containsSpecialChars(password),
        },
    ]
}

export const isPasswordMeetsRequirements = (password) => {
    const requirements = evaluatePasswordRequirements(password)
    let res = false
    if (requirements) {
        res = true
        requirements.forEach((requirement) => {
            if (!requirement.condition) {
                res = false
            }
        })
    }
    return res
}
